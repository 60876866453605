import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

// Constants

export const types = {
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  SET_SESSION_EXPIRED: 'SET_SESSION_EXPIRED',
  SET_SESSION_EXPIRING: 'SET_SESSION_EXPIRING',

  FETCH_USER_PROFILE_REQUEST: 'FETCH_USER_PROFILE_REQUEST',
  FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
  FETCH_USER_PROFILE_ERROR: 'FETCH_USER_PROFILE_ERROR',
};

const initialState = Immutable({
  user: {},
  userProfile: null,
  isUserProfileLoading: false,
  isUserProfileRequested: false,
  sessionExpired: false,
  sessionExpiring: false,
});

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_USER: {
      return state.merge({
        user: { ...action.payload.user },
      });
    }
    case types.LOGOUT_SUCCESS: {
      return state.merge({ user: {} });
    }
    case types.SET_SESSION_EXPIRED: {
      return state.merge({ sessionExpired: true });
    }
    case types.SET_SESSION_EXPIRING: {
      return state.merge({ sessionExpiring: action.payload });
    }
    case types.FETCH_USER_PROFILE_REQUEST: {
      return state.merge({
        isUserProfileLoading: true,
        isUserProfileRequested: true,
      });
    }
    case types.FETCH_USER_PROFILE_SUCCESS: {
      return state.merge({
        isUserProfileLoading: false,
        userProfile: action.payload.userProfile,
      });
    }
    case types.FETCH_USER_PROFILE_ERROR: {
      return state.merge({
        isUserProfileLoading: false,
      });
    }
    default:
      return state;
  }
}

// Actions

export const actions = {
  setCurrentUser: ({ user }) => ({
    type: types.SET_CURRENT_USER,
    payload: { user },
  }),
  logoutRequest: () => ({
    type: types.LOGOUT_REQUEST,
  }),
  logoutSuccess: () => ({
    type: types.LOGOUT_SUCCESS,
  }),
  logoutFailure: () => ({
    type: types.LOGOUT_FAILURE,
  }),
  setSessionExpired: () => ({
    type: types.SET_SESSION_EXPIRED,
  }),
  setSessionExpiring: isExpiring => ({
    type: types.SET_SESSION_EXPIRING,
    payload: isExpiring,
  }),
  fetchUserProfileRequest: () => ({
    type: types.FETCH_USER_PROFILE_REQUEST,
  }),
  fetchUserProfileSuccess: ({ userProfile }) => ({
    type: types.FETCH_USER_PROFILE_SUCCESS,
    payload: { userProfile },
  }),
  fetchUserProfileError: () => ({
    type: types.FETCH_USER_PROFILE_ERROR,
  }),
};

// Selectors

const authSelector = () => state => state.auth;

export const selectCurrentUser = () => createSelector(authSelector(), authState => authState.user);

export const selectSessionExpired = () =>
  createSelector(authSelector(), authState => authState.sessionExpired);

export const selectSessionExpiring = () =>
  createSelector(authSelector(), authState => authState.sessionExpiring);

export const selectIsUserProfileRequested = () =>
  createSelector(authSelector(), authState => authState.isUserProfileRequested);

export const selectIsUserProfileLoading = () =>
  createSelector(authSelector(), authState => authState.isUserProfileLoading);

export const selectUserProfile = () =>
  createSelector(authSelector(), authState => authState.userProfile);
