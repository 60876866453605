import { client } from './API';

/**
 * Fetch list of milestones
 */
export const fetchMilestones = ({ boardId }) =>
  client.get(`/v1/boards/${boardId}/milestones`).then(response => response.data);

/**
 * Create milestone
 */
export const createMilestone = ({ boardId, containerId, data }) =>
  client
    .post(`/v1/boards/${boardId}/milestones/${containerId}`, data)
    .then(response => response.data);

/**
 * Update milestone values
 */
export const updateMilestone = ({ boardId, milestoneId, data }) =>
  client
    .put(`/v1/boards/${boardId}/milestones/${milestoneId}`, data)
    .then(response => response.data);

/**
 * Delete milestone
 */
export const deleteMilestone = ({ boardId, milestoneId }) =>
  client.delete(`/v1/boards/${boardId}/milestones/${milestoneId}`).then(response => response.data);

/**
 * Fetch milestone attachment infos
 */
export const fetchMilestoneAttachments = ({ boardId, milestoneId }) =>
  client
    .get(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments`)
    .then(response => response.data);

/**
 * Upload attachment with content to milestone
 */
export const uploadMilestoneAttachment = ({ boardId, milestoneId, file }) => {
  const data = new FormData();
  data.append('file', file, file.name);
  return client
    .post(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments`, data, {
      headers: { Accept: '*/*' },
    })
    .then(response => response.data);
};

/**
 * Add link to milestone
 */
export const addMilestoneLink = ({ boardId, milestoneId, link }) =>
  client
    .post(`/v1/boards/${boardId}/milestones/${milestoneId}/links`, link)
    .then(response => response.data);

/**
 * Delete attachment from milestone
 */
export const deleteMilestoneAttachment = ({ boardId, milestoneId, attachmentId }) => {
  client
    .delete(`/v1/boards/${boardId}/milestones/${milestoneId}/attachments/${attachmentId}`)
    .then(response => response.data);
};

export const downloadMilestoneAttachment = ({ boardId, milestoneId, attachmentId, version }) => {
  const config = {
    responseType: 'blob', // important
    params: version ? { version } : {},
  };
  return client.get(
    `/v1/boards/${boardId}/milestones/${milestoneId}/attachments/${attachmentId}`,
    config,
  );
};

/**
 * Fetch all milestone comments
 */
export const fetchMilestoneComments = ({ boardId, milestoneId }) => {
  return client
    .get(`/v1/boards/${boardId}/milestones/${milestoneId}/comments`)
    .then(response => response.data);
};

/**
 * Create comment in milestone
 */
export const createMilestoneComment = ({ boardId, milestoneId, content }) => {
  const comment = { content };
  return client
    .post(`/v1/boards/${boardId}/milestones/${milestoneId}/comments`, comment)
    .then(response => response.data);
};

/**
 * Update comment in Milestone
 */
export const updateMilestoneComment = ({ boardId, milestoneId, comment }) => {
  return client
    .put(`/v1/boards/${boardId}/milestones/${milestoneId}/comments/${comment.id}`, comment)
    .then(response => response.data);
};

/**
 * Delete comment in Milestone
 */
export const deleteMilestoneComment = ({ boardId, milestoneId, commentId }) => {
  return client
    .delete(`/v1/boards/${boardId}/milestones/${milestoneId}/comments/${commentId}`)
    .then(response => response.data);
};
