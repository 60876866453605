import * as api from '../services/API';
import { actions } from '../ducks/Subscribers';
import onError from './Error';

export const subscribe = ({ boardId, id }) => {
  return async dispatch => {
    dispatch(actions.subscribeRequest());

    try {
      await api.subscribe({ boardId, id });

      return dispatch(actions.subscribeSuccess({ boardId, subscribed: true }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.subscribeError({ error }),
      });
    }
  };
};

export const unsubscribe = ({ boardId, id }) => {
  return async dispatch => {
    dispatch(actions.subscribeRequest());

    try {
      await api.unsubscribe({ boardId, id });

      return dispatch(actions.subscribeSuccess({ boardId, subscribed: false }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.subscribeError({ error }),
      });
    }
  };
};

export const fetchSubscriptions = ({ boardId, id }) => {
  return async dispatch => {
    dispatch(actions.fetchSubscriptionsRequest());

    try {
      const subscriptions = await api.fetchSubscribers({ boardId, id });

      return dispatch(actions.fetchSubscriptionsSuccess({ subscriptions }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchSubscriptionsError({ error }),
      });
    }
  };
};
