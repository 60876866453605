import { getAnswer, getBoardFields, isOverdue } from './FieldUtil';
import { getNoonOfDay } from './DateUtil';
import Immutable from 'seamless-immutable';
import { getFilters } from './FilterUtil';
import { QUESTION } from './Constants';
import { cardSorter } from './SortUtil';

export const isQuestionAnswered = (board, card) => {
  if (board.field_config.answered_date_field) {
    const foundField = card.fields.filter(f => f.id === board.field_config.answered_date_field);

    if (foundField && foundField[0]) {
      return !!foundField[0].value;
    }
  }

  return getAnswer(card, board) !== '';
};

export const prepareQuestionCardsForWeekBoard = (cards, board, viewConfig) => {
  const filters = getFilters({ viewConfig, type: QUESTION });

  return cards
    .filter(card => viewConfig.stepIdsToShowOnBoard.indexOf(card.step.id) !== -1)
    .filter(question => {
      const filterResults = filters.map(filter =>
        filter.filterFn({
          fieldId: filter.fieldId,
          filterValues: viewConfig.field_filters[QUESTION][filter.fieldId],
          card: question,
        }),
      );
      return !filterResults.length || filterResults.indexOf(true) < 0;
    })
    .map(card => {
      const boardFields = getBoardFields({ board, card });

      const dueDate =
        boardFields.due && boardFields.due.value && getNoonOfDay(new Date(boardFields.due.value));

      const to = boardFields.to.value;

      const isAnswered = isQuestionAnswered(board, card);
      return Immutable({
        ...card,
        dueDate,
        disciplines: to,
        isAnswered,
        isOverdue: !isAnswered && isOverdue(dueDate),
      });
    })
    .sort(cardSorter(viewConfig['questionTitle']));
};
