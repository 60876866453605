import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector, createSelector } from 'reselect';
import intl from 'react-intl-universal';
import queryString from 'query-string';

import LinearProgress from '@material-ui/core/LinearProgress';
import Boards from './Boards';
import * as boardsActions from '../../thunks/Boards';
import { actions as communityActions } from '../../ducks/Communities';
import { actions as roomActions } from '../../ducks/Rooms';
import * as communitiesActions from '../../thunks/Communities';
import * as roomsActions from '../../thunks/Rooms';
import PageWrapper from '../../common/PageWrapper';
import { selectIsLoadingBoards, selectBoards, selectIsRequested } from '../../ducks/Boards';
import {
  selectCommunities,
  selectActiveCommunity,
  selectIsLoadingCommunities,
  selectWasRequested,
} from '../../ducks/Communities';
import {
  selectRooms,
  selectActiveRoom,
  selectIsLoadingRooms,
  selectRequestedCommunity,
} from '../../ducks/Rooms';
import { withStyles } from '@material-ui/core/styles';
import { renderSelectField } from '../../utils/RenderUtil';

const styles = {
  field: {
    marginRight: 20,
    minWidth: 200,
  },
  loading: {
    marginTop: 15,
  },
};

const mapStateToProps = () =>
  createStructuredSelector({
    boards: selectBoards(),
    isLoading: createSelector(
      selectIsLoadingBoards(),
      selectIsLoadingCommunities(),
      selectIsLoadingRooms(),
      (isLoadingBoards, isLoadingCommunities, isLoadingRooms) =>
        isLoadingBoards || isLoadingCommunities || isLoadingRooms,
    ),
    isBoardsRequested: selectIsRequested(),
    communities: selectCommunities(),
    communityId: selectActiveCommunity(),
    rooms: selectRooms(),
    roomId: selectActiveRoom(),
    wasCommunityRequested: selectWasRequested(),
    roomsRequestedCommunity: selectRequestedCommunity(),
  });

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      ...boardsActions,
      ...communitiesActions,
      ...roomsActions,
      setActiveCommunity: communityActions.setActiveCommunity,
      setActiveRoom: roomActions.setActiveRoom,
    },
    dispatch,
  ),
});

class BoardsContainer extends React.Component {
  resolveCommunityIdFromUrl = () => queryString.parse(this.props.location.search).communityId || '';
  resolveRoomIdFromUrl = () => queryString.parse(this.props.location.search).roomId || '';

  setupRoomsByCommunity = (communityId, roomId) => {
    if (roomId) {
      this.props.setActiveRoom({ roomId });
    }
    if (this.props.roomsRequestedCommunity !== communityId) {
      this.props.fetchRooms({ communityId });
    }
  };

  shouldChangeHistory = (currentCommunityId, currentRoomId) =>
    this.resolveCommunityIdFromUrl() !== currentCommunityId ||
    this.resolveRoomIdFromUrl() !== currentRoomId;

  changeHistory = (currentCommunityId, currentRoomId) => {
    if (this.shouldChangeHistory(currentCommunityId, currentRoomId)) {
      const newPath = currentRoomId
        ? `?communityId=${currentCommunityId}&roomId=${currentRoomId}`
        : `?communityId=${currentCommunityId}`;

      this.props.history.push(newPath);
    }
  };

  componentDidMount = () => {
    const { communityId, roomId, isBoardsRequested } = this.props;
    const roomIdFromUrl = this.resolveRoomIdFromUrl();
    let currentRoomId = roomIdFromUrl || roomId;

    this.setupRoomsByCommunity(communityId, currentRoomId);
    this.changeHistory(communityId, currentRoomId);

    if (
      currentRoomId &&
      (isBoardsRequested.roomId !== currentRoomId || isBoardsRequested.communityId !== communityId)
    ) {
      this.props.fetchBoards({ communityId, roomId: currentRoomId });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { communityId, roomId, isBoardsRequested } = this.props;
    if (communityId !== prevProps.communityId) {
      this.setupRoomsByCommunity(communityId, roomId);
      this.changeHistory(communityId, roomId);
    }

    if (
      roomId &&
      roomId !== prevProps.roomId &&
      (isBoardsRequested.roomId !== roomId || isBoardsRequested.communityId !== communityId)
    ) {
      this.props.fetchBoards({ communityId, roomId });
    }
  };

  handleCommunityChange = event => {
    this.props.setActiveCommunity({ communityId: event.target.value });
    this.props.setActiveRoom({ roomId: '' });
    this.changeHistory(event.target.value, '');
  };

  handleRoomChange = event => {
    this.props.setActiveRoom({ roomId: event.target.value });
    this.changeHistory(this.props.communityId, event.target.value);
  };

  render() {
    const { isLoading, boards, communities, rooms, communityId, roomId, classes } = this.props;

    const communityOptions = communities.map(community => {
      let label = community.name;

      if (community.project_name) {
        label = community.name + ' - ' + community.project_name;
      }

      if (community.name === '') {
        label = community.id;
      }

      return {
        id: community.id,
        name: label,
      };
    });

    return (
      <React.Fragment>
        <PageWrapper title={intl.get('app_bar.boards')}>
          {renderSelectField({
            name: 'communityId',
            label: intl.get('boards.community'),
            value: communityId,
            handleChange: this.handleCommunityChange,
            options: communityOptions,
            className: classes.field,
          })}
          {renderSelectField({
            name: 'roomId',
            label: intl.get('boards.room'),
            value: roomId,
            handleChange: this.handleRoomChange,
            options: rooms,
            className: classes.field,
          })}
          {isLoading && <LinearProgress className={classes.loading} />}
          {!isLoading && (
            <Boards
              history={this.props.history}
              communityId={communityId}
              communities={communities}
              roomId={roomId}
              rooms={rooms}
              boards={boards}
            />
          )}
        </PageWrapper>
      </React.Fragment>
    );
  }
}

BoardsContainer.propTypes = {
  isBoardsRequested: PropTypes.shape({
    roomId: PropTypes.string,
    communityId: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
  wasCommunityRequested: PropTypes.bool.isRequired,
  communities: PropTypes.arrayOf(PropTypes.shape({})),
  rooms: PropTypes.arrayOf(PropTypes.shape({})),
  boards: PropTypes.arrayOf(PropTypes.shape({})),
  communityId: PropTypes.string.isRequired,
  roomsRequestedCommunity: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  setActiveCommunity: PropTypes.func.isRequired,
  setActiveRoom: PropTypes.func.isRequired,
  fetchCommunities: PropTypes.func.isRequired,
  fetchBoards: PropTypes.func.isRequired,
  fetchRooms: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.shape({
    field: PropTypes.string,
    loading: PropTypes.string,
  }),
};

BoardsContainer.defaultProps = {
  communities: [],
  rooms: [],
  boards: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BoardsContainer));
