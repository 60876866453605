import PropTypes from 'prop-types';

const ListViewConfig = {
  shape: PropTypes.shape({
    sorter: PropTypes.arrayOf(PropTypes.shape({})),
    columnOrder: PropTypes.arrayOf(PropTypes.string),
    visibleColumns: PropTypes.arrayOf(PropTypes.string),
  }),
  defaultValue: {
    sorter: null,
    columnOrder: null,
    visibleColumns: null,
  },
};

export default ListViewConfig;
