import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { actions as appActions, selectZoomLevel } from '../ducks/App';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const mapStateToProps = () =>
  createStructuredSelector({
    zoomLevel: selectZoomLevel(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...appActions,
    },
    dispatch,
  ),
});

// To track zoom in / zoom out event which is not provided by browsers by default
// we subscribe to resize event that is triggered on resize of window and on zoom
// activity. To filter only zoom activity we compare previous zoom
// value (passed as zoomLevel) with current (window.devicePixelRatio). If the values
// are equal then it was resize event otherwise it was zoom event.
class ZoomDetector extends Component {
  zoomHandler = () => {
    const me = this;
    if (me.props.zoomLevel !== window.devicePixelRatio) {
      me.props.actions.zoom({ zoomLevel: window.devicePixelRatio });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.zoomHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.zoomHandler);
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

ZoomDetector.propTypes = {
  zoomLevel: PropTypes.number,
};

ZoomDetector.defaultProps = {
  zoomLevel: 1,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ZoomDetector);
