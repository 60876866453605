import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import AttachmentIcon from '@material-ui/icons/AttachFile';
import CommentIcon from '@material-ui/icons/ModeCommentOutlined';
import DraggableCard from './DraggableCard';
import CardItemTooltip from './CardItemTooltip';
import { getCardDate, getEntryTitle, isNumberField, getTitle } from '../../utils/FieldUtil';
import { QUESTION } from '../../utils/Constants';

export const STATUS_RED = 'statusRed';
export const STATUS_GREEN = 'statusGreen';

const DEFAULT_PADDING_LEFT = '40px';
const HIGH_READABILITY_PADDING_LEFT = '50px';

const styles = () => ({
  card: {
    margin: 8,
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: 38,
    border: '2px dashed transparent;',
  },
  cardFake: {
    opacity: 0.4,
    border: '2px dashed grey',
  },
  cardHighReadability: {
    margin: 8,
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
    background: '#f5f2f1',
    minHeight: 56,
  },
  cardIsMarked: {
    background: '#fee',
    borderWidth: 3,
  },
  title: {
    maxHeight: 30,
    overflow: 'hidden',
    fontSize: 14,
    lineHeight: '15px',
    padding: '6px 14px 0 38px',
    textOverflow: 'ellipsis',
  },
  titleHighReadability: {
    maxHeight: 49,
    overflow: 'hidden',
    fontSize: 24,
    lineHeight: '24px',
    padding: '8px 22px 0 52px',
    textOverflow: 'ellipsis',
  },
  info: {
    display: 'flex',
    paddingRight: '5px',
    fontSize: 10,
    fontWeight: 100,
  },
  infoHighReadability: {
    display: 'flex',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: 18,
    fontWeight: 100,
  },
  typeIconContainer: {
    borderRadius: '50%',
    position: 'absolute',
    top: 4,
    display: 'inline-block',
  },
  tag: {
    height: 26,
    width: 6,
    borderRadius: 3,
    position: 'absolute',
    right: 3,
    top: 6,
    display: 'inline-block',
  },
  tagHighReadability: {
    height: 42,
    width: 12,
    borderRadius: 6,
    position: 'absolute',
    right: 3,
    top: 6,
    display: 'inline-block',
  },
  icon: {
    height: 14,
    width: 14,
  },
  iconHighReadability: {
    height: 24,
    width: 24,
  },
  date: {
    marginTop: 2,
    marginRight: 9,
  },
  heading: {
    fontSize: 8,
  },
  statusGreen: {
    backgroundColor: '#309269',
  },
  statusRed: {
    backgroundColor: '#d64c48',
  },
});

class CardItem extends React.Component {
  calculateCardInfoPaddingLeft = ({ cardType, cardDate, showComments, showAttachments }) => {
    const { board, viewConfig, titleField } = this.props;

    if (cardType !== QUESTION) {
      return viewConfig.highReadability ? HIGH_READABILITY_PADDING_LEFT : DEFAULT_PADDING_LEFT;
    }

    const fields = board.field_config.fields;
    const isTitleNumberField = isNumberField({
      fields,
      titleId: viewConfig[titleField] || getEntryTitle(fields),
    });

    if (viewConfig.highReadability) {
      return isTitleNumberField && cardDate && showAttachments && showComments
        ? DEFAULT_PADDING_LEFT
        : HIGH_READABILITY_PADDING_LEFT;
    }

    return isTitleNumberField
      ? cardDate && showAttachments && showComments
        ? '24px' //when title is number field and we should display all icons in info
        : '32px' //when title is number field and we should not display all icons in info
      : DEFAULT_PADDING_LEFT; //when title is not number field
  };

  render() {
    const {
      classes,
      card,
      board,
      viewConfig,
      titleField,
      getFieldMap,
      typeIcon,
      typeIconTooltip,
      statusColour,
      statusTooltip,
      onCardClick,
      rowIndex,
      columnIndex,
      isMarked,
      type,
    } = this.props;
    const cardTitle = getTitle(card, viewConfig, titleField);
    const commentCaption = intl.get(
      card.comment_count === 1 ? 'boards.tooltips.comment' : 'boards.tooltips.comments',
    );
    const attachmentCaption = intl.get(
      card.child_count === 1 ? 'boards.tooltips.attachment' : 'boards.tooltips.attachments',
    );
    const locale = intl.getInitOptions().currentLocale;
    const cardDate = getCardDate(card, board, locale);
    const showAttachments = viewConfig.showAttachments && card.child_count > 0;
    const showComments = viewConfig.showComments && card.comment_count > 0;

    const cardInfoPaddingLeft = this.calculateCardInfoPaddingLeft({
      cardType: type,
      cardDate,
      showComments,
      showAttachments,
    });

    return (
      <DraggableCard card={card} rowIndex={rowIndex} columnIndex={columnIndex} board={board}>
        <Paper
          className={classNames(
            viewConfig.highReadability ? classes.cardHighReadability : classes.card,
            card.fakeModel && classes.cardFake,
            isMarked && classes.cardIsMarked && false, // do not highlight cards until DARWIN-16807 AC #7 will be clarified
          )}
          elevation={1}
          onClick={() => {
            onCardClick(card);
          }}>
          <Tooltip title={typeIconTooltip}>
            <div className={classes.typeIconContainer}>{typeIcon}</div>
          </Tooltip>
          <Tooltip title={statusTooltip}>
            <div
              className={classNames(
                viewConfig.highReadability ? classes.tagHighReadability : classes.tag,
                classes[statusColour],
              )}
            />
          </Tooltip>
          <CardItemTooltip viewConfig={viewConfig} map={getFieldMap(card)}>
            <div>
              <Typography
                className={
                  viewConfig.highReadability ? classes.titleHighReadability : classes.title
                }
                gutterBottom>
                {cardTitle}
              </Typography>
              <div
                className={viewConfig.highReadability ? classes.infoHighReadability : classes.info}
                style={{ paddingLeft: cardInfoPaddingLeft }}>
                <span style={{ height: 20, marginRight: 1 }}>{cardDate}</span>
                {showComments && (
                  <Tooltip title={String(card.comment_count) + ' ' + commentCaption}>
                    <CommentIcon
                      className={
                        viewConfig.highReadability ? classes.iconHighReadability : classes.icon
                      }
                    />
                  </Tooltip>
                )}
                {showAttachments && (
                  <Tooltip title={String(card.child_count) + ' ' + attachmentCaption}>
                    <AttachmentIcon
                      className={
                        viewConfig.highReadability ? classes.iconHighReadability : classes.icon
                      }
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </CardItemTooltip>
        </Paper>
      </DraggableCard>
    );
  }
}

CardItem.propTypes = {
  card: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  typeIcon: PropTypes.node,
  typeIconTooltip: PropTypes.string,
  statusColour: PropTypes.string, // Valid values: STATUS_RED, STATUS_GREEN
  statusTooltip: PropTypes.string,
  titleField: PropTypes.string,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  isMarked: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // from 'withStyles'
  type: PropTypes.string.isRequired,
  getFieldMap: PropTypes.func,
};

CardItem.defaultProps = {
  onCardClick: () => {},
  typeIcon: '',
  typeIconTooltip: '',
  statusColour: STATUS_RED,
  statusTooltip: '',
  titleField: null,
  rowIndex: 0,
  columnIndex: 0,
  isMarked: false,
  getFieldMap: () => {},
};

export default withStyles(styles)(CardItem);
