import intl from 'react-intl-universal';

export const STEP_COLUMN = 'stepName';
export const COMMENT_COLUMN = 'commentCount';
export const ATTACHMENT_COLUMN = 'childCount';
export const STATUS_COLUMN = 'status';
export const OWNER_COLUMN = 'owner';
export const CREATED_COLUMN = 'creationDate';
export const CREATED_BY_COLUMN = 'createdBy';
export const CHANGED_COLUMN = 'modifiedDate';
export const CHANGED_BY_COLUMN = 'modifiedBy';
export const CONCLUDED_COLUMN = 'concluded';
export const MARGIN_COLUMN = 'marginTrackingEnabled';

export const questionFieldsToColumns = fieldConfig => {
  // Convert field configs to columns
  const columns = fieldsToColumns(fieldConfig);

  // Add margin column if enabled
  if (fieldConfig.margin_tracking_enabled) {
    columns.push({
      name: MARGIN_COLUMN,
      title: intl.get('card.margin'),
    });
  }

  // Add step column
  columns.push({
    name: STEP_COLUMN,
    title: intl.get('board.views.list.columns.step'),
    type: 'string',
  });

  // Add concluded column
  columns.push({
    name: CONCLUDED_COLUMN,
    title: intl.get('card.concluded'),
    type: 'dateTime',
  });

  return columns;
};

export const deliveryFieldsToColumns = fieldConfig => {
  // Convert field configs to columns
  const columns = fieldsToColumns(fieldConfig);

  // Add status column
  columns.push({
    name: STATUS_COLUMN,
    title: intl.get('board.tooltips.status'),
  });

  return columns;
};

export const fieldsToColumns = fieldConfig => {
  let columns = [
    {
      name: COMMENT_COLUMN,
      title: intl.get('board.views.list.columns.comment'),
      type: 'number',
    },
  ];

  const fieldColumns = fieldConfig.fields
    .map(field => ({
      name: field.id,
      title: field.name,
      type: field.type,
    }))
    .asMutable(); // mutable data is required for sorting

  columns = columns.concat(fieldColumns);

  columns.push({
    name: ATTACHMENT_COLUMN,
    title: intl.get('board.views.list.columns.attachment'),
    type: 'number',
  });

  columns.push({
    name: OWNER_COLUMN,
    title: intl.get('card.owner'),
    type: 'dateTime',
  });

  columns.push({
    name: CREATED_COLUMN,
    title: intl.get('card.created'),
    type: 'dateTime',
  });

  columns.push({
    name: CREATED_BY_COLUMN,
    title: intl.get('card.created.by'),
  });

  columns.push({
    name: CHANGED_COLUMN,
    title: intl.get('card.changed'),
    type: 'dateTime',
  });

  columns.push({
    name: CHANGED_BY_COLUMN,
    title: intl.get('card.changed.by'),
  });

  return columns;
};

export const generateNumberOfCardsMessage = ({ totalCount, selectedCount, filteredCount }) => {
  if (totalCount === filteredCount && selectedCount === 0) {
    return intl.get('board.views.list.tooltips.cards.not_filtered_none_selected', { totalCount });
  }
  if (totalCount === filteredCount) {
    return intl.get('board.views.list.tooltips.cards.not_filtered_some_selected', {
      totalCount,
      selectedCount,
    });
  }
  if (selectedCount === 0) {
    return intl.get('board.views.list.tooltips.cards.filtered_none_selected', {
      totalCount,
      filteredCount,
    });
  }

  return intl.get('board.views.list.tooltips.cards.filtered_some_selected', {
    totalCount,
    selectedCount,
    filteredCount,
  });
};
