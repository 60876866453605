import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';

import { alertAction, alertWarning } from '../../thunks/Alerts';
import PageWrapper from '../../common/PageWrapper';
import Steps from './Steps';
import Toolbar from './Toolbar';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import * as milestoneActions from '../../thunks/Milestones';
import * as deliveriesActions from '../../thunks/Deliveries';
import { actions } from '../../ducks/Boards';
import { getNoonOfDay } from '../../utils/DateUtil';
import {
  extractDeliveries,
  extractQuestions,
  flattenArray,
  calculatePPC,
  calculateToValue,
} from '../../utils/FieldUtil';
import {
  actions as viewActions,
  selectBoardCards,
  selectIsLoadingCards,
  selectPossibleResponsible,
  selectViewConfig,
} from '../../ducks/Cards';
import {
  selectActiveBoard,
  selectBoardConfig,
  selectWeekScrollPos,
  actions as bActions,
} from '../../ducks/Boards';
import { WEEK_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import { MILESTONE, DELIVERY } from '../../utils/Constants';
import WeekBoard from './WeekBoard';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { selectMilestones } from '../../ducks/Milestones';
import { selectDeliveries } from '../../ducks/Deliveries';
import { isOverdueColumn } from '../../utils/DNDUtils';
import { selectShowSidebar, selectZoomLevel } from '../../ducks/App';
import CreateCardMenu from './CreateCardMenu';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectBoardCards(),
    milestones: selectMilestones(),
    deliveries: selectDeliveries(),
    config: selectBoardConfig(WEEK_BOARD_VIEW_MODE),
    viewConfig: selectViewConfig(),
    scrollPos: selectWeekScrollPos(),
    showSidebar: selectShowSidebar(),
    zoomLevel: selectZoomLevel(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...boardsActions,
      ...bActions,
      ...cardActions,
      ...milestoneActions,
      ...deliveriesActions,
      expandCollapseWeekViewColumn: actions.expandCollapseWeekViewColumn,
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

class WeekBoardContainer extends React.Component {
  componentDidMount() {
    const { cards, activeBoard } = this.props;
    const { params } = this.props.match;
    if (cards.length > 1 || activeBoard.id.toString() !== params.boardId) {
      this.props.actions.fetchCards({ boardId: params.boardId }).then(action =>
        alertAction({
          action,
          error: intl.get('board.views.week.fetch_cards.error'),
        }),
      );
      this.props.actions.fetchMilestones({ boardId: params.boardId });
      if (activeBoard.delivery_config) {
        this.props.actions.fetchDeliveries({ boardId: params.boardId });
      }
    }
  }

  handleCardClick = ({ card, type }) => {
    const { activeBoard, history } = this.props;
    if (MILESTONE === type) {
      history.push(
        `/${activeBoard.id}/milestoneset/${card.milestoneFolderId}/milestones/${card.milestoneId}?view=${WEEK_BOARD_VIEW_MODE}`,
      );
    } else if (DELIVERY === type) {
      history.push(`/${activeBoard.id}/deliveries/${card.id}?view=${WEEK_BOARD_VIEW_MODE}`);
    } else {
      history.push(`/${activeBoard.id}/cards/${card.id}?view=${WEEK_BOARD_VIEW_MODE}`);
    }
  };

  handleSetScrollPos = ({ pos }) => this.props.actions.setWeekScrollPos({ scrollPos: pos });

  handleUpdateCard = ({ card, columnIndex, rowIndex, sourceRowIndex, config }) => {
    if (card.type === MILESTONE) {
      return this.handleMoveMilestone({
        card,
        board: this.props.activeBoard,
        columnIndex,
        config,
      });
    }
    if (card.type === DELIVERY) {
      return this.handleMoveDelivery({
        card,
        board: this.props.activeBoard,
        columnIndex,
        rowIndex,
        config,
      });
    }
    const { activeBoard } = this.props;
    const initialStepId = activeBoard.step_config.initial_step_id;

    this.props.actions
      .updateCardImmediately({
        boardId: activeBoard.id,
        card,
        stepId: initialStepId,
        data: this.getFieldsWithUpdatedFieldsForQuestion({
          card,
          board: this.props.activeBoard,
          columnIndex,
          rowIndex,
          sourceRowIndex,
          config,
        }),
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('board.views.week.update_card.error'),
          success: intl.get('board.views.week.update_card.success'),
        });
      });
  };

  handleMoveDelivery({ card, board, columnIndex, rowIndex, config }) {
    this.props.actions
      .updateDeliveryImmediately({
        boardId: board.id,
        delivery: card,
        data: this.getFieldsWithUpdatedFieldsForDelivery({
          card,
          board,
          columnIndex,
          rowIndex,
          config,
        }),
      })

      .then(action => {
        alertAction({
          action,
          error: intl.get('delivery.move.error'),
          success: intl.get('delivery.move.success'),
        });
      });
  }

  getFieldsWithUpdatedFieldsForQuestion({
    card,
    board,
    columnIndex,
    rowIndex,
    sourceRowIndex,
    config,
  }) {
    const dateField = board.field_config.answer_due_field;
    const toMemberField = board.field_config.to_member_field;
    const newToFieldValue = board.field_config.allowed_to_authorities.find(
      authority => authority.id === config.xColumns[rowIndex].id,
    );
    const oldToFieldValue = board.field_config.allowed_to_authorities.find(
      authority => authority.id === config.xColumns[sourceRowIndex].id,
    );
    return card.fields.map(field => {
      if (field.id === toMemberField) {
        return field.set(
          'value',
          calculateToValue({
            board,
            card,
            newValue: newToFieldValue,
            oldValue: oldToFieldValue,
          }),
        );
      }
      if (field.id === dateField && !isOverdueColumn(columnIndex)) {
        return field.set('value', config.yColumns[columnIndex].date);
      }
      return field;
    });
  }

  getFieldsWithUpdatedFieldsForDelivery({ card, board, columnIndex, rowIndex, config }) {
    const dateField = board.delivery_config.delivery_date_field;
    const fromMemberField = board.delivery_config.delivery_from_field;
    const fromMemberFieldValue = board.delivery_config.allowed_from_authorities.filter(
      authority => authority.id === config.xColumns[rowIndex].id,
    );
    return card.fields.map(field => {
      if (field.id === fromMemberField) {
        return field.set('value', fromMemberFieldValue);
      }
      if (field.id === dateField && !isOverdueColumn(columnIndex)) {
        return field.set('value', config.yColumns[columnIndex].date);
      }
      return field;
    });
  }

  handleMoveMilestone({ card, board, columnIndex, config }) {
    const { activeBoard } = this.props;

    this.props.actions
      .updateMilestoneImmediately({
        boardId: activeBoard.id,
        milestone: card,
        milestoneSet: card.milestoneFolderId,
        data: this.getFieldsWithUpdatedDateForMilestone({
          card,
          board,
          columnIndex,
          config,
        }),
      })

      .then(action => {
        alertAction({
          action,
          error: intl.get('board.views.tile.move_milestone.error'),
          success: intl.get('board.views.tile.move_milestone.success'),
        });
      });
  }

  getFieldsWithUpdatedDateForMilestone({ card, board, columnIndex, config }) {
    const dateField = board.milestones_config.filter(
      config => config.id === card.milestoneFolderId,
    )[0].date_field; // get date_field
    return card.fields.map(field =>
      field.id === dateField ? field.set('value', config.yColumns[columnIndex].date) : field,
    );
  }

  handleMoveCard = ({ board, card, step }) => {
    this.props.actions
      .moveCardImmediately({
        board,
        card,
        stepId: step.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('board.views.week.move_card.error'),
          success: intl.get('board.views.week.move_card.success'),
        });
      });
  };

  handleConfigChange = viewConfig => {
    this.props.actions.setViewConfig(viewConfig);
  };

  renderCreateCardButton = () => {
    const { activeBoard } = this.props;

    return (
      <CreateCardMenu
        board={activeBoard}
        onCreateQuestion={this.handleQuestionCreateClick}
        onCreateMilestone={this.handleMilestoneCreateClick}
        onCreateDelivery={this.handleDeliveryCreateClick}
      />
    );
  };

  handleQuestionCreateClick = () =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/create-card?view=${WEEK_BOARD_VIEW_MODE}`,
    );

  handleMilestoneCreateClick = ({ milestoneSet }) =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/milestoneset/${milestoneSet.id}/create-milestone?view=${WEEK_BOARD_VIEW_MODE}`,
    );

  handleDeliveryCreateClick = () =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/create-delivery?view=${WEEK_BOARD_VIEW_MODE}`,
    );

  handleExpandAll = () => {
    const { viewConfig, config } = this.props;

    this.handleConfigChange(
      viewConfig.set(
        'expandedWeekRows',
        config.xColumns.map(column => column.id),
      ),
    );
  };

  handleCollapseAll = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('expandedWeekRows', []));
  };

  handleCalculatePPC = (dateFrom, dateTo) => {
    const { cards, deliveries, activeBoard } = this.props;
    const questions = extractQuestions(cards, activeBoard.field_config, activeBoard.step_config);
    const dels = extractDeliveries(deliveries, activeBoard.delivery_config);
    const allCards = calculatePPC(dateFrom, dateTo, questions.concat(dels));
    if (allCards.total < 1) {
      alertWarning(intl.get('board.toolbar.ppc.not_found'));
      this.handleConfigChange(this.props.viewConfig.set('showPPC', false));
    }
    return allCards;
  };

  handleExpandAllCells = () => {
    const { viewConfig, config } = this.props;
    let cells = [];
    config.xColumns.map((xCol, xIndex) =>
      config.yColumns.map((yCol, yIndex) => cells.push(`${xIndex}-${yIndex}`)),
    );
    this.handleConfigChange(
      viewConfig
        .set('expandedWeekCells', cells)
        .set(
          'expandedWeekRows',
          viewConfig.expandedWeekRows.length === 0
            ? config.xColumns.map(column => column.id)
            : viewConfig.expandedWeekRows,
        ),
    );
  };

  handleCollapseAllCells = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('expandedWeekCells', []));
  };

  handleDateChange = (field, value) => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set(field, getNoonOfDay(value)));
  };

  handleStepSidebarClick = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('stepsSidebarOpen', !viewConfig.stepsSidebarOpen));
  };

  render() {
    const {
      activeBoard,
      cards,
      isLoadingCards,
      config,
      viewConfig,
      scrollPos,
      possibleResponsible,
      width,
      milestones,
      deliveries,
      showSidebar,
      history,
    } = this.props;

    // Still loading cards or board
    if (isLoadingCards) {
      return <LinearProgress />;
    }

    const containerWidth = isWidthUp('md', width)
      ? window.innerWidth - (viewConfig.stepsSidebarOpen ? 410 : 115) - (showSidebar ? 205 : 0)
      : window.innerWidth;

    return (
      <PageWrapper title={intl.get('app_bar.week_view')}>
        <Toolbar
          renderCreateCardButton={this.renderCreateCardButton}
          board={activeBoard}
          viewConfig={viewConfig}
          onChange={this.handleConfigChange}
          onExpandAll={this.handleExpandAll}
          onCollapseAll={this.handleCollapseAll}
          expandedRows={viewConfig.expandedWeekRows}
          expandedCells={viewConfig.expandedWeekCells}
          onCalculatePPC={this.handleCalculatePPC}
          onExpandAllCells={this.handleExpandAllCells}
          onCollapseAllCells={this.handleCollapseAllCells}
          possibleResponsible={possibleResponsible}
          onDateChange={this.handleDateChange}
          viewName={WEEK_BOARD_VIEW_MODE}
        />
        <WeekBoard
          width={containerWidth}
          config={config}
          cards={flattenArray(cards)}
          board={activeBoard}
          onUpdateCard={this.handleUpdateCard}
          onCardClick={this.handleCardClick}
          viewConfig={viewConfig}
          scrollPos={scrollPos}
          onChange={this.handleConfigChange}
          onExpandCollapseColumn={this.props.actions.expandCollapseWeekViewColumn}
          onSetScrollPos={this.handleSetScrollPos}
          milestones={milestones}
          deliveries={deliveries}
        />
        <Steps
          open={viewConfig.stepsSidebarOpen}
          onSidebarClick={this.handleStepSidebarClick}
          board={activeBoard}
          cards={cards[1]}
          steps={activeBoard.step_config.steps.filter(
            step => step.id !== activeBoard.step_config.initial_step_id,
          )}
          history={history}
          viewConfig={viewConfig}
          onCardClick={this.handleCardClick}
          onMoveCardToStep={this.handleMoveCard}
          onChange={this.handleConfigChange}
        />
      </PageWrapper>
    );
  }
}

WeekBoardContainer.propTypes = {
  config: PropTypes.object.isRequired,
  isLoadingCards: PropTypes.bool.isRequired,
  activeBoard: PropTypes.object,
  cards: PropTypes.array,
  stepCards: PropTypes.array,
  deliveries: PropTypes.array,
  milestones: PropTypes.array,
  showSidebar: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  viewConfig: PropTypes.shape({
    dateFrom: PropTypes.instanceOf(Date),
    dateTo: PropTypes.instanceOf(Date),
    expandedWeekCells: PropTypes.array,
    expandedWeekRows: PropTypes.array,
    set: PropTypes.func,
    stepsSidebarOpen: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    expandCollapseWeekViewColumn: PropTypes.func,
    setViewConfig: PropTypes.func,
    moveCardImmediately: PropTypes.func,
    updateMilestoneImmediately: PropTypes.func,
    updateCardImmediately: PropTypes.func,
    updateDeliveryImmediately: PropTypes.func,
    fetchMilestones: PropTypes.func,
    fetchCards: PropTypes.func,
    fetchDeliveries: PropTypes.func,
    setWeekScrollPos: PropTypes.func,
  }).isRequired,
  possibleResponsible: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  scrollPos: PropTypes.shape({}),
};

WeekBoardContainer.defaultProps = {
  deliveries: [],
  milestones: [],
  history: {},
  scrollPos: {},
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(WeekBoardContainer)),
);
