import { types } from '../../ducks/Rooms';

const newrelic = window.newrelic;

export default {
  [types.FETCH_ROOMS_SUCCESS]: { category: 'rooms', action: 'fetch' },
  [types.FETCH_ROOMS_FAILURE]: action => ({
    category: 'rooms',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.SET_ACTIVE_ROOM]: action => {
    if (newrelic) {
      newrelic.setCustomAttribute('IxRoom', action.payload.roomId);
    }
    return { category: 'user.rooms', action: 'switch' };
  },
};
