import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Note';
import amber from '@material-ui/core/colors/amber';

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
  },
  iconButton: {
    padding: 0,
  },
  iconBadge: {
    top: 0,
    right: -2,
    transform: 'scale(1)',
  },
  iconBadgeHighReadability: {
    top: 0,
    right: -2,
    transform: 'scale(1.5)',
  },
  icon: {
    color: amber[500],
    width: 48,
    height: 48,
  },
  iconHighReadability: {
    color: amber[500],
    width: 64,
    height: 64,
  },
};

const CardFolderClosed = ({ classes, viewConfig, cardCount, onExpand }) => (
  <div className={classes.center}>
    {cardCount > 0 && (
      <IconButton onClick={onExpand} className={classes.iconButton}>
        <Badge
          classes={{
            badge: viewConfig.highReadability
              ? classes.iconBadgeHighReadability
              : classes.iconBadge,
          }}
          badgeContent={cardCount}
          color="primary">
          <MailIcon
            className={viewConfig.highReadability ? classes.iconHighReadability : classes.icon}
          />
        </Badge>
      </IconButton>
    )}
  </div>
);

CardFolderClosed.propTypes = {
  classes: PropTypes.shape({
    center: PropTypes.string,
    icon: PropTypes.string,
    iconButton: PropTypes.string,
    iconBadge: PropTypes.string,
    iconBadgeHighReadability: PropTypes.string,
    iconHighReadability: PropTypes.string,
  }),
  viewConfig: PropTypes.shape({
    highReadability: PropTypes.bool,
  }).isRequired,
  cardCount: PropTypes.number,
  onExpand: PropTypes.func,
};

CardFolderClosed.defaultProps = {
  cardCount: 0,
  onExpand: () => {},
};

export default withStyles(styles)(CardFolderClosed);
