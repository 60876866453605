import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  icon: {
    display: 'inline-block',
    '& IconButton': {
      position: 'relative',
      vAlign: 'middle',
    },
    '&:hover $active': {
      display: 'none',
    },

    '&:hover $hover': {
      display: 'inline-block',
    },
  },
  active: {
    padding: 8,
    display: 'inline-block',
  },
  hover: {
    padding: 8,
    display: 'none',
  },
};

const ToggleIconButton = ({ classes, tooltipText, generalIcon, hoverIcon, onClick, disabled }) =>
  disabled ? (
    <IconButton className={classes.active} disabled>
      {generalIcon}
    </IconButton>
  ) : (
    <Tooltip title={tooltipText}>
      <div className={classes.icon}>
        <IconButton className={classes.hover} onClick={onClick}>
          {hoverIcon}
        </IconButton>
        <IconButton className={classes.active}>{generalIcon}</IconButton>
      </div>
    </Tooltip>
  );

ToggleIconButton.propTypes = {
  classes: PropTypes.shape({
    active: PropTypes.string,
    icon: PropTypes.string,
    hover: PropTypes.string,
  }).isRequired,
  tooltipText: PropTypes.string,
  generalIcon: PropTypes.node.isRequired,
  hoverIcon: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ToggleIconButton.defaultProps = {
  tooltipText: '',
  disabled: false,
  onClick: () => {},
};

export default withStyles(styles)(ToggleIconButton);
