import nbLocale from 'date-fns/locale/nb';
import svLocale from 'date-fns/locale/sv';
import enLocale from 'date-fns/locale/en-GB';

import enTranslations from '../assets/locale/en.json';
import nbTranslations from '../assets/locale/nb.json';
import svTranslations from '../assets/locale/sv.json';

const mapping = {
  en: enTranslations,
  nb: nbTranslations,
  sv: svTranslations,
};

const languages = {
  no: 'nb', // supporting multiple norwegian languages
  nb: 'nb', // supporting multiple norwegian languages
  en: 'en',
  sv: 'sv',
};

export function getTranslations({ locale }) {
  return mapping[locale];
}

export const getLocale = locale =>
  locale === 'nb' ? nbLocale : locale === 'sv' ? svLocale : enLocale;

export const getLanguage = locale => {
  let language = locale.split('_')[0];
  return languages[language] || 'en'; // in case of unsupported language use English
};
