import { MILESTONE, DELIVERY } from './Constants';

/**
 * Calculate permissions for move to step
 */
export const canMoveToStep = ({ step, card }) => {
  if (card.type === MILESTONE || card.type === DELIVERY) return false;
  if (step.id === card.step.id) {
    return false;
  }

  return !!card.permissions.MOVE[step.id];
};

export const isInStep = ({ step, card }) => {
  return step.id === card.step.id;
};

export const canEditItem = permissions =>
  permissions.ADD_ATTACHMENT || permissions.ADD_COMMENT || permissions.DELETE_ATTACHMENT;

export const canEditQuestion = permissions =>
  canEditItem(permissions) ||
  permissions.ANSWER ||
  permissions.ASK ||
  // MOVE permission contains steps as keys, and true or false as values
  Object.values(permissions.MOVE).some(el => el);

export const canEditDelivery = permissions => canEditItem(permissions) || permissions.UPDATE;

export const canEditMilestone = permissions => canEditItem(permissions) || permissions.UPDATE;
