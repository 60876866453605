import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

// Constants

export const types = {
  // FETCH COMMUNITY ROOMS
  FETCH_ROOMS_REQUEST: 'FETCH_ROOMS_REQUEST',
  FETCH_ROOMS_SUCCESS: 'FETCH_ROOMS_SUCCESS',
  FETCH_ROOMS_FAILURE: 'FETCH_ROOMS_FAILURE',

  // SET ACTIVE ROOM
  SET_ACTIVE_ROOM: 'SET_ACTIVE_ROOM',
};

const initialState = Immutable({
  rooms: [],
  roomId: '',
  isLoadingRooms: false,
  communityId: null,
});

// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_ROOMS_REQUEST: {
      return state.merge({
        isLoadingRooms: true,
        communityId: action.payload.communityId,
      });
    }
    case types.FETCH_ROOMS_SUCCESS: {
      return state.merge({
        rooms: action.payload.rooms,
        isLoadingRooms: false,
      });
    }
    case types.FETCH_ROOMS_FAILURE: {
      return state.merge({
        isLoadingRooms: false,
      });
    }
    case types.SET_ACTIVE_ROOM: {
      return state.merge({
        roomId: action.payload.roomId,
      });
    }
    default:
      return state;
  }
}

// Actions

export const actions = {
  fetchRoomsRequest: ({ communityId }) => ({
    type: types.FETCH_ROOMS_REQUEST,
    payload: { communityId },
  }),
  fetchRoomsSuccess: ({ communityId, rooms }) => ({
    type: types.FETCH_ROOMS_SUCCESS,
    payload: {
      rooms,
    },
  }),
  fetchRoomsError: ({ error }) => ({
    type: types.FETCH_ROOMS_ERROR,
    payload: {
      error,
    },
  }),
  setActiveRoom: ({ roomId }) => ({
    type: types.SET_ACTIVE_ROOM,
    payload: {
      roomId,
    },
  }),
};

// Selectors
const roomsSelector = () => state => state.rooms;

export const selectRooms = () => createSelector(roomsSelector(), roomsState => roomsState.rooms);

export const selectActiveRoom = () =>
  createSelector(roomsSelector(), roomsState => roomsState.roomId);

export const selectIsLoadingRooms = () =>
  createSelector(roomsSelector(), roomsState => roomsState.isLoadingRooms);

export const selectRequestedCommunity = () =>
  createSelector(roomsSelector(), roomsState => roomsState.communityId);
