import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import IconInfo from '@material-ui/icons/InfoOutlined';
import IconEdit from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';

import * as milestoneActionThunks from '../../thunks/Milestones';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  actions as milestoneActions,
  selectMilestones,
  selectIsLoadingMilestones,
  selectLastActiveMilestone,
  selectListViewConfig,
} from '../../ducks/Milestones';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { LIST_MILESTONE_VIEW_MODE } from '../../utils/ViewModeUtil';
import { fieldsToColumns, generateNumberOfCardsMessage } from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import {
  toPrimitiveFieldValue,
  getCellComponentRenderer,
  itemToRow,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditMilestone } from '../../utils/PermissionUtils';
import { Cell } from '@tribiahq/interaxo-react-components';
import ToggleIconButton from '../../common/ToggleIconButton';
import Typography from '@material-ui/core/Typography/Typography';
import Error from '../../common/Error';
import { alertAction } from '../../thunks/Alerts';
import DeleteMilestoneDialog from '../../common/DeleteCardItemDialog';
import DeleteIconButton from '../../common/DeleteIconButton';
import PropTypes from 'prop-types';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';
import ListViewConfig from '../../models/ListViewConfig';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingMilestones: selectIsLoadingMilestones(),
    milestones: selectMilestones(),
    listViewConfig: selectListViewConfig(),
    roomMembers: selectRoomMembers(),
    lastActiveMilestone: selectLastActiveMilestone(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...milestoneActionThunks,
      ...milestoneActions,
      cleanUp: milestoneActions.cleanUp,
      fetchRoomMembers,
    },
    dispatch,
  ),
});

class ListMilestoneContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeleteDialog: false,
      filters: [],
      viewConfig: props.listViewConfig,
    };
  }

  componentDidMount() {
    const { activeBoard, roomMembers } = this.props;
    const { boardId } = this.props.match.params;

    this.props.actions.fetchMilestones({ boardId });

    if (roomMembers.length < 1 || activeBoard.id.toString() !== boardId) {
      this.props.actions.fetchRoomMembers({ boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    this.setState({
      filters: JSON.parse(localStorage.getItem('filters')) || [],
    });
  }

  handleSortChange = ({ sorter, milestoneFolderId }) => {
    const { viewConfig } = this.state;
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;

    this.setState({
      viewConfig: {
        ...viewConfig,
        [milestoneFolderId]: { ...milestoneSetViewConfig, sorter },
      },
    });
  };

  handleColumnOrderChange = ({ columnOrder, milestoneFolderId }) => {
    const { viewConfig } = this.state;
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;

    this.setState({
      viewConfig: {
        ...viewConfig,
        [milestoneFolderId]: { ...milestoneSetViewConfig, columnOrder },
      },
    });
  };

  handleColumnVisibilityChange = ({ visibleColumns, milestoneFolderId }) => {
    const { viewConfig } = this.state;
    const milestoneSetViewConfig = viewConfig[milestoneFolderId] || ListViewConfig.defaultValue;

    this.setState({
      viewConfig: {
        ...viewConfig,
        [milestoneFolderId]: { ...milestoneSetViewConfig, visibleColumns },
      },
    });
  };

  handleSearch = searchTerm => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  handleFilter = filters => {
    localStorage.setItem('filters', JSON.stringify(filters));
    this.setState({
      filters: filters,
    });
  };

  componentWillUnmount() {
    const { viewConfig } = this.state;

    if (viewConfig) {
      this.props.actions.setListViewConfig(viewConfig);
    }

    this.props.actions.cleanUp();
  }

  handleMilestoneEditClick = (milestoneFolderId, milestoneId) => {
    this.props.actions.setLastActiveMilestone({
      lastActiveMilestone: milestoneId,
    });
    this.props.history.push(
      `/${this.props.match.params.boardId}/milestoneset/${milestoneFolderId}/milestones/${milestoneId}?view=${LIST_MILESTONE_VIEW_MODE}`,
    );
  };

  handleMilestoneCreateClick = ({ milestoneSet }) =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/milestoneset/${milestoneSet.id}/create-milestone?view=${LIST_MILESTONE_VIEW_MODE}`,
    );

  handleDelete = () => {
    this.setState({ openDeleteDialog: false });

    const { activeBoard } = this.props;
    this.props.actions
      .deleteMilestone({
        boardId: activeBoard.id,
        milestone: this.state.milestone,
        milestoneSet: this.state.milestoneSet,
      })
      .then(action =>
        alertAction({
          action,
          success: intl.get('board.tooltips.milestone.delete.success.message'),
          error: intl.get('board.tooltips.milestone.delete.error.message'),
          onSuccess: () => {},
        }),
      );
  };

  handleClose = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleClickOpenDialog = (milestone, milestoneSet) => {
    this.setState({
      milestone: milestone,
      milestoneSet: milestoneSet,
      openDeleteDialog: true,
    });
  };

  rowToMilestoneInfo = row => {
    const { roomMembers } = this.props;

    const cardInfo = row.fields.reduce((milestoneInfo, field) => {
      milestoneInfo[field.id] = toPrimitiveFieldValue(field);
      return milestoneInfo;
    }, {});
    return cardInfo.merge({ ...getCardInfo(row, roomMembers) });
  };

  milestonesToRows = (milestones, milestoneFolderConfig) =>
    milestones
      .asMutable()
      .map(milestone => itemToRow({ item: milestone, fieldConfig: milestoneFolderConfig }));

  renderContainerTitle = name => (
    <Typography style={{ marginLeft: 40, fontSize: '1rem' }}>{name}</Typography>
  );

  renderMilestoneLinkButton = ({ row, id, milestoneSet }) => {
    const canEdit = canEditMilestone(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit
              ? 'board.views.list.tooltips.edit_milestone'
              : 'board.views.list.tooltips.view_milestone',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => this.handleMilestoneEditClick(id, row.id)}
        />
        <DeleteIconButton
          tooltipText={intl.get('board.tooltips.milestone.delete.question.title')}
          onClick={() => this.handleClickOpenDialog(row, milestoneSet)}
          disabled={!row.permissions.DELETE}
        />
      </Cell>
    );
  };

  handleExportClick =
    ({ folderName }) =>
    ({ columns, items }) => {
      const exportRequest = generateExportRequest({ columns, items });

      return exportContent({
        boardId: this.props.activeBoard.id,
        exportRequest,
      }).then(response => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  render() {
    const { activeBoard, milestones, lastActiveMilestone, isLoadingMilestones, roomMembers } =
      this.props;

    // Still loading milestones or board
    if (isLoadingMilestones) {
      return <LinearProgress />;
    }

    return (
      <PageWrapper title={intl.get('app_bar.milestone_list_view')}>
        {activeBoard.milestones_config.length ? (
          milestones.map(item => {
            const milestoneSet = activeBoard.milestones_config.filter(
              config => config.id === item.id,
            )[0];
            const viewConfig =
              this.state.viewConfig[milestoneSet.id] || ListViewConfig.defaultValue;

            return (
              <ListBoard
                key={item.id}
                lastActiveItem={lastActiveMilestone}
                board={item}
                items={this.milestonesToRows(item.milestones, milestoneSet)}
                height={milestones.length === 1 ? window.innerHeight - 192 : 400}
                style={{ marginBottom: 15 }}
                columns={fieldsToColumns(milestoneSet)}
                renderCellComponent={getCellComponentRenderer(milestoneSet.fields, roomMembers)}
                editing={{
                  renderLinkButtons: ({ row, id }) =>
                    this.renderMilestoneLinkButton({
                      row,
                      id,
                      milestoneSet: milestoneSet.id,
                    }),
                  width: 110,
                }}
                title={this.renderContainerTitle(milestoneSet.name)}
                rowToInfo={this.rowToMilestoneInfo}
                readOnly={!milestoneSet.permissions.ADD_MILESTONE}
                onItemCreateClick={() => this.handleMilestoneCreateClick({ milestoneSet })}
                tooltips={{
                  create: intl.get('board.tooltips.add_milestone'),
                }}
                filters={this.state.filters}
                onFiltersChange={this.handleFilter}
                onSearch={this.handleSearch}
                onSortChange={sorter =>
                  this.handleSortChange({
                    sorter,
                    milestoneFolderId: milestoneSet.id,
                  })
                }
                onColumnOrderChange={columnOrder =>
                  this.handleColumnOrderChange({
                    columnOrder,
                    milestoneFolderId: milestoneSet.id,
                  })
                }
                onColumnVisibilityChange={visibleColumns =>
                  this.handleColumnVisibilityChange({
                    visibleColumns,
                    milestoneFolderId: milestoneSet.id,
                  })
                }
                searchTerm={searchTerm}
                selection={{
                  enableSelection: true,
                  helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
                  numberOfItemsMessage: generateNumberOfCardsMessage,
                }}
                sorter={viewConfig.sorter}
                defaultSorter={[{ columnName: getEntryTitle(milestoneSet.fields) }]}
                columnOrder={viewConfig.columnOrder}
                visibleColumns={viewConfig.visibleColumns}
                onExportClick={this.handleExportClick({
                  folderName: milestoneSet.name,
                })}
              />
            );
          })
        ) : (
          <Error text={intl.get('milestone.not_found')} />
        )}
        <DeleteMilestoneDialog
          open={this.state.openDeleteDialog}
          onClose={this.handleClose}
          onDelete={this.handleDelete}
          titles={{
            deleteDialogTitle: intl.get('board.tooltips.milestone.delete.question.title'),
            deleteDialogMessage: intl.get('board.tooltips.milestone.delete.question.message'),
          }}
        />
      </PageWrapper>
    );
  }
}

ListMilestoneContainer.propTypes = {
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    milestones_config: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    deleteMilestone: PropTypes.func.isRequired,
    fetchMilestones: PropTypes.func.isRequired,
    cleanUp: PropTypes.func.isRequired,
    fetchRoomMembers: PropTypes.func.isRequired,
    setLastActiveMilestone: PropTypes.func.isRequired,
    setListViewConfig: PropTypes.func.isRequired,
  }).isRequired,
  milestones: PropTypes.array.isRequired,
  lastActiveMilestone: PropTypes.string,
  isLoadingMilestones: PropTypes.bool.isRequired,
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  listViewConfig: PropTypes.shape({}).isRequired,
};

ListMilestoneContainer.defaultProps = {
  roomMembers: [],
  match: {},
  history: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListMilestoneContainer));
