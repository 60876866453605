import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import IconInfo from '@material-ui/icons/InfoOutlined';
import IconEdit from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import { URLSearchParams } from '../../utils/UrlUtil';

import * as cardActionThunks from '../../thunks/Cards';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  selectCards,
  selectIsLoadingCards,
  selectLastActiveCard,
  selectListViewConfig,
  actions as cardActions,
} from '../../ducks/Cards';
import { actions as boardActions } from '../../ducks/Boards';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { LIST_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import {
  questionFieldsToColumns,
  STEP_COLUMN,
  generateNumberOfCardsMessage,
  COMMENT_COLUMN,
  MARGIN_COLUMN,
  CONCLUDED_COLUMN,
} from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import { alertAction } from '../../thunks/Alerts';
import {
  getCellComponentRenderer,
  itemToRow,
  toPrimitiveFieldValue,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditQuestion } from '../../utils/PermissionUtils';
import { Cell } from '@tribiahq/interaxo-react-components';
import ToggleIconButton from '../../common/ToggleIconButton';
import PropTypes from 'prop-types';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectCards(),
    lastActiveCard: selectLastActiveCard(),
    roomMembers: selectRoomMembers(),
    listViewConfig: selectListViewConfig(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...cardActionThunks, ...cardActions, ...boardActions, fetchRoomMembers },
    dispatch,
  ),
});

class ListBoardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: [],
      viewConfig: props.listViewConfig,
    };
  }

  componentDidMount() {
    const { activeBoard, roomMembers } = this.props;
    const { boardId } = this.props.match.params;
    this.props.actions.fetchCards({ boardId });
    if (roomMembers.length < 1 || activeBoard.id.toString() !== boardId) {
      this.props.actions.fetchRoomMembers({ boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    this.setState({
      filters: JSON.parse(localStorage.getItem('filters')) || [],
    });
  }

  componentWillUnmount() {
    const { viewConfig } = this.state;

    if (viewConfig) {
      this.props.actions.setListViewConfig(viewConfig);
    }
  }

  handleSearch = searchTerm => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  handleFilter = filters => {
    localStorage.setItem('filters', JSON.stringify(filters));
    this.setState({
      filters: filters,
    });
  };

  handleCardEditClick = id => {
    const { history, location } = this.props;
    const { params } = this.props.match;
    const step = new URLSearchParams(location.search).get('step');
    this.props.actions.setLastActiveCard({ lastActiveCard: id });

    return step
      ? history.push(`/${params.boardId}/cards/${id}?view=${LIST_BOARD_VIEW_MODE}&step=${step}`)
      : history.push(`/${params.boardId}/cards/${id}?view=${LIST_BOARD_VIEW_MODE}`);
  };

  handleCardCreateClick = () =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/create-card?view=${LIST_BOARD_VIEW_MODE}`,
    );

  handleCardLockClick = id => {
    return this.props.actions
      .lockCard({
        boardId: this.props.match.params.boardId,
        cardId: id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.lock_unlock.error'),
          success: intl.get('card.lock_unlock.success'),
        });
      });
  };

  handleCardUnlockClick = id => {
    return this.props.actions
      .unlockCard({
        boardId: this.props.match.params.boardId,
        cardId: id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.lock_unlock.error'),
          success: intl.get('card.lock_unlock.success'),
        });
      });
  };

  handleExportClick =
    ({ folderName, isDecisionLog }) =>
    config => {
      const columnsToExport = config.columns.filter(
        column =>
          column.name !== COMMENT_COLUMN &&
          column.name !== MARGIN_COLUMN &&
          (isDecisionLog || column.name !== CONCLUDED_COLUMN),
      );

      const exportRequest = generateExportRequest({
        columns: columnsToExport,
        items: config.items,
        includeComments: config.includeComments,
      });

      return exportContent({
        boardId: this.props.activeBoard.id,
        exportRequest,
      }).then(response => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  handleSortChange = sorter => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, sorter } });
  };

  handleColumnOrderChange = columnOrder => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, columnOrder } });
  };

  handleColumnVisibilityChange = visibleColumns => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, visibleColumns } });
  };

  rowToCardInfo = row => {
    const { roomMembers } = this.props;

    const cardInfo = row.fields.reduce((questionInfo, field) => {
      questionInfo[field.id] = toPrimitiveFieldValue(field);
      return questionInfo;
    }, {});
    // Add step name and meta info to cardInfo
    return cardInfo.merge({
      [STEP_COLUMN]: row[STEP_COLUMN],
      ...getCardInfo(row, roomMembers),
    });
  };

  cardsToRows = (cards, step) => {
    const { activeBoard } = this.props;
    cards = step ? cards.filter(card => card.step.id === step) : cards;

    return cards.asMutable().map(card =>
      itemToRow({
        item: card,
        decision_log_step_id: activeBoard.step_config.decision_log_step_id,
        fieldConfig: activeBoard.field_config,
      }),
    );
  };

  renderCardLinkButton = ({ row }) => {
    const canEdit = canEditQuestion(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit
              ? 'board.views.list.tooltips.edit_question'
              : 'board.views.list.tooltips.view_question',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => this.handleCardEditClick(row.id)}
        />
        <ToggleIconButton
          tooltipText={row.locked ? intl.get('card.unlock') : intl.get('card.lock')}
          generalIcon={row.locked ? <Lock /> : <LockOpen />}
          hoverIcon={row.locked ? <LockOpen /> : <Lock />}
          disabled={row.locked ? !row.permissions.UNLOCK : !row.permissions.LOCK}
          onClick={() =>
            row.locked ? this.handleCardUnlockClick(row.id) : this.handleCardLockClick(row.id)
          }
        />
      </Cell>
    );
  };

  render() {
    const { activeBoard, cards, lastActiveCard, isLoadingCards, location, roomMembers } =
      this.props;

    const { viewConfig } = this.state;

    const step = new URLSearchParams(location.search).get('step');

    // Still loading cards or board
    if (isLoadingCards) {
      return <LinearProgress />;
    }

    const isDecisionLog = step && step === activeBoard.step_config.decision_log_step_id;

    return (
      <PageWrapper
        title={intl.get(isDecisionLog ? 'app_bar.decision_log' : 'app_bar.question_list_view')}>
        <ListBoard
          board={activeBoard}
          lastActiveItem={lastActiveCard}
          items={this.cardsToRows(cards, step)}
          height={window.innerHeight - 255}
          columns={questionFieldsToColumns(activeBoard.field_config)}
          renderCellComponent={getCellComponentRenderer(
            activeBoard.field_config.fields,
            roomMembers,
          )}
          editing={{ renderLinkButtons: this.renderCardLinkButton, width: 100 }}
          rowToInfo={this.rowToCardInfo}
          readOnly={isDecisionLog ? !activeBoard.permissions.FULL_CONTROL : false}
          onItemCreateClick={this.handleCardCreateClick}
          tooltips={{ create: intl.get('board.tooltips.add_card') }}
          filters={this.state.filters}
          onFiltersChange={this.handleFilter}
          onSearch={this.handleSearch}
          onSortChange={this.handleSortChange}
          onColumnOrderChange={this.handleColumnOrderChange}
          onColumnVisibilityChange={this.handleColumnVisibilityChange}
          searchTerm={searchTerm}
          selection={{
            enableSelection: true,
            helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
            numberOfItemsMessage: generateNumberOfCardsMessage,
          }}
          sorter={viewConfig.sorter}
          defaultSorter={[{ columnName: getEntryTitle(activeBoard.field_config.fields) }]}
          columnOrder={viewConfig.columnOrder}
          visibleColumns={viewConfig.visibleColumns}
          onExportClick={this.handleExportClick({
            folderName: activeBoard.workflow_config.workflow_name,
            isDecisionLog,
          })}
        />
      </PageWrapper>
    );
  }
}

ListBoardContainer.propTypes = {
  listViewConfig: PropTypes.shape({}).isRequired,
  lastActiveCard: PropTypes.string,
  activeBoard: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  isLoadingCards: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    fetchCards: PropTypes.func,
    unlockCard: PropTypes.func,
    lockCard: PropTypes.func,
    fetchRoomMembers: PropTypes.func,
    setLastActiveCard: PropTypes.func,
    setListViewConfig: PropTypes.func,
  }).isRequired,
  params: PropTypes.shape({
    boardId: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

ListBoardContainer.defaultProps = {
  roomMembers: [],
  match: {},
  history: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListBoardContainer));
