import { map } from 'lodash';

import * as api from '../services/API';
import { actions } from '../ducks/Cards';
import onError from './Error';
import Immutable from 'seamless-immutable';

export const fetchCards = ({ boardId }) => {
  return async dispatch => {
    dispatch(actions.fetchCardsRequest());

    try {
      const cards = await api.fetchCards({ boardId });
      return dispatch(actions.fetchCardsSuccess({ cards }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchCardsError({ error }),
      });
    }
  };
};

export const updateCard = ({ boardId, cardId, data }) => {
  return async dispatch => {
    dispatch(actions.updateCardRequest());

    try {
      const card = await api.updateCard({ boardId, cardId, data });
      return dispatch(actions.updateCardSuccess({ card: card }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCardError({ error }),
      });
    }
  };
};

export const updateCardImmediately = ({ boardId, card, stepId, data }) => {
  return async dispatch => {
    let updatedCard = card.merge({
      fields: card.fields.map(field => {
        const fields = data.filter(updatedField => updatedField.id === field.id);
        return fields.length ? Immutable(fields[0]) : field;
      }),
      fakeModel: true,
    });
    const targetStep = {
      id: stepId,
    };

    if (stepId) {
      updatedCard = updatedCard.merge({
        step: targetStep,
      });
    }

    dispatch(
      actions.updateCardImmediatelySuccess({
        card: updatedCard,
      }),
    );

    try {
      const cardFromBackend = await api.updateCard({
        boardId,
        cardId: card.id,
        stepId,
        data: {
          type: 'entry',
          fields: data,
          step: card.step,
        },
      });

      return dispatch(actions.updateCardSuccess({ card: cardFromBackend }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCardImmediatelyError({ error, card }),
      });
    }
  };
};

export const lockCard = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.lockCardRequest());

    try {
      const card = await api.lockCard({ boardId, cardId });
      return dispatch(actions.lockCardSuccess({ card }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.lockCardError({ error }),
      });
    }
  };
};

export const unlockCard = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.unlockCardRequest());

    try {
      const card = await api.unlockCard({ boardId, cardId });
      return dispatch(actions.unlockCardSuccess({ card: card }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.unlockCardError({ error }),
      });
    }
  };
};

export const createCard = ({ boardId, data }) => {
  return async dispatch => {
    dispatch(actions.createCardRequest());

    try {
      const card = await api.createCard({ boardId, data });
      return dispatch(actions.createCardSuccess({ card: card }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createCardError({ error }),
      });
    }
  };
};

export const moveCardImmediately = ({ board, card, stepId }) => {
  return async dispatch => {
    const updatedCard = card.merge({
      step: {
        id: stepId,
      },
      fakeModel: true,
    });

    dispatch(
      actions.updateCardImmediatelySuccess({
        card: updatedCard,
      }),
    );

    try {
      const cardFromBackend = await api.moveCard({
        boardId: board.id,
        cardId: card.id,
        stepId,
      });
      return dispatch(actions.updateCardSuccess({ card: cardFromBackend }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCardImmediatelyError({ error, card }),
      });
    }
  };
};

export const moveCard = ({ board, cardId, stepId }) => {
  return async dispatch => {
    dispatch(actions.updateCardRequest());

    try {
      const card = await api.moveCard({
        boardId: board.id,
        cardId,
        stepId,
      });
      return dispatch(actions.updateCardSuccess({ card }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCardError({ error }),
      });
    }
  };
};

export const fetchAttachments = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.fetchAttachmentsRequest());
    try {
      const attachments = await api.fetchCardAttachments({
        boardId,
        cardId,
      });
      return dispatch(actions.fetchAttachmentsSuccess({ attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchAttachmentsError({ error }),
      });
    }
  };
};

export const uploadAttachment = ({ boardId, cardId, files }) => {
  return async dispatch => {
    dispatch(actions.uploadAttachmentRequest());
    try {
      const uploads = [];
      const attachUpload = file =>
        uploads.push(
          api.uploadCardAttachment({
            boardId,
            cardId,
            file,
          }),
        );
      map(files, attachUpload);
      const attachments = await Promise.all(uploads);
      return dispatch(actions.uploadAttachmentSuccess({ cardId, attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.uploadAttachmentError({ error }),
      });
    }
  };
};

export const addLink = ({ boardId, cardId, link }) => {
  return async dispatch => {
    dispatch(actions.addLinkRequest());
    try {
      const createdLink = await api.addLink({ boardId, cardId, link });
      return dispatch(actions.addLinkSuccess({ cardId, link: createdLink }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.addLinkError({ error }),
      });
    }
  };
};

export const deleteAttachment = ({ boardId, cardId, attachmentId }) => {
  return async dispatch => {
    dispatch(actions.deleteAttachmentsRequest());
    try {
      await api.deleteCardAttachment({ boardId, cardId, attachmentId });
      return dispatch(actions.deleteAttachmentsSuccess({ cardId, attachmentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteAttachmentsError({ error }),
      });
    }
  };
};

export const createComment = ({ boardId, cardId, content }) => {
  return async dispatch => {
    dispatch(actions.createCommentRequest());
    try {
      const comment = await api.createCardComment({ boardId, cardId, content });
      comment.cardId = cardId;
      return dispatch(actions.createCommentSuccess({ comment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createCommentError({ error }),
      });
    }
  };
};

export const updateComment = ({ boardId, cardId, comment }) => {
  return async dispatch => {
    dispatch(actions.updateCommentRequest());
    try {
      const updatedComment = await api.updateCardComment({
        boardId,
        cardId,
        comment,
      });
      updatedComment.cardId = cardId;
      return dispatch(actions.updateCommentSuccess({ comment: updatedComment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCommentError({ error }),
      });
    }
  };
};

export const deleteComment = ({ boardId, cardId, commentId }) => {
  return async dispatch => {
    dispatch(actions.deleteCommentRequest());
    try {
      await api.deleteCardComment({ boardId, cardId, commentId });
      return dispatch(actions.deleteCommentSuccess({ commentId: commentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteCommentError({ error }),
      });
    }
  };
};

export const fetchComments = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.fetchCommentsRequest());
    try {
      const comments = await api.fetchCardComments({ boardId, cardId });
      comments.forEach(function (comment, index) {
        comment.cardId = cardId;
      });
      return dispatch(actions.fetchCommentsSuccess({ comments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchCommentsError({ error }),
      });
    }
  };
};

export const fetchLog = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.fetchLogRequest());
    try {
      const log = await api.fetchCardLog({ boardId, cardId });
      return dispatch(actions.fetchLogSuccess({ log }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchLogError({ error }),
      });
    }
  };
};

export const fetchPossibleResponsible = ({ boardId, disciplines }) => {
  return async dispatch => {
    dispatch(actions.fetchPossibleResponsibleRequest());

    try {
      let possibleResponsible = [];
      if (disciplines.length > 0) {
        possibleResponsible = await api.getRoomMembers({
          boardId,
          disciplines,
        });
      }
      return dispatch(actions.fetchPossibleResponsibleSuccess({ possibleResponsible }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchPossibleResponsibleError({ error }),
      });
    }
  };
};
