import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { parseISO } from 'date-fns';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import intl from 'react-intl-universal';
import IconOpenTile from '@material-ui/icons/GridOn';
import OpenQuestionList from '@material-ui/icons/List';
import IconDecisionLog from '@material-ui/icons/PlaylistAddCheck';
import OpenDeliveriesList from '@material-ui/icons/LocalShippingOutlined';
import WeekIcon from '@material-ui/icons/DateRange';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import {
  LIST_BOARD_VIEW_MODE,
  LIST_DELIVERY_VIEW_MODE,
  LIST_MILESTONE_VIEW_MODE,
  WEEK_BOARD_VIEW_MODE,
} from '../../utils/ViewModeUtil';
import OpenMilestonesList from '../../common/icons/OutlinedMilestoneIcon';

const styles = theme => ({
  card: {
    maxWidth: 400,
    backgroundColor: '#fafafa',
  },
  header: {
    paddingTop: 6,
    paddingBottom: 0,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

class BoardItem extends React.Component {
  state = { expanded: false };

  handleOpenTileView = () => this.props.history.push(`/${this.props.board.id}`);
  handleOpenWeekView = () =>
    this.props.history.push(`/${this.props.board.id}?view=${WEEK_BOARD_VIEW_MODE}`);
  handleOpenListView = () =>
    this.props.history.push(`/${this.props.board.id}?view=${LIST_BOARD_VIEW_MODE}`);
  handleOpenDecisionLogView = () =>
    this.props.history.push(
      `/${this.props.board.id}?view=${LIST_BOARD_VIEW_MODE}&step=${this.props.board.step_config.decision_log_step_id}`,
    );
  handleOpenMilestoneListView = () =>
    this.props.history.push(`/${this.props.board.id}?view=${LIST_MILESTONE_VIEW_MODE}`);
  handleOpenDeliveryListView = () =>
    this.props.history.push(`/${this.props.board.id}?view=${LIST_DELIVERY_VIEW_MODE}`);
  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { classes, board } = this.props;
    return (
      <Card className={classes.card}>
        {/* Board title */}
        <CardHeader className={classes.header} title={board.name} />
        {/* Board buttons */}
        <CardActions className={classes.actions} disableActionSpacing>
          <Tooltip title={intl.get('boards.tooltips.tile_view')}>
            <IconButton onClick={this.handleOpenTileView} className={'Open-tile-view'}>
              <IconOpenTile />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.get('boards.tooltips.week_view')}>
            <IconButton onClick={this.handleOpenWeekView} className={'Open-week-view'}>
              <WeekIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={intl.get('boards.tooltips.decision_log')}>
            <IconButton
              onClick={this.handleOpenDecisionLogView}
              className={'Open-decision-log-view'}>
              <IconDecisionLog />
            </IconButton>
          </Tooltip>
          {board.milestones_config.length ? (
            <Tooltip title={intl.get('boards.tooltips.milestone_list_view')}>
              <IconButton
                onClick={this.handleOpenMilestoneListView}
                className={'Open-milestone-list-view'}>
                <OpenMilestonesList />
              </IconButton>
            </Tooltip>
          ) : (
            <span />
          )}
          <Tooltip title={intl.get('boards.tooltips.question_list_view')}>
            <IconButton onClick={this.handleOpenListView} className={'Open-question-list-view'}>
              <OpenQuestionList />
            </IconButton>
          </Tooltip>
          {board.delivery_config ? (
            <Tooltip title={intl.get('boards.tooltips.delivery_list_view')}>
              <IconButton
                onClick={this.handleOpenDeliveryListView}
                className={'Open-delivery-list-view'}>
                <OpenDeliveriesList />
              </IconButton>
            </Tooltip>
          ) : (
            <span />
          )}
          <Tooltip title={intl.get('boards.tooltips.expand')}>
            <IconButton
              className={classnames(classes.expand, 'Expand-board-info', {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label={intl.get('boards.tooltips.expand')}>
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
        {/* Hidden board description */}
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>
              {intl.get('boards.creation_date', {
                date: parseISO(board.creation_date),
              })}
            </Typography>
            <Typography>
              {intl.get('boards.last_modified_date', {
                date: parseISO(board.last_modified_date),
              })}
            </Typography>
            <Typography paragraph>
              {intl.get('boards.description', {
                description: board.description,
              })}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

BoardItem.propTypes = {
  classes: PropTypes.object.isRequired,
  board: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    step_config: PropTypes.object,
    milestones_config: PropTypes.arrayOf(PropTypes.shape({})),
    delivery_config: PropTypes.shape({}),
    description: PropTypes.string,
    creation_date: PropTypes.string,
    last_modified_date: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

BoardItem.defaultProps = {
  history: {},
};

export default withStyles(styles)(BoardItem);
