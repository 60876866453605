import intl from 'react-intl-universal';

export const DateFormatter = ({ value }) => {
  return value ? intl.get('common.date', { date: new Date(value) }) : '';
};

export const DateTimeFormatter = ({ value }) => {
  const locale = intl.getInitOptions().currentLocale;

  return value
    ? `${new Date(value).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })}`
    : '';
};
