import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BoardsContainer as Boards } from './boards/';
import { BoardContainer as Board } from './board/';
import { CardContainer } from './card/';
import { DeliveryContainer } from './delivery/';
import { MilestoneContainer } from './milestone/';
import { Docs } from './docs/';
import Error from '../common/Error';
import { logException } from '../store/analytics';

// eslint-disable-next-line react/display-name
export default () => (
  <Switch>
    <Route path="/" exact component={Boards} />
    <Route path="/docs" exact component={Docs} />
    <Route path="/:boardId" exact component={Board} />
    <Route path="/:boardId/create-card" exact component={CardContainer} />
    <Route path="/:boardId/cards/:cardId" exact component={CardContainer} />
    <Route
      path="/:boardId/milestoneset/:milestoneFolderId/create-milestone"
      exact
      component={MilestoneContainer}
    />
    <Route
      path="/:boardId/milestoneset/:milestoneFolderId/milestones/:milestoneId"
      exact
      component={MilestoneContainer}
    />
    <Route path="/:boardId/create-delivery" exact component={DeliveryContainer} />
    <Route path="/:boardId/deliveries/:deliveryId" exact component={DeliveryContainer} />
    <Route
      path="*"
      component={Error}
      onEnter={route =>
        logException({
          type: 'NAVIGATION',
          message: `Failed to process route: ${route.location.pathname}`,
        })
      }
    />
  </Switch>
);
