import { client } from './API';

/**
 * Fetch list of deliveries
 */
export const fetchDeliveries = ({ boardId }) =>
  client.get(`/v1/boards/${boardId}/deliveries`).then(response => response.data);

/**
 * Create delivery
 */
export const createDelivery = ({ boardId, data }) =>
  client.post(`/v1/boards/${boardId}/deliveries`, data).then(response => response.data);

/**
 * Update delivery values
 */
export const updateDelivery = ({ boardId, deliveryId, data }) =>
  client
    .put(`/v1/boards/${boardId}/deliveries/${deliveryId}`, data)
    .then(response => response.data);

/**
 * Delete delivery
 */
export const deleteDelivery = ({ boardId, deliveryId }) =>
  client.delete(`/v1/boards/${boardId}/deliveries/${deliveryId}`).then(response => response.data);

/**
 * Fetch delivery attachment infos
 */
export const fetchDeliveryAttachments = ({ boardId, deliveryId }) =>
  client
    .get(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments`)
    .then(response => response.data);

/**
 * Upload attachment with content to delivery
 */
export const uploadDeliveryAttachment = ({ boardId, deliveryId, file }) => {
  const data = new FormData();
  data.append('file', file, file.name);
  return client
    .post(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments`, data, {
      headers: { Accept: '*/*' },
    })
    .then(response => response.data);
};

/**
 * Add link to delivery
 */
export const addDeliveryLink = ({ boardId, deliveryId, link }) =>
  client
    .post(`/v1/boards/${boardId}/deliveries/${deliveryId}/links`, link)
    .then(response => response.data);

/**
 * Delete attachment from delivery
 */
export const deleteDeliveryAttachment = ({ boardId, deliveryId, attachmentId }) => {
  client
    .delete(`/v1/boards/${boardId}/deliveries/${deliveryId}/attachments/${attachmentId}`)
    .then(response => response.data);
};

export const downloadDeliveryAttachment = ({ boardId, deliveryId, attachmentId, version }) => {
  const config = {
    responseType: 'blob', // important
    params: version ? { version } : {},
  };
  return client.get(
    `/v1/boards/${boardId}/deliveries/${deliveryId}/attachments/${attachmentId}`,
    config,
  );
};

/**
 * Fetch all delivery comments
 */
export const fetchDeliveryComments = ({ boardId, deliveryId }) => {
  return client
    .get(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments`)
    .then(response => response.data);
};

/**
 * Create comment in delivery
 */
export const createDeliveryComment = ({ boardId, deliveryId, content }) => {
  const comment = { content };
  return client
    .post(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments`, comment)
    .then(response => response.data);
};

/**
 * Update comment in deliveries
 */
export const updateDeliveryComment = ({ boardId, deliveryId, comment }) => {
  return client
    .put(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments/${comment.id}`, comment)
    .then(response => response.data);
};

/**
 * Delete comment in deliveries
 */
export const deleteDeliveryComment = ({ boardId, deliveryId, commentId }) => {
  return client
    .delete(`/v1/boards/${boardId}/deliveries/${deliveryId}/comments/${commentId}`)
    .then(response => response.data);
};
