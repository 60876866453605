import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  flexWrapper: {
    display: 'flex',
  },
  relativeWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  progress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -30,
  },
  submitButton: {
    padding: '6px 10px',
  },
  arrowDropDownButton: {
    padding: '4px 8px',
  },
  menu: {
    paddingBottom: 4,
    paddingTop: 4,
  },
  menuItem: {
    paddingBottom: 3,
    paddingTop: 3,
  },
}));

export default function SplitButton({ options, disabled, isLoading, width }) {
  const findDefaultOption = () => {
    const index = options.findIndex(option => !!option.default);
    return index >= 0 ? index : 0;
  };

  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(findDefaultOption);

  const anchorRef = React.useRef(null);
  const classes = useStyles();

  const handleMenuItemClick = option => {
    setSelected(option.index);
    setOpen(false);

    option.handleClick();
  };

  const handleClick = () => {
    setOpen(false);

    const selectedOption = options[selected];
    if (selectedOption) {
      selectedOption.handleClick();
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.flexWrapper}>
      <div className={classes.relativeWrapper}>
        <ButtonGroup variant="contained" color="primary" disabled={disabled} ref={anchorRef}>
          <Button
            onClick={handleClick}
            className={classes.submitButton}
            style={{ width: width - 40 }} // 40 is a select menu button width
            disabled={disabled}>
            {options[selected].title || ''}
          </Button>
          <Button
            color="primary"
            size="small"
            disabled={disabled}
            className={classes.arrowDropDownButton}
            aria-controls={open ? 'button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        {isLoading && <CircularProgress size={21} className={classes.progress} />}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          placement={'bottom-end'}
          style={{ zIndex: 1 }}
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper style={{ width: width }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="button-menu" className={classes.menu}>
                    {options
                      .map((option, index) =>
                        Object.defineProperty(option, 'index', {
                          value: index,
                        }),
                      )
                      .filter(option => option.index !== selected)
                      .map(option => (
                        <MenuItem
                          key={option.index}
                          className={classes.menuItem}
                          onClick={() => handleMenuItemClick(option)}>
                          {option.title}
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

SplitButton.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  width: PropTypes.number,
};

SplitButton.defaultProps = {
  options: [],
  disabled: false,
  isLoading: false,
  width: 195,
};
