import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

const cardSource = {
  canDrag(props) {
    const { ASK, UPDATE, MOVE } = props.card.permissions;
    // Don't allow to drag and drop cards that already was dropped and waiting
    // for response from server. fakeModel is a flag for such models.
    // disabled drag-n-drop for question cards in non-initial steps on TileBoard && QuestionBoard until business logic clarified
    return (
      !props.card.fakeModel &&
      (!props.card.step ||
        props.board.step_config.initial_step_id === props.card.step.id ||
        (props.rowIndex === 0 && props.columnIndex === 0)) &&
      // MOVE permission contains steps as keys, and true or false as values
      (ASK || UPDATE || Object.values(MOVE).some(el => el))
    );
  },
  beginDrag(props) {
    return {
      card: props.card,
      rowIndex: props.rowIndex,
      columnIndex: props.columnIndex,
    };
  },
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

class Draggable extends React.PureComponent {
  render() {
    const { children, connectDragSource, isDragging } = this.props;
    return connectDragSource(<div style={{ opacity: isDragging ? '0.2' : '1' }}>{children}</div>);
  }
}

Draggable.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};
export default DragSource('CARD', cardSource, collect)(Draggable);
