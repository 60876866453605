import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import saveAs from 'file-saver';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import {
  selectDelivery,
  selectAttachments,
  selectComments,
  selectLog,
  selectIsUploadingAttachments,
  selectIsDeletingAttachments,
  actions as deliveriesActions,
} from '../../ducks/Deliveries';
import { downloadDeliveryAttachment } from '../../services/Deliveries';
import { alertAction } from '../../thunks/Alerts';
import * as boardsActions from '../../thunks/Boards';
import * as deliveryActions from '../../thunks/Deliveries';
import Error from '../../common/Error';
import PageWrapper from '../../common/PageWrapper';
import {
  calculateDisabledFieldsForDelivery,
  getRequiredFields,
  getUpdatedFieldsAndValues,
  populateInitialValues,
  getCardMetaInfo,
} from '../../utils/FieldUtil';
import { LIST_DELIVERY_VIEW_MODE, withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import DeliveryAttachments from '../common/Attachments';
import DeliveryComments from '../common/Comments';
import Log from '../common/Log';
import DeleteDeliveryDialogue from '../../common/DeleteCardItemDialog';
import DeleteIconButton from '../../common/DeleteIconButton';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import { selectPossibleResponsible } from '../../ducks/Deliveries';

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    delivery: selectDelivery({ deliveryId: ownProp.match.params.deliveryId }),
    attachments: selectAttachments(),
    log: selectLog(),
    isUploadingAttachments: selectIsUploadingAttachments(),
    isDeletingAttachments: selectIsDeletingAttachments(),
    comments: selectComments(),
    roomMembers: selectRoomMembers(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...deliveryActions,
      ...deliveriesActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

class DeliveryUpdateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    openDeleteDialogue: false,
    closeOnSuccess: false,
  };

  componentDidMount() {
    const { board, roomMembers } = this.props;
    const { boardId, deliveryId } = this.props.match.params;

    this.handleFetchComments({ boardId, deliveryId });
    this.handleFetchAttachments({ boardId, deliveryId });
    this.handleFetchLog({ boardId, cardId: deliveryId });
    // Load board members to enable mentions in comments.
    if (roomMembers.length < 1 || board.id.toString() !== boardId) {
      this.handleFetchRoomMembers({ boardId });
    }
  }

  handleSubmit = ({ values, item }) => {
    const { board } = this.props;

    return this.props.actions
      .updateDelivery({
        boardId: board.id,
        deliveryId: item.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.delivery_config.fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('delivery.update.error'),
          success: intl.get('delivery.update.success'),
          onSuccess: () =>
            this.props.history.push(
              withViewModeQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/deliveries/${action.payload.delivery.id}`,
              ),
            ),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeQuery(`/${this.props.board.id}`));
  };

  handleFetchRoomMembers = ({ boardId }) => {
    this.props.actions.fetchRoomMembers({ boardId });
  };

  handleFetchComments = ({ boardId, deliveryId }) => {
    this.props.actions
      .fetchComments({ boardId, deliveryId })
      .then(action => alertAction({ action, error: intl.get('common.comments.fetch.error') }));
  };

  handleSubmitComment = ({ content }) => {
    const { board, delivery } = this.props;

    return this.props.actions
      .createComment({ boardId: board.id, deliveryId: delivery.id, content })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.add.error'),
          success: intl.get('common.comments.add.success'),
        });
      });
  };

  handleUpdateComment = ({ comment }) => {
    const { board, delivery } = this.props;

    return this.props.actions
      .updateComment({
        boardId: board.id,
        deliveryId: delivery.id,
        commentId: comment.id,
        comment,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.update.error'),
          success: intl.get('common.comments.update.success'),
        });
      });
  };

  handleDeleteComment = ({ comment }) => {
    const { board, delivery } = this.props;

    return this.props.actions
      .deleteComment({
        boardId: board.id,
        deliveryId: delivery.id,
        commentId: comment.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.delete.error'),
          success: intl.get('common.comments.delete.success'),
        });
      });
  };

  handleFetchAttachments = ({ boardId, deliveryId }) => {
    this.props.actions.fetchAttachments({ boardId, deliveryId }).then(action =>
      alertAction({
        action,
        error: intl.get('common.attachments.fetch.error'),
      }),
    );
  };

  handleFetchLog = ({ boardId, cardId }) => {
    this.props.actions
      .fetchLog({ boardId, cardId })
      .then(action => alertAction({ action, error: intl.get('common.log.fetch.error') }));
  };

  handleUploadAttachment = ({ files }) => {
    const { board, delivery } = this.props;

    if (files.length > 0) {
      this.props.actions
        .uploadAttachment({ boardId: board.id, deliveryId: delivery.id, files })
        .then(action => {
          alertAction({
            action,
            success: intl.get('common.attachments.upload.success'),
            error: intl.get('common.attachments.upload.error'),
          });
        });
    }
  };

  handleAddLinks = links => {
    const { board, delivery } = this.props;
    links.forEach(link =>
      this.props.actions
        .addDeliveryLink({ boardId: board.id, deliveryId: delivery.id, link })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.link.add.error'),
            success: intl.get('common.link.add.success'),
          });
        }),
    );
  };

  handleDownloadAttachment = ({ attachment }) => {
    const { board, delivery } = this.props;
    const boardId = board.id;
    const deliveryId = delivery.id;
    let attachmentId = attachment.id;
    let version;
    if (attachment.type === 'internal-link') {
      attachmentId = attachment.destination;
      version = attachment.destination_version;
    }
    downloadDeliveryAttachment({
      boardId,
      deliveryId,
      attachmentId,
      version,
    }).then(response => {
      saveAs(response.data, attachment.name);
    });
  };

  handleDeleteAttachment = ({ attachmentId }) => {
    const { board, delivery } = this.props;

    this.props.actions
      .deleteAttachment({
        boardId: board.id,
        deliveryId: delivery.id,
        attachmentId,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.attachments.delete.error'),
          success: intl.get('common.attachments.delete.success'),
        });
      });
  };

  handleClickDelete = () => {
    this.setState({ openDeleteDialogue: true });
  };

  handleCloseDelete = () => {
    this.setState({ openDeleteDialogue: false });
  };

  handleDelete = () => {
    const { board, delivery } = this.props;
    const { params } = this.props.match;

    this.props.actions.deleteDelivery({ boardId: board.id, delivery }).then(action =>
      alertAction({
        action,
        success: intl.get('board.tooltips.delivery.delete.success.message'),
        error: intl.get('board.tooltips.delivery.delete.error.message'),
        onSuccess: () => {},
      }),
    );
    this.props.history.push(`/${params.boardId}?view=${LIST_DELIVERY_VIEW_MODE}`);
  };

  deleteIconButton = delivery => (
    <DeleteIconButton
      tooltipText={intl.get('board.tooltips.delivery.delete.question.title')}
      onClick={this.handleClickDelete}
      disabled={!delivery.permissions.DELETE}
    />
  );

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const {
      activeCommunity,
      activeRoom,
      board,
      delivery,
      log,
      attachments,
      isUploadingAttachments,
      isDeletingAttachments,
      comments,
      roomMembers,
      possibleResponsible,
    } = this.props;

    // Failed to fetch delivery.
    if (!delivery) {
      return <Error text={intl.get('delivery.not_found')} />;
    }

    return (
      <PageWrapper title={intl.get('app_bar.delivery_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              formRef={this.formRef}
              item={delivery}
              initialValues={populateInitialValues({
                item: delivery,
                fields: board.delivery_config.fields,
              })}
              disabledFields={calculateDisabledFieldsForDelivery({
                permissions: delivery.permissions,
                fields: board.delivery_config.fields,
              })}
              disableDelete={false}
              requiredFields={getRequiredFields({
                item: delivery,
                fields: board.delivery_config.fields,
              })}
              type={board.type}
              fieldConfig={board.delivery_config}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              deleteIcon={this.deleteIconButton(delivery)}
              restrictions={{
                'task-done': (fieldConfig, field) => ({}),
                'task-responsible': (fieldConfig, field) => ({}),
                'rich-text': (fieldConfig, field) => ({}),
                'auto-number': (fieldConfig, field) => ({}),
                'unique-document-id': (fieldConfig, field) => ({}),
                'sequence-number': (fieldConfig, field) => ({}),
                member: (fieldConfig, field) => ({}),
                list: (fieldConfig, field) => ({}),
                date: (fieldConfig, field) => {
                  if (fieldConfig.delivery_met_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => ({}),
                numeric: (fieldConfig, field) => ({}),
              }}
              metaInfo={getCardMetaInfo(delivery, roomMembers)}
              possibleResponsible={possibleResponsible}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  default: true,
                  handleClick: () => this.handleSaveClick({ closeForm: true }),
                },
                {
                  title: intl.get('common.form.save'),
                  handleClick: () => this.handleSaveClick({ closeForm: false }),
                },
              ]}
            />
            <DeleteDeliveryDialogue
              open={this.state.openDeleteDialogue}
              onClose={this.handleCloseDelete}
              onDelete={this.handleDelete}
              titles={{
                deleteDialogTitle: intl.get('board.tooltips.delivery.delete.question.title'),
                deleteDialogMessage: intl.get('board.tooltips.delivery.delete.question.message'),
              }}
            />
            <DeliveryAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={delivery}
              attachments={attachments}
              members={roomMembers}
              isUploading={isUploadingAttachments}
              isDeleting={isDeletingAttachments}
              onUploadAttachment={this.handleUploadAttachment}
              onDeleteAttachment={this.handleDeleteAttachment}
              onDownloadAttachment={this.handleDownloadAttachment}
              onAddLinks={this.handleAddLinks}
            />
            <Log board={board} log={log} members={roomMembers} item={delivery} />
          </Grid>
          <Grid item sm={12} md={4} xs={12}>
            <DeliveryComments
              board={board}
              item={delivery}
              comments={comments}
              onSubmitComment={this.handleSubmitComment}
              onUpdateComment={this.handleUpdateComment}
              onDeleteComment={this.handleDeleteComment}
              members={roomMembers}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

DeliveryUpdateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  board: PropTypes.object,
  isLoadingBoard: PropTypes.bool,
  delivery: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      DELETE: PropTypes.bool,
    }),
  }),
  isLoadingDeliveries: PropTypes.bool,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploadingAttachments: PropTypes.bool,
  isDeletingAttachments: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
    deliveryId: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    updateDelivery: PropTypes.func,
    fetchRoomMembers: PropTypes.func,
    fetchComments: PropTypes.func,
    createComment: PropTypes.func,
    fetchAttachments: PropTypes.func,
    uploadAttachment: PropTypes.func,
    addDeliveryLink: PropTypes.func,
    deleteAttachment: PropTypes.func,
    deleteDelivery: PropTypes.func,
    fetchLog: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      cardId: PropTypes.string,
      deliveryId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  log: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
};

DeliveryUpdateContainer.defaultProps = {
  board: null,
  isLoadingBoard: false,
  delivery: null,
  isLoadingDeliveries: false,
  attachments: [],
  isUploadingAttachments: false,
  isDeletingAttachments: false,
  comments: [],
  roomMembers: [],
  match: {},
  history: {},
  possibleResponsible: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliveryUpdateContainer));
