import React, { PureComponent } from 'react';
import classNames from 'classnames';
import intl from 'react-intl-universal';

import { DatePicker as MuiDatePicker } from 'material-ui-pickers';
import { IconButton, withStyles } from '@material-ui/core';
import DateRange from '@material-ui/icons/DateRange';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { format, isSameDay, startOfISOWeek, getISOWeek } from 'date-fns';
import PropTypes from 'prop-types';

class DayPicker extends PureComponent {
  _renderDay = (date, selectedDate, dayInCurrentMonth) => {
    const { classes } = this.props;
    const start = startOfISOWeek(date);
    const isSelectedDate = isSameDay(date, selectedDate);
    const isFirstDayInWeek = isSameDay(date, start);
    const week = getISOWeek(date);
    const wrapperClassName = classNames({
      [classes.highlight]: isSelectedDate,
      [classes.firstHighlight]: isSelectedDate,
      [classes.endHighlight]: isSelectedDate,
    });

    const dayClassName = classNames(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && isSelectedDate,
    });

    return (
      <div className={wrapperClassName}>
        {isFirstDayInWeek && <div className={classes.week}>{week}</div>}
        <IconButton className={dayClassName}>
          <span> {format(date, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { field, disabled, handleChange, error, classes, ...other } = this.props;

    return (
      <MuiDatePicker
        keyboard
        allowKeyboardControl
        keyboardIcon={<DateRange />}
        leftArrowIcon={<KeyboardArrowLeft />}
        rightArrowIcon={<KeyboardArrowRight />}
        disabled={disabled}
        clearable
        label={field.name}
        name={field.id}
        renderDay={this._renderDay}
        labelFunc={date => (!date || isNaN(date) ? '' : intl.get('common.date', { date }))}
        onKeyDown={e => {
          // if Tab clicked then do nothing
          // else prevent default behavior
          if (e.nativeEvent.keyCode !== 9) {
            e.preventDefault();
          }
        }}
        onChange={value => {
          if (value) {
            // There are 2 scenarios:
            // 1st: initial value is empty and user just clicks OK button to confirm current date
            // in that case the value is set to Date.now() (keeping current time)
            // 2nd: initial value can be empty or already set to any date. And user clicks date
            // that corresponds to new value and then confirms hos choice by clicking OK button.
            // In that case the value is set as selected date with time 00:00:00
            // To minimize different time zones effect we should fix the time to noon.
            value.setHours(12);
          }
          handleChange(field.id, value);
        }}
        minDateMessage={'TTT'}
        {...other}
        helperText={error}
        error={!!error}
        okLabel={intl.get('common.form.datepicker.ok')}
        cancelLabel={intl.get('common.form.datepicker.cancel')}
        clearLabel={intl.get('common.form.datepicker.clear')}
        todayLabel={intl.get('common.form.datepicker.today')}
      />
    );
  }
}

const styles = theme => ({
  week: {
    position: 'absolute',
    marginLeft: -8,
    marginTop: 9,
    fontSize: 10,
    color: 'red',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
});

DayPicker.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  classes: PropTypes.shape({
    highlight: PropTypes.string,
    firstHighlight: PropTypes.string,
    endHighlight: PropTypes.string,
    day: PropTypes.string,
    nonCurrentMonthDay: PropTypes.string,
    highlightNonCurrentMonthDay: PropTypes.string,
    week: PropTypes.string,
  }).isRequired,
};

DayPicker.defaultProps = {
  disabled: false,
  error: undefined,
};

export default withStyles(styles)(DayPicker);
