import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import intl from 'react-intl-universal';
import BoardItem from './BoardItem';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  gridItem: {
    flexBasis: 'auto',
  },
};

class Boards extends React.Component {
  render() {
    return <React.Fragment>{this.getContent()}</React.Fragment>;
  }

  getContent = () => {
    const { boards, rooms, roomId, classes } = this.props;
    let noRoomSelected;
    let room;

    if (roomId) {
      room = rooms.filter(room => room.id === roomId)[0];
    }
    noRoomSelected = !roomId || !room;
    const noBoardsFound = boards.length === 0;
    if (noRoomSelected) {
      return <Typography variant="body1">{intl.get('boards.select_any')}</Typography>;
    }
    if (noBoardsFound) {
      return <Typography variant="body1">{intl.get('boards.not_found')}</Typography>;
    }

    return (
      <ExpansionPanel defaultExpanded key={`boards-item-${room.id}`}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{room.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            {boards.map(board => (
              <Grid className={classes.gridItem} key={`board-${board.id}`} item xs>
                <BoardItem board={board} history={this.props.history} />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };
}

Boards.propTypes = {
  classes: PropTypes.shape({
    gridItem: PropTypes.string,
  }).isRequired,
  boards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  communities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rooms: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  communityId: PropTypes.string.isRequired,
  roomId: PropTypes.string.isRequired,
  history: PropTypes.shape({}),
};

Boards.defaultProps = {
  history: {},
};

export default withStyles(styles)(Boards);
