import React from 'react';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import { Table, Cell } from '@tribiahq/interaxo-react-components';
import Toolbar from '@material-ui/core/Toolbar';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Paper from '@material-ui/core/Paper';
import DetailedCardRow from './DetailedCardRow';
import {
  COMMENT_COLUMN,
  ATTACHMENT_COLUMN,
  OWNER_COLUMN,
  CREATED_COLUMN,
  CREATED_BY_COLUMN,
  CHANGED_COLUMN,
  CHANGED_BY_COLUMN,
} from '../../utils/ListViewUtil';
import { getCustomSorters } from '../../utils/RenderUtil';
import PropTypes from 'prop-types';
import { connectProps } from '@devexpress/dx-react-core';
import ToolbarComponent from '../common/ToolbarComponent';
import { ColumnChooserButton } from '../common/ColumnChooserButton';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import CommentIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import AttachmentIcon from '@material-ui/icons/AttachFileOutlined';

const styles = () => ({
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  toolbar: {
    borderTop: '1px solid rgb(191, 191, 191)',
    backgroundColor: 'white',
  },
  flexGrow: {
    flex: '1 1 auto',
  },
});

// TODO: Shown column names..
const defaultHiddenColumnNames = [
  OWNER_COLUMN,
  CREATED_COLUMN,
  CREATED_BY_COLUMN,
  CHANGED_COLUMN,
  CHANGED_BY_COLUMN,
];

class ListBoard extends React.Component {
  state = {
    filtering: this.props.filters && !!this.props.filters.length,
    filteredRows: [],
    selectedRows: [],
    hiddenColumnNames: [],
    disableExport: false,
  };

  constructor(props) {
    super(props);
    this.vtRef = React.createRef();

    this.toolbarRoot = connectProps(ToolbarComponent, () => {
      const { readOnly, title, subscription } = props;
      const { filtering } = this.state;

      const tooltips = {
        ...props.tooltips,
        export: intl.get('board.views.list.tooltips.export'),
        add_filtering: intl.get('board.views.list.tooltips.add_filtering'),
        remove_filtering: intl.get('board.views.list.tooltips.remove_filtering'),
      };

      return {
        readOnly,
        filtering,
        handleItemCreateClick: this.handleItemCreateClick,
        handleExportClick: this.handleExportClick,
        handleFilteringToggle: this.handleFilteringToggle,
        tooltips,
        title,
        subscription,
      };
    });
  }

  componentDidMount() {
    const defaultHiddenColumnNames = this.extractHiddenColumns({
      board: this.props.board,
      columns: this.props.columns,
    });
    if (this.props.lastActiveItem) {
      this.vtRef.current.scrollToRow(this.props.lastActiveItem);
    }

    this.setState({ hiddenColumnNames: defaultHiddenColumnNames });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.toolbarRoot.update();
  }

  // Create item toolbar button is clicked
  handleItemCreateClick = () => this.props.onItemCreateClick();

  handleExportClick = () => {
    const { columns, onExportClick, items } = this.props;
    const { hiddenColumnNames, selectedRows, filteredRows } = this.state;

    let itemsToExport =
      selectedRows.length > 0
        ? selectedRows
        : (filteredRows.length ? filteredRows : items).map(item => item.id);

    const columnsToExport = columns.filter(column => !hiddenColumnNames.includes(column.name)); // only visible should be exported

    const includeComments = !hiddenColumnNames.includes(COMMENT_COLUMN);

    return onExportClick({
      columns: columnsToExport,
      items: itemsToExport,
      includeComments,
    });
  };

  // Filter in toolbar is toggled
  handleFilteringToggle = () => {
    this.props.onFiltersChange([]);
    this.setState({ filtering: !this.state.filtering });
  };

  extractHiddenByDefaultColumns = columns => {
    const { visibleColumns } = this.props;

    if (visibleColumns && visibleColumns.length > 0) {
      return columns
        .filter(column => !visibleColumns.includes(column.name))
        .map(column => column.name);
    }

    return columns
      .filter(column => defaultHiddenColumnNames.includes(column.name))
      .map(column => column.name);
  };

  // By default show only QUESTION column for small devices
  extractHiddenColumns = ({ board, columns }) =>
    columns.length > 1 && isWidthDown('sm', this.props.width)
      ? columns
          .filter(column => board.field_config && board.field_config.question_field === column.name)
          .map(column => column.name)
      : this.extractHiddenByDefaultColumns(columns);

  render() {
    const {
      classes,
      board,
      items,
      height,
      style,
      columns,
      renderCellComponent,
      editing,
      rowToInfo,
      getRowId,
      selection,
      columnOrder,
      sorter,
      defaultSorter,
    } = this.props;
    const { filtering } = this.state;

    const defaultColumnWidths = columns.map(column => ({
      columnName: column.name,
      width: column.name === 'commentCount' || column.name === 'childCount' ? 70 : 170,
    }));

    return (
      <Paper style={style}>
        <Table
          columns={columns || []}
          rows={items}
          virtual
          height={height}
          filtering={
            filtering && {
              rowConfig: {
                messages: {
                  filterPlaceholder: intl.get('board.views.list.placeholders.filter'),
                },
              },
              // TODO: Create custom filter, so we can filter on the number of comments and attachments, instead of disabling filtering.
              stateConfig: {
                columnExtensions: [
                  {
                    columnName: COMMENT_COLUMN,
                    filteringEnabled: false,
                  },
                  {
                    columnName: ATTACHMENT_COLUMN,
                    filteringEnabled: false,
                  },
                ],
                onFiltersChange: this.props.onFiltersChange,
                defaultFilters: this.props.filters,
              },
            }
          }
          gridConfig={{ getRowId: getRowId }}
          selection={
            selection.enableSelection && {
              stateConfig: {
                onSelectionChange: selection => this.setState({ selectedRows: selection }),
              },
              config: {
                selectByRowClick: true,
                highlightRow: true,
                showSelectionColumn: false,
              },
            }
          }
          sorting={{
            // TODO: Create custom sorter, so we can sort on the number of comments and attachments, instead of disabling sorting.
            stateConfig: {
              columnExtensions: [
                {
                  columnName: COMMENT_COLUMN,
                  sortingEnabled: false,
                },
                {
                  columnName: ATTACHMENT_COLUMN,
                  sortingEnabled: false,
                },
              ],
              defaultSorting: sorter || defaultSorter,
              onSortingChange: sorting => this.props.onSortChange(sorting),
            },
            config: {
              columnExtensions: getCustomSorters(columns),
            },
          }}
          tableHeaderRowConfig={{
            messages: {
              sortingHint: intl.get('board.views.list.tooltips.sort'),
            },
            contentComponent: ({ children, column }) => (
              <TableHeaderRow.Content>
                {column.name === 'commentCount' ? (
                  <CommentIcon />
                ) : column.name === 'childCount' ? (
                  <AttachmentIcon />
                ) : (
                  children
                )}
              </TableHeaderRow.Content>
            ),
          }}
          editing={{
            config: {
              cellComponent: ({ row }) => {
                return editing.renderLinkButtons({ row, id: board.id });
              },
              width: editing.width,
            },
          }}
          columnResizing={{
            defaultColumnWidths,
          }}
          columnReordering={{
            config: {
              customOrder: columnOrder,
              onOrderChange: order => this.props.onColumnOrderChange(order),
            },
          }}
          columnVisibility={{
            config: {
              defaultHiddenColumnNames: this.extractHiddenColumns({
                board,
                columns,
              }),
              onHiddenColumnNamesChange: hiddenColumnNames => {
                const visibleColumns = this.props.columns
                  .map(column => column.name)
                  .filter(columnName => !hiddenColumnNames.includes(columnName));
                this.props.onColumnVisibilityChange(visibleColumns);
                this.setState({ hiddenColumnNames });
              },
            },
            columnChooserConfig: {
              messages: {
                showColumnChooser: intl.get('board.views.list.tooltips.show_hide_columns'),
              },
              toggleButtonComponent: ColumnChooserButton,
            },
          }}
          rowDetailing={{
            config: {
              contentComponent: ({ row }) => (
                <DetailedCardRow row={row} columns={columns} rowToInfo={rowToInfo} />
              ),
            },
          }}
          toolbarConfig={{
            // Override toolbar to contain filtering toggle and create card button
            rootComponent: this.toolbarRoot,
          }}
          search={{
            panelConfig: {
              messages: {
                searchPlaceholder: intl.get('board.views.list.placeholders.search'),
              },
            },
            stateConfig: {
              defaultValue: this.props.searchTerm,
              onValueChange: this.props.onSearch,
            },
          }}
          tableConfig={{
            //the average value for a table whose rows have different heights
            //in our case, the height of the row with details may vary depending on the number of fields in the entry
            estimatedRowHeight: 75, // TODO: investigate why 1000?
            ref: this.vtRef,
            cellComponent: obj => <Cell>{renderCellComponent(obj)}</Cell>,
            messages: {
              noData: <div className={classes.noData}>{intl.get('common.content.empty')}</div>,
            },
          }}
        />
        {selection.enableSelection && (
          <Toolbar className={classes.toolbar}>
            {selection.helperText || ''}
            <div className={classes.flexGrow} />
            {selection.numberOfItemsMessage &&
              selection.numberOfItemsMessage({
                totalCount: items.length,
                selectedCount: this.state.selectedRows.length,
                filteredCount: this.state.filteredRows.length,
              })}
          </Toolbar>
        )}
      </Paper>
    );
  }
}

ListBoard.propTypes = {
  classes: PropTypes.shape({
    noData: PropTypes.string,
    toolbar: PropTypes.string,
    flexGrow: PropTypes.string,
  }).isRequired,
  board: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  height: PropTypes.number,
  width: PropTypes.number.isRequired,
  style: PropTypes.shape({
    marginBottom: PropTypes.number,
  }),
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  renderCellComponent: PropTypes.func.isRequired,
  title: PropTypes.node,
  editing: PropTypes.shape({
    renderLinkButtons: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  rowToInfo: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  subscription: PropTypes.node,
  tooltips: PropTypes.shape({
    create: PropTypes.string.isRequired,
  }).isRequired,
  onItemCreateClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onColumnOrderChange: PropTypes.func.isRequired,
  onColumnVisibilityChange: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  getRowId: PropTypes.func,
  selection: PropTypes.shape({
    enableSelection: PropTypes.bool,
    helperText: PropTypes.string,
    numberOfItemsMessage: PropTypes.func,
  }),
  sorter: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string,
      direction: PropTypes.string,
    }),
  ),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  lastActiveItem: PropTypes.string,
  defaultSorter: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      direction: PropTypes.string,
    }),
  ),
};

ListBoard.defaultProps = {
  height: window.innerHeight - 190,
  subscription: <React.Fragment />,
  searchTerm: '',
  getRowId: row => row['id'],
  selection: {
    enableSelection: false,
    helperText: '',
    numberOfItemsMessage: ({ totalCount, selectedCount, filteredCount }) =>
      `Displaying ${filteredCount} of ${totalCount} cards. Selected: ${selectedCount}`,
  },
  defaultSorter: [],
};

export default withStyles(styles)(withWidth()(ListBoard));
