import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import BoardsIcon from '@material-ui/icons/Public';
import BoardIcon from '@material-ui/icons/GridOn';
import intl from 'react-intl-universal';
import ListView from '@material-ui/icons/List';
import WeekIcon from '@material-ui/icons/DateRange';
import DecisionLog from '@material-ui/icons/PlaylistAddCheck';
import DeliveriesList from '@material-ui/icons/LocalShippingOutlined';
import List from '@material-ui/core/List';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import interaxa_X from '../assets/img/INTERAXO_X.png';
import interaxo_logo_blue from '../assets/img/interaxo-logo-full-color-rgb.png';
import { ENVIRONMENTAL_VARIABLES } from '../utils/Environment';

import NavLink from './NavLink';
import NavExternalLink from './NavExternalLink';
import {
  WEEK_BOARD_VIEW_MODE,
  LIST_BOARD_VIEW_MODE,
  LIST_MILESTONE_VIEW_MODE,
  LIST_DELIVERY_VIEW_MODE,
} from '../utils/ViewModeUtil';
import MilestonesList from './icons/OutlinedMilestoneIcon';

const styles = theme => ({
  button: theme.mixins.gutters({
    borderRadius: 0,
    justifyContent: 'flex-start',
    textTransform: 'none',
    width: '100%',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  drawerPaper: {
    position: 'fixed',
    height: '100%',
    width: 250,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 45,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  drawerInner: {
    width: 250,
    height: '100%',
    paddingTop: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerHeader: {
    backgroundColor: '#eafbfc',
  },
  headerInner: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  contextItem: {
    marginLeft: 26,
    marginTop: 20,
    fontSize: 13,
  },
  contextItemChild: {
    marginLeft: 50,
    fontSize: 13,
  },
  contextItemSeparator: {
    marginTop: 30,
    marginLeft: 75,
    fontSize: 13,
  },
  sidebarToggler: {
    marginLeft: 25,
  },
  interaxoIcon: {
    width: 18,
    height: 20,
    marginLeft: 13,
    marginBottom: 5,
  },
  openInteraxo: {
    width: 18,
    height: 20,
    margin: '-8px 9px -8px -10px',
    padding: '3px 10px',
  },
  interaxoIconBig: {
    width: 120,
    // 46px for margin-left is result of subtraction of 57px (needed for the content
    // fits the width of sidebar) and 11px (space between content and right border of sidebar)
    marginLeft: 46,
    marginBottom: 5,
  },
});

const Sidebar = ({ classes, show, onMenuClick, activeBoard }) => {
  return (
    <Drawer
      open={show}
      variant="permanent"
      classes={{
        paper: classNames(classes.drawerPaper, !show && classes.drawerPaperClose),
      }}>
      <List className={classes.drawerInner}>
        <div className={classes.drawerHeader}>
          <div className={classes.headerInner}>
            {!show && <img className={classes.interaxoIcon} src={interaxa_X} alt={'X'} />}
            {show && (
              <img className={classes.interaxoIconBig} src={interaxo_logo_blue} alt={'Interaxo'} />
            )}
            <Tooltip title={intl.get('app_bar.sidebar.close.tooltip')}>
              <IconButton className={classes.sidebarToggler} onClick={onMenuClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
          <NavLink
            icon={<BoardsIcon style={{ marginRight: 12 }} />}
            title={intl.get('app_bar.boards')}
            href={`/`}
          />
        </div>
        {activeBoard && [
          <NavLink
            className={'Tile-view'}
            key={'app_bar.tile_view'}
            icon={<BoardIcon style={{ marginRight: 12 }} />}
            title={intl.get('app_bar.tile_view')}
            href={`/${activeBoard.id}`}
          />,
          <NavLink
            className={'Week-view'}
            key={'app_bar.week_view'}
            icon={<WeekIcon style={{ marginRight: 12 }} />}
            title={intl.get('app_bar.week_view')}
            href={`/${activeBoard.id}?view=${WEEK_BOARD_VIEW_MODE}`}
          />,
          <NavLink
            className={'Decision-log-view'}
            key={'app_bar.decision_log'}
            icon={<DecisionLog style={{ marginRight: 12 }} />}
            title={intl.get('app_bar.decision_log')}
            href={`/${activeBoard.id}?view=${LIST_BOARD_VIEW_MODE}&step=${activeBoard.step_config.decision_log_step_id}`}
          />,
          <Divider key={'divider'} light />,
          activeBoard.milestones_config.length ? (
            [
              <NavLink
                className={'Milestone-list-view'}
                key={'app_bar.milestone_list_view'}
                icon={<MilestonesList style={{ marginRight: 12 }} />}
                title={intl.get('app_bar.milestone_list_view')}
                href={`/${activeBoard.id}?view=${LIST_MILESTONE_VIEW_MODE}`}
              />,
            ]
          ) : (
            <span key={'span1'} />
          ),
          <NavLink
            className={'Question-list-view'}
            key={'app_bar.question_list_view'}
            icon={<ListView style={{ marginRight: 12 }} />}
            title={intl.get('app_bar.question_list_view')}
            href={`/${activeBoard.id}?view=${LIST_BOARD_VIEW_MODE}`}
          />,
          activeBoard.delivery_config ? (
            [
              <NavLink
                className={'Delivery-list-view'}
                key={'app_bar.delivery_list_view'}
                icon={<DeliveriesList style={{ marginRight: 12 }} />}
                title={intl.get('app_bar.delivery_list_view')}
                href={`/${activeBoard.id}?view=${LIST_DELIVERY_VIEW_MODE}`}
              />,
            ]
          ) : (
            <span key={'span2'} />
          ),
          <NavExternalLink
            key={'app_bar.open_interaxo'}
            icon={<img className={classes.openInteraxo} src={interaxa_X} alt={'X'} />}
            title={intl.get('app_bar.open_interaxo')}
            href={`${ENVIRONMENTAL_VARIABLES.INTERAXO_URL}/${activeBoard.workflow_config.community_id}/${activeBoard.workflow_config.room_id}/documentLibrary/${activeBoard.workflow_config.workflow_id}`}
          />,
        ]}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.shape({
    drawerHeader: PropTypes.string,
    drawerInner: PropTypes.string,
    drawerPaper: PropTypes.string,
    drawerPaperClose: PropTypes.string,
    interaxoIcon: PropTypes.string,
    interaxoIconBig: PropTypes.string,
    sidebarToggler: PropTypes.string,
    openInteraxo: PropTypes.string,
    headerInner: PropTypes.string,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    step_config: PropTypes.shape({
      decision_log_step_id: PropTypes.string,
    }),
    milestones_config: PropTypes.arrayOf(PropTypes.shape({})),
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
      room_id: PropTypes.string,
      workflow_id: PropTypes.string,
    }),
    delivery_config: PropTypes.shape({}),
  }),
};

Sidebar.defaultProps = {
  activeBoard: null, //the very first render before activeBoard fetched
};

export default withStyles(styles)(Sidebar);
