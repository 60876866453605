export const URLSearchParams = (function (windowInstance) {
  // The code provides polyfill for URLSearchParams in IE that doesn't support it by default.
  return (
    windowInstance.URLSearchParams ||
    function (searchString) {
      const self = this;
      self.searchString = searchString;
      //returns value for requested parameter from location search
      self.get = function (name) {
        const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(self.searchString);
        if (results == null) {
          return null;
        } else {
          return decodeURI(results[1]) || 0;
        }
      };
    }
  );
})(window);
