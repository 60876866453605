import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';

import AddCardButton from './AddCardButton';

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class CreateCardMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, board, onCreateQuestion, onCreateMilestone, onCreateDelivery } = this.props;

    const withMilestonesOrDeliveries = board.delivery_config || board.milestones_config.length > 0;
    const cascading = withMilestonesOrDeliveries && board.milestones_config.length > 1;

    return (
      <div>
        <AddCardButton
          onClick={withMilestonesOrDeliveries ? this.handleClick : onCreateQuestion}
          title={intl.get('board.tooltips.add_card')}
        />
        {withMilestonesOrDeliveries && (
          <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={this.handleClose}>
            <MenuItem
              id={'create-question-item'}
              key={'create-question-item'}
              onClick={onCreateQuestion}>
              {intl.get('board.tooltips.question')}
            </MenuItem>
            {board.milestones_config.length > 0 && (
              <div>
                <MenuItem
                  id={'create-milestone-item'}
                  key={'create-milestone-item'}
                  autoFocus={false}
                  style={cascading ? { opacity: 1 } : {}}
                  onClick={
                    cascading
                      ? null
                      : () =>
                          onCreateMilestone({
                            milestoneSet: board.milestones_config[0],
                          })
                  }
                  disabled={
                    cascading ? true : !board.milestones_config[0].permissions.ADD_MILESTONE
                  }>
                  {intl.get('board.tooltips.milestone')}
                </MenuItem>
                {cascading && (
                  <MenuList component="div" disablePadding>
                    {board.milestones_config.map(milestoneSet => (
                      <MenuItem
                        className={classes.nested}
                        id={'create-milestone-set-item-' + milestoneSet.name}
                        key={'create-milestone-set-item-' + milestoneSet.name}
                        disabled={!milestoneSet.permissions.ADD_MILESTONE}
                        onClick={() => onCreateMilestone({ milestoneSet })}>
                        {milestoneSet.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
              </div>
            )}
            {board.delivery_config && (
              <MenuItem
                id={'create-delivery-item'}
                key={'create-delivery-item'}
                onClick={onCreateDelivery}
                disabled={!board.delivery_config.permissions.ADD_DELIVERY}>
                {intl.get('board.tooltips.delivery')}
              </MenuItem>
            )}
          </Menu>
        )}
      </div>
    );
  }
}

CreateCardMenu.propTypes = {
  classes: PropTypes.shape({
    nested: PropTypes.shape({}),
  }).isRequired,
  board: PropTypes.shape({
    delivery_config: PropTypes.shape({
      permissions: PropTypes.shape({
        ADD_DELIVERY: PropTypes.bool,
      }),
    }),
    milestones_config: PropTypes.arrayOf(
      PropTypes.shape({
        permissions: PropTypes.shape({
          ADD_MILESTONE: PropTypes.bool,
        }),
      }),
    ),
  }).isRequired,
  onCreateQuestion: PropTypes.func.isRequired,
  onCreateMilestone: PropTypes.func,
  onCreateDelivery: PropTypes.func,
};

CreateCardMenu.defaultProps = {
  onCreateMilestone: () => {},
  onCreateDelivery: () => {},
};

export default withStyles(styles)(CreateCardMenu);
