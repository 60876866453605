import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import memoize from 'lodash/memoize';
import saveAs from 'file-saver';

import { alertAction } from '../../thunks/Alerts';
import * as boardsActions from '../../thunks/Boards';
import * as milestoneActions from '../../thunks/Milestones';
import Error from '../../common/Error';
import PageWrapper from '../../common/PageWrapper';
import {
  calculateDisabledFieldsForMilestone,
  getCardMetaInfo,
  getRequiredFields,
  getUpdatedFieldsAndValues,
  populateInitialValues,
} from '../../utils/FieldUtil';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import {
  selectMilestone,
  selectAttachments,
  selectComments,
  selectLog,
  selectIsUploadingAttachments,
  selectIsDeletingAttachments,
  selectPossibleResponsible,
  actions as milestonesActions,
} from '../../ducks/Milestones';
import { downloadMilestoneAttachment } from '../../services/Milestones';
import { LIST_MILESTONE_VIEW_MODE, withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import MilestoneAttachments from '../common/Attachments';
import Log from '../common/Log';
import MilestoneComments from '../common/Comments';
import DeleteMilestoneDialog from '../../common/DeleteCardItemDialog';
import DeleteIconButton from '../../common/DeleteIconButton';

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    milestone: selectMilestone({
      milestoneId: ownProp.params.milestoneId,
    }),
    attachments: selectAttachments(),
    log: selectLog(),
    isUploadingAttachments: selectIsUploadingAttachments(),
    isDeletingAttachments: selectIsDeletingAttachments(),
    comments: selectComments(),
    roomMembers: selectRoomMembers(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...milestoneActions,
      ...milestonesActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

class MilestoneUpdateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    openDeleteDialog: false,
    closeOnSuccess: false,
  };

  componentDidMount() {
    const { board, roomMembers } = this.props;
    const { params } = this.props;
    // Direct link, assume nothing is loaded.

    this.handleFetchComments({
      boardId: params.boardId,
      milestoneFolderId: params.milestoneFolderId,
      milestoneId: params.milestoneId,
    });
    this.handleFetchAttachments({
      boardId: params.boardId,
      milestoneId: params.milestoneId,
    });
    this.handleFetchLog({
      boardId: params.boardId,
      cardId: params.milestoneId,
    });
    // Load board members to enable mentions in comments.
    if (roomMembers.length < 1 || board.id.toString() !== params.boardId) {
      this.handleFetchRoomMembers({ boardId: params.boardId });
    }
  }

  handleSubmit = ({ values, item }) => {
    const { board, history } = this.props;
    const { milestoneFolderId } = this.props.params;

    return this.props.actions
      .updateMilestone({
        boardId: board.id,
        milestoneId: item.id,
        milestoneSet: milestoneFolderId,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: this.getMilestoneSet(board).fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('milestone.update.error'),
          success: intl.get('milestone.update.success'),
          onSuccess: () =>
            history.push(
              withViewModeQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/milestoneset/${action.payload.milestoneSet}/milestones/${action.payload.milestone.id}`,
              ),
            ),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeQuery(`/${this.props.board.id}`));
  };

  handleFetchRoomMembers = ({ boardId }) => {
    this.props.actions.fetchRoomMembers({ boardId });
  };

  handleFetchComments = ({ boardId, milestoneId }) => {
    this.props.actions
      .fetchComments({ boardId, milestoneId })
      .then(action => alertAction({ action, error: intl.get('common.comments.fetch.error') }));
  };

  handleFetchLog = ({ boardId, cardId }) => {
    this.props.actions.fetchLog({ boardId, cardId }).then(action =>
      alertAction({
        action,
        error: intl.get('common.log.fetch.error'),
      }),
    );
  };

  handleSubmitComment = ({ content }) => {
    const { board, milestone } = this.props;
    return this.props.actions
      .createComment({ boardId: board.id, milestoneId: milestone.id, content })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.add.error'),
          success: intl.get('common.comments.add.success'),
        });
      });
  };

  handleUpdateComment = ({ comment }) => {
    const { board, milestone } = this.props;

    return this.props.actions
      .updateComment({
        boardId: board.id,
        milestoneId: milestone.id,
        commentId: comment.id,
        comment,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.update.error'),
          success: intl.get('common.comments.update.success'),
        });
      });
  };

  handleDeleteComment = ({ comment }) => {
    const { board, milestone } = this.props;

    return this.props.actions
      .deleteComment({
        boardId: board.id,
        milestoneId: milestone.id,
        commentId: comment.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.delete.error'),
          success: intl.get('common.comments.delete.success'),
        });
      });
  };

  handleFetchAttachments = ({ boardId, milestoneId }) => {
    this.props.actions.fetchAttachments({ boardId, milestoneId }).then(action =>
      alertAction({
        action,
        error: intl.get('common.attachments.fetch.error'),
      }),
    );
  };

  handleUploadAttachment = ({ files }) => {
    const { board, milestone } = this.props;

    if (files.length > 0) {
      this.props.actions
        .uploadAttachment({
          boardId: board.id,
          milestoneId: milestone.id,
          files,
        })
        .then(action => {
          alertAction({
            action,
            success: intl.get('common.attachments.upload.success'),
            error: intl.get('common.attachments.upload.error'),
          });
        });
    }
  };

  handleAddLinks = links => {
    const { board, milestone } = this.props;
    links.forEach(link =>
      this.props.actions
        .addMilestoneLink({
          boardId: board.id,
          milestoneId: milestone.id,
          link,
        })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.link.add.error'),
            success: intl.get('common.link.add.success'),
          });
        }),
    );
  };

  handleDownloadAttachment = ({ attachment }) => {
    const { board, milestone } = this.props;
    const boardId = board.id;
    const milestoneId = milestone.id;
    let attachmentId = attachment.id;
    let version;
    if (attachment.type === 'internal-link') {
      attachmentId = attachment.destination;
      version = attachment.destination_version;
    }
    downloadMilestoneAttachment({
      boardId,
      milestoneId,
      attachmentId,
      version,
    }).then(response => {
      saveAs(response.data, attachment.name);
    });
  };

  handleDeleteAttachment = ({ attachmentId }) => {
    const { board, milestone } = this.props;
    this.props.actions
      .deleteAttachment({
        boardId: board.id,
        milestoneId: milestone.id,
        attachmentId,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.attachments.delete.error'),
          success: intl.get('common.attachments.delete.success'),
        });
      });
  };

  handleClickDelete = () => {
    this.setState({ openDeleteDialog: true });
  };

  handleCloseDelete = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleDelete = () => {
    const { board, milestone } = this.props;
    const { boardId, milestoneFolderId } = this.props.params;

    this.props.actions
      .deleteMilestone({
        boardId: board.id,
        milestone,
        milestoneSet: milestoneFolderId,
      })
      .then(action =>
        alertAction({
          action,
          success: intl.get('board.tooltips.milestone.delete.success.message'),
          error: intl.get('board.tooltips.milestone.delete.error.message'),
          onSuccess: () => {},
        }),
      );
    this.props.history.push(`/${boardId}?view=${LIST_MILESTONE_VIEW_MODE}`);
  };

  getMilestoneSet = memoize(board =>
    board.milestones_config.find(config => config.id === this.props.params.milestoneFolderId),
  );

  deleteIconButton = milestone => (
    <DeleteIconButton
      tooltipText={intl.get('board.tooltips.milestone.delete.question.title')}
      onClick={this.handleClickDelete}
      disabled={!milestone.permissions.UPDATE}
    />
  );

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const {
      activeCommunity,
      activeRoom,
      board,
      milestone,
      log,
      attachments,
      isUploadingAttachments,
      isDeletingAttachments,
      comments,
      roomMembers,
      possibleResponsible,
    } = this.props;
    // Failed to fetch milestone.
    if (!milestone) {
      return <Error text={intl.get('milestone.not_found')} />;
    }

    return (
      <PageWrapper title={intl.get('app_bar.milestone_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              formRef={this.formRef}
              item={milestone}
              initialValues={populateInitialValues({
                item: milestone,
                fields: this.getMilestoneSet(board).fields,
              })}
              disabledFields={calculateDisabledFieldsForMilestone({
                permissions: milestone.permissions,
                fields: this.getMilestoneSet(board).fields,
              })}
              disableDelete={false}
              requiredFields={getRequiredFields({
                item: milestone,
                fields: this.getMilestoneSet(board).fields,
              })}
              type={board.type}
              fieldConfig={this.getMilestoneSet(board)}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              deleteIcon={this.deleteIconButton(milestone)}
              restrictions={{
                'task-done': (fieldConfig, field) => {},
                'task-responsible': (fieldConfig, field) => {},
                'rich-text': (fieldConfig, field) => {},
                'auto-number': (fieldConfig, field) => {},
                'unique-document-id': (fieldConfig, field) => {},
                'sequence-number': (fieldConfig, field) => {},
                member: (fieldConfig, field) => {},
                list: (fieldConfig, field) => {},
                date: (fieldConfig, field) => {
                  if (fieldConfig.met_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => {},
                numeric: (fieldConfig, field) => {},
              }}
              metaInfo={getCardMetaInfo(milestone, roomMembers)}
              possibleResponsible={possibleResponsible}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  default: true,
                  handleClick: () => this.handleSaveClick({ closeForm: true }),
                },
                {
                  title: intl.get('common.form.save'),
                  handleClick: () => this.handleSaveClick({ closeForm: false }),
                },
              ]}
            />
            <DeleteMilestoneDialog
              open={this.state.openDeleteDialog}
              onClose={this.handleCloseDelete}
              onDelete={this.handleDelete}
              titles={{
                deleteDialogTitle: intl.get('board.tooltips.milestone.delete.question.title'),
                deleteDialogMessage: intl.get('board.tooltips.milestone.delete.question.message'),
              }}
            />
            <MilestoneAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={milestone}
              attachments={attachments}
              members={roomMembers}
              isUploading={isUploadingAttachments}
              isDeleting={isDeletingAttachments}
              onUploadAttachment={this.handleUploadAttachment}
              onDeleteAttachment={this.handleDeleteAttachment}
              onDownloadAttachment={this.handleDownloadAttachment}
              onAddLinks={this.handleAddLinks}
            />
            <Log board={board} log={log} members={roomMembers} item={milestone} />
          </Grid>
          <Grid item sm={12} md={4} xs={12}>
            <MilestoneComments
              board={board}
              item={milestone}
              comments={comments}
              onSubmitComment={this.handleSubmitComment}
              onUpdateComment={this.handleUpdateComment}
              onDeleteComment={this.handleDeleteComment}
              members={roomMembers}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

MilestoneUpdateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  board: PropTypes.object,
  milestone: PropTypes.object,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploadingAttachments: PropTypes.bool,
  isDeletingAttachments: PropTypes.bool,
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
    milestoneId: PropTypes.string,
    milestoneFolderId: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    updateMilestone: PropTypes.func,
    fetchRoomMembers: PropTypes.func,
    fetchComments: PropTypes.func,
    createComment: PropTypes.func,
    fetchAttachments: PropTypes.func,
    uploadAttachment: PropTypes.func,
    addMilestoneLink: PropTypes.func,
    deleteAttachment: PropTypes.func,
    deleteMilestone: PropTypes.func,
    fetchLog: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      milestoneId: PropTypes.string,
      milestoneFolderId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  log: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
};

MilestoneUpdateContainer.defaultProps = {
  board: null,
  milestone: null,
  attachments: [],
  isUploadingAttachments: false,
  isDeletingAttachments: false,
  comments: [],
  roomMembers: [],
  match: {},
  history: {},
  possibleResponsible: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilestoneUpdateContainer));
