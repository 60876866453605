import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  button: {
    marginLeft: 10,
  },
});

class Subscriptions extends React.Component {
  render() {
    const { classes, isSubscribing, onSubscribe } = this.props;
    return (
      <Tooltip
        title={intl.get(isSubscribing ? 'subscription.unsubscribe' : 'subscription.subscribe')}>
        <IconButton
          id={'toggle-subscribe'}
          onClick={event => {
            // Stopping other event listeners for when button is
            // rendered in a container with a click listener on it.
            event.stopPropagation();
            onSubscribe();
          }}>
          {isSubscribing ? (
            <VisibilityOff className={classes.icon} />
          ) : (
            <Visibility className={classes.icon} />
          )}
        </IconButton>
      </Tooltip>
    );
  }
}

Subscriptions.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string,
  }),
  isSubscribing: PropTypes.bool.isRequired,
  onSubscribe: PropTypes.func,
};

Subscriptions.defaultProps = {
  classes: {},
  onSubscribe: () => {},
};

export default withStyles(styles)(Subscriptions);
