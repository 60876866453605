import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';

import { alertAction } from '../../thunks/Alerts';
import { flattenArray, getFromAndToFields, shouldToValuesBeUpdated } from '../../utils/FieldUtil';
import { TILE_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import {
  selectBoardCards,
  selectIsLoadingCards,
  selectViewConfig,
  actions as viewActions,
  selectPossibleResponsible,
} from '../../ducks/Cards';
import {
  selectActiveBoard,
  selectBoardConfig,
  selectIsLoadingActiveBoard,
  selectTileScrollPos,
  actions as bActions,
} from '../../ducks/Boards';
import PageWrapper from '../../common/PageWrapper';
import Steps from './Steps';
import TileBoard from './TileBoard';
import Toolbar from './Toolbar';

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { selectShowSidebar, selectZoomLevel } from '../../ducks/App';
import AddCardButton from './AddCardButton';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingActiveBoard: selectIsLoadingActiveBoard(),
    isLoadingCards: selectIsLoadingCards(),
    cards: selectBoardCards(),
    config: selectBoardConfig(),
    viewConfig: selectViewConfig(),
    scrollPos: selectTileScrollPos(),
    showSidebar: selectShowSidebar(),
    zoomLevel: selectZoomLevel(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...boardsActions,
      ...bActions,
      ...cardActions,
      setViewConfig: viewActions.setViewConfig,
    },
    dispatch,
  ),
});

class TileBoardContainer extends React.Component {
  componentDidMount() {
    const { cards, activeBoard } = this.props;
    const { params } = this.props.match;
    if (cards.length > 1 || activeBoard.id.toString() !== params.boardId) {
      this.props.actions.fetchCards({ boardId: params.boardId }).then(action =>
        alertAction({
          action,
          error: intl.get('board.views.tile.fetch_cards.error'),
        }),
      );
    }
  }

  handleCardClick = ({ card }) => {
    const { activeBoard, history } = this.props;
    history.push(`/${activeBoard.id}/cards/${card.id}`);
  };

  handleSetScrollPos = ({ pos }) => this.props.actions.setTileScrollPos({ scrollPos: pos });

  renderCreateCardButton = () => (
    <AddCardButton onClick={this.handleCreateCard} title={intl.get('board.tooltips.add_card')} />
  );

  handleCreateCard = () => {
    this.props.history.push(`${this.props.match.params.boardId}/create-card`);
  };

  handleUpdateCard = ({ card, values }) => {
    const { activeBoard } = this.props;
    const initialStepId = activeBoard.step_config.initial_step_id;

    // Will be undefined if no values updated (dropped on same cell)
    const updatedValues = getFromAndToFields({
      board: activeBoard,
      card,
      values,
    });

    this.props.actions
      .updateCardImmediately({
        boardId: activeBoard.id,
        card,
        stepId: initialStepId,
        data: updatedValues || [],
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('board.views.tile.update_card.error'),
          success: intl.get('board.views.tile.update_card.success'),
        }),
      );
  };

  handleMoveCard = ({ board, card, step, values }) => {
    const toField = card.fields.find(field => field.id === board.field_config.to_member_field);

    const shouldToBeUpdated = shouldToValuesBeUpdated({
      board,
      card,
      stepId: step.id,
      values,
    });

    if (values && !shouldToBeUpdated) {
      values.to = toField.value;
    }

    this.props.actions
      .updateCardImmediately({
        boardId: board.id,
        cardId: card.id,
        stepId: step.id,
        card,
        data: values || [],
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('board.views.tile.move_card.error'),
          success: intl.get('board.views.tile.move_card.success'),
        });
      });
  };

  handleConfigChange = viewConfig => {
    this.props.actions.setViewConfig(viewConfig);
  };

  handleExpandAll = () => {
    const { viewConfig, config } = this.props;

    this.handleConfigChange(
      viewConfig.set(
        'expandedTileRows',
        config.xColumns.map(column => column.id),
      ),
    );
  };

  handleCollapseAll = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('expandedTileRows', []));
  };

  handleExpandAllCells = () => {
    const { viewConfig, config } = this.props;
    let cells = [];
    config.xColumns.map((xCol, xIndex) =>
      config.yColumns.map((yCol, yIndex) => cells.push(`${xIndex}-${yIndex}`)),
    );
    this.handleConfigChange(
      viewConfig
        .set('expandedTileCells', cells)
        .set(
          'expandedTileRows',
          viewConfig.expandedTileRows.length === 0
            ? config.xColumns.map(column => column.id)
            : viewConfig.expandedTileRows,
        ),
    );
  };

  handleCollapseAllCells = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('expandedTileCells', []));
  };

  handleStepSidebarClick = () => {
    const { viewConfig } = this.props;
    this.handleConfigChange(viewConfig.set('stepsSidebarOpen', !viewConfig.stepsSidebarOpen));
  };

  render() {
    const {
      activeBoard,
      cards,
      isLoadingActiveBoard,
      isLoadingCards,
      config,
      viewConfig,
      scrollPos,
      possibleResponsible,
      width,
      showSidebar,
      history,
    } = this.props;

    // Still loading cards or board
    if (isLoadingActiveBoard || isLoadingCards) {
      return <LinearProgress />;
    }
    const containerWidth = isWidthUp('md', width)
      ? window.innerWidth - (viewConfig.stepsSidebarOpen ? 410 : 115) - (showSidebar ? 205 : 0)
      : window.innerWidth;
    return (
      <PageWrapper title={intl.get('app_bar.tile_view')}>
        <Toolbar
          renderCreateCardButton={this.renderCreateCardButton}
          board={activeBoard}
          onExpandAll={this.handleExpandAll}
          onCollapseAll={this.handleCollapseAll}
          expandedRows={viewConfig.expandedTileRows}
          expandedCells={viewConfig.expandedTileCells}
          onExpandAllCells={this.handleExpandAllCells}
          onCollapseAllCells={this.handleCollapseAllCells}
          viewConfig={viewConfig}
          onChange={this.handleConfigChange}
          subscription
          possibleResponsible={possibleResponsible}
          viewName={TILE_BOARD_VIEW_MODE}
        />
        <TileBoard
          width={containerWidth}
          config={config}
          viewConfig={viewConfig}
          scrollPos={scrollPos}
          cards={flattenArray(cards)}
          board={activeBoard}
          onUpdateCard={this.handleUpdateCard}
          onCardClick={this.handleCardClick}
          onChange={this.handleConfigChange}
          onSetScrollPos={this.handleSetScrollPos}
        />
        <Steps
          open={viewConfig.stepsSidebarOpen}
          onSidebarClick={this.handleStepSidebarClick}
          board={activeBoard}
          cards={cards[1]}
          steps={activeBoard.step_config.steps.filter(
            step => step.id !== activeBoard.step_config.initial_step_id,
          )}
          history={history}
          viewConfig={viewConfig}
          onCardClick={this.handleCardClick}
          onMoveCardToStep={this.handleMoveCard}
          onChange={this.handleConfigChange}
        />
      </PageWrapper>
    );
  }
}

TileBoardContainer.propTypes = {
  isLoadingActiveBoard: PropTypes.bool,
  isLoadingCards: PropTypes.bool,
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    field_config: PropTypes.shape({}),
    step_config: PropTypes.shape({
      initial_step_id: PropTypes.string,
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  viewConfig: PropTypes.shape({
    expandedTileCells: PropTypes.arrayOf(PropTypes.string),
    expandedTileRows: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    ),
    stepsSidebarOpen: PropTypes.bool,
    set: PropTypes.func,
  }),
  showSidebar: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
  config: PropTypes.shape({
    xColumns: PropTypes.arrayOf(PropTypes.shape({})),
    yColumns: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  cards: PropTypes.arrayOf(PropTypes.array),
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  params: PropTypes.object,
  width: PropTypes.string,
  actions: PropTypes.shape({
    moveCardImmediately: PropTypes.func,
    setViewConfig: PropTypes.func,
    updateCard: PropTypes.func,
    updateCardImmediately: PropTypes.func,
    fetchCards: PropTypes.func,
    setTileScrollPos: PropTypes.func,
  }).isRequired,
  scrollPos: PropTypes.shape({}),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }).isRequired,
};

TileBoardContainer.defaultProps = {
  isLoadingActiveBoard: true,
  isLoadingCards: true,
  params: undefined,
  cards: undefined,
  config: null,
  viewConfig: null,
  possibleResponsible: undefined,
  width: '',
  scrollPos: {},
  history: {},
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withWidth()(TileBoardContainer)),
);
