import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  caption: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  captionHighReadability: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 20,
  },
  value: {
    display: 'inline-block',
  },
  valueHighReadability: {
    display: 'inline-block',
    fontSize: 20,
  },
});

const Value = ({ highReadability, classes, caption, value }) => {
  return (
    <div>
      <Typography className={highReadability ? classes.captionHighReadability : classes.caption}>
        {caption}
        :&nbsp;
      </Typography>
      <Typography
        component={'span'}
        className={highReadability ? classes.valueHighReadability : classes.value}>
        {value}
      </Typography>
    </div>
  );
};

// TODO: When high readability mode is implemented in the entire application, take viewConfig as a property and get highReadability from there.
Value.propTypes = {
  highReadability: PropTypes.bool,
  caption: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    captionHighReadability: PropTypes.string,
    caption: PropTypes.string,
    valueHighReadability: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

Value.defaultProps = {
  highReadability: false,
};

export default withStyles(styles)(Value);
