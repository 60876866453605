import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

// Types
export const types = {
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
  SHOW_USER_MENU: 'SHOW_USER_MENU',
  ZOOM: 'ZOOM',
};

const initialState = Immutable({
  showSidebar: false,
  showUserMenu: false,
  userMenuLocation: null,
  zoomLevel: 1,
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SIDEBAR:
      return state.merge({
        showSidebar: action.payload.showSidebar,
      });
    case types.SHOW_USER_MENU:
      return state.merge({
        showUserMenu: action.payload.showUserMenu,
        userMenuLocation: action.payload.userMenuLocation,
      });
    case types.ZOOM:
      return state.merge({
        zoomLevel: action.payload.zoomLevel,
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  showSidebar: ({ show }) => ({
    type: types.SHOW_SIDEBAR,
    payload: {
      showSidebar: show,
    },
  }),
  showUserMenu: ({ show, location }) => ({
    type: types.SHOW_USER_MENU,
    payload: {
      showUserMenu: show,
      userMenuLocation: location,
    },
  }),
  zoom: ({ zoomLevel }) => ({
    type: types.ZOOM,
    payload: {
      zoomLevel,
    },
  }),
};

// Selectors
const uiSelector = () => state => state.ui;

export const selectUiState = () => createSelector(uiSelector(), uiState => uiState);

export const selectShowSidebar = () => createSelector(uiSelector(), uiState => uiState.showSidebar);

export const selectShowUserMenu = () =>
  createSelector(uiSelector(), uiState => uiState.showUserMenu);

export const selectUserMenuLocation = () =>
  createSelector(uiSelector(), uiState => uiState.userMenuLocation);

export const selectZoomLevel = () => createSelector(uiSelector(), uiState => uiState.zoomLevel);
