import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { alertAction } from '../../thunks/Alerts';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Value from '../../common/Value';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { selectComments } from '../../ducks/Cards';
import { fetchComments } from '../../thunks/Cards';
import { DateTimeFormatter } from '../../common/DateFormatter';
import { actions } from '../../ducks/Cards';
import ReactHtmlParser from 'react-html-parser';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const styles = () => ({
  caption: {
    display: 'inline-block',
    fontWeight: 'bold',
  },
  value: {
    display: 'inline-block',
  },
});

const mapStateToProps = () =>
  createStructuredSelector({
    comments: selectComments(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchComments, ...actions }, dispatch),
});

class DetailedCardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 100,
    };
  }

  componentDidMount() {
    const { row } = this.props;
    const { params } = this.props.match;
    this.props.actions.cleanCommentForCardId({
      cardId: row.id,
    });
    this.handleFetchComments({
      boardId: params.boardId,
      cardId: row.id,
    });
  }

  componentWillUnmount() {
    const { row } = this.props;
    this.props.actions.cleanCommentForCardId({
      cardId: row.id,
    });
  }

  handleFetchComments = ({ boardId, cardId }) => {
    this.props.actions
      .fetchComments({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.comments.fetch.error'),
        }),
      );
  };

  render() {
    const { columns, row, rowToInfo, classes, comments } = this.props;
    const newComments = comments.filter(comment => row.id === comment.cardId);
    const commentColumns = [];
    const handleChangePage = (event, newPage) => {
      this.setState({ page: newPage });
    };
    const handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
      this.setState({ page: 0 });
    };

    // Add comment column
    commentColumns.push({
      id: 'content',
      label: intl.get('board.views.list.columns.comment'),
      type: 'string',
      minWidth: 170,
      width: 620,
      format: comment => ReactHtmlParser(comment.content),
    });

    commentColumns.push({
      id: 'modified',
      label: intl.get('question.list.comment.posted_by'),
      type: 'string',
      minWidth: 50,
      format: comment => {
        return intl.get('question.list.comment.posted_by.and.on', {
          name: comment.modified_by.name,
          date: DateTimeFormatter({ value: comment.modified }),
        });
      },
    });

    const columnNamesMap = columns.reduce((map, column) => {
      map[column.name] =
        column.name === 'commentCount'
          ? intl.get('board.views.list.columns.comment')
          : column.name === 'childCount'
          ? intl.get('board.views.list.columns.attachment')
          : column.title;
      return map;
    }, {});

    // TODO: When high readability mode is implemented in the views where this component is used, pass viewConfig to Value rather than highReadability. This component should already receive viewConfig as a prop.
    return (
      <Card>
        <CardContent>
          {Object.entries(rowToInfo(row)).map((entry, index) =>
            entry[0].startsWith('phentry:richText') ? (
              <React.Fragment key={`item-${index}`}>
                <Typography className={classes.caption}>
                  {columnNamesMap[entry[0]]}
                  :&nbsp;
                </Typography>
                <Typography
                  className={classes.value}
                  dangerouslySetInnerHTML={{ __html: String(entry[1]) }}
                />
              </React.Fragment>
            ) : (
              <Value
                key={`item-${index}`}
                highReadability={false}
                caption={columnNamesMap[entry[0]]}
                value={String(entry[1])}
              />
            ),
          )}
        </CardContent>
        <Paper
          sx={{ width: '100%', overflow: 'hidden' }}
          style={{ display: newComments.length > 0 ? 'block' : 'none' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {commentColumns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width,
                      }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {newComments
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage + this.state.rowsPerPage,
                  )
                  .map(comment => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {commentColumns.map(column => {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format(comment)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={newComments.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ display: newComments.length > 5 ? 'block' : 'none' }}
          />
        </Paper>
      </Card>
    );
  }
}

DetailedCardRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  rowToInfo: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    caption: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    fetchComments: PropTypes.func,
    cleanCommentForCardId: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
};

DetailedCardRow.defaultProps = {
  columns: [],
  comments: [],
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DetailedCardRow)),
);
