import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import IconCreate from '@material-ui/icons/Add';
import IconFilterOn from '@material-ui/icons/FilterList';
import IconFilterOff from '@material-ui/icons/Close';
import ButtonWithProgress from '../../common/ButtonWithProgress';

class ToolbarComponent extends Component {
  render() {
    const {
      readOnly,
      filtering,
      handleItemCreateClick,
      handleFilteringToggle,
      tooltips,
      title,
      subscription,
      handleExportClick,
      children,
    } = this.props;

    return (
      <Toolbar>
        {!readOnly && (
          <Tooltip title={tooltips.create}>
            <Fab size="small" color="primary" onClick={handleItemCreateClick}>
              <IconCreate />
            </Fab>
          </Tooltip>
        )}
        {title}
        {children}
        <Tooltip title={filtering ? tooltips.remove_filtering : tooltips.add_filtering}>
          <IconButton onClick={handleFilteringToggle}>
            {filtering ? <IconFilterOff /> : <IconFilterOn />}
          </IconButton>
        </Tooltip>
        {subscription}
        <ButtonWithProgress
          buttonText={tooltips.export}
          tooltipText={tooltips.export}
          handleClick={handleExportClick}
        />
      </Toolbar>
    );
  }
}

ToolbarComponent.propTypes = {
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  tooltips: PropTypes.shape({
    create: PropTypes.string.isRequired,
    export: PropTypes.string.isRequired,
    add_filtering: PropTypes.string.isRequired,
    remove_filtering: PropTypes.string.isRequired,
  }),
  filtering: PropTypes.arrayOf(PropTypes.shape({})),
  handleItemCreateClick: PropTypes.func,
  handleExportClick: PropTypes.func,
  handleFilteringToggle: PropTypes.func,
  subscription: PropTypes.node,
  children: PropTypes.node,
};

ToolbarComponent.defaultProps = {
  readOnly: false,
  title: '',
  tooltips: {},
  filtering: [],
  handleItemCreateClick: () => {},
  handleExportClick: () => {},
  handleFilteringToggle: () => {},
  subscription: <React.Fragment />,
  children: <React.Fragment />,
};

export default ToolbarComponent;
