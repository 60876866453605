import React from 'react';
import PropTypes from 'prop-types';

import Milestone from '@material-ui/icons/Stop';

const MilestoneIcon = ({ style, ...rest }) => (
  <Milestone style={{ transform: 'rotate(45deg)', ...style }} {...rest} />
);

MilestoneIcon.propTypes = {
  style: PropTypes.shape({}),
};

MilestoneIcon.defaultProps = {
  style: {},
};

export default MilestoneIcon;
