import { types as questionTypes } from '../../ducks/Cards';
import { types as milestoneTypes } from '../../ducks/Milestones';
import { types as deliveryTypes } from '../../ducks/Deliveries';

export default {
  [questionTypes.FETCH_LOG_SUCCESS]: {
    category: 'cards.logs',
    action: 'fetch',
  },
  [questionTypes.FETCH_LOG_ERROR]: action => ({
    category: 'cards.logs',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.FETCH_LOG_SUCCESS]: {
    category: 'milestones.logs',
    action: 'fetch',
  },
  [milestoneTypes.FETCH_LOG_ERROR]: action => ({
    category: 'milestones.logs',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.FETCH_LOG_SUCCESS]: {
    category: 'deliveries.logs',
    action: 'fetch',
  },
  [deliveryTypes.FETCH_LOG_ERROR]: action => ({
    category: 'deliveries.logs',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
