import * as api from '../services/API';
import { actions as authActions } from '../ducks/Auth';
import onError from './Error';

export function getUserProfile({ communityId }) {
  return async dispatch => {
    dispatch(authActions.fetchUserProfileRequest());

    try {
      const userProfile = await api.fetchCurrentUserProfile({ communityId });

      return dispatch(authActions.fetchUserProfileSuccess({ userProfile }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: authActions.fetchUserProfileError({ error }),
      });
    }
  };
}

export const logout = () => {
  return async dispatch => {
    dispatch(authActions.logoutRequest());

    try {
      return dispatch(authActions.logoutSuccess());
    } catch (error) {
      return onError({ error, dispatch, action: authActions.logoutFailure() });
    }
  };
};
