import Tooltip from '@material-ui/core/Tooltip';
import intl from 'react-intl-universal';
import DatePicker from './DatePicker';
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const FromToDatePicker = props => {
  return [
    <Tooltip key={'tooltip-from'} title={intl.get('board.toolbar.date.from.tooltip')}>
      <DatePicker
        field={{
          id: 'dateFrom',
        }}
        minDate={props.minDateFrom}
        maxDate={props.maxDateFrom}
        value={props.valueFrom}
        handleChange={props.handleChange}
        style={{ marginRight: 4, width: 130 }}
        showTodayButton={isWithinInterval(Date.now(), {
          start: startOfDay(props.minDateFrom),
          end: endOfDay(props.maxDateFrom),
        })}
        autoOk
        clearable={false}
      />
    </Tooltip>,
    <Typography key={'arrow'} variant="subtitle1">
      {'->'}
    </Typography>,
    <Tooltip key={'tooltip-to'} title={intl.get('board.toolbar.date.to.tooltip')}>
      <DatePicker
        field={{
          id: 'dateTo',
        }}
        minDate={props.minDateTo}
        maxDate={props.maxDateTo}
        value={props.valueTo}
        handleChange={props.handleChange}
        style={{ marginLeft: 4, width: 130 }}
        showTodayButton={isWithinInterval(Date.now(), {
          start: startOfDay(props.minDateTo),
          end: endOfDay(props.maxDateTo),
        })}
        autoOk
        clearable={false}
      />
    </Tooltip>,
  ];
};

FromToDatePicker.propTypes = {
  minDateFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  maxDateFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  valueFrom: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  minDateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  maxDateTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  valueTo: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  handleChange: PropTypes.any,
};

export default FromToDatePicker;
