import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@tribiahq/interaxo-react-components';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  page: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
});

class PageWrapper extends React.Component {
  render() {
    const { classes, children, title } = this.props;
    return (
      <React.Fragment>
        <Tab>{title}</Tab>
        <div className={classes.page}>{children}</div>
      </React.Fragment>
    );
  }
}

PageWrapper.propTypes = {
  classes: PropTypes.shape({
    page: PropTypes.string,
  }),
  title: PropTypes.string,
  children: PropTypes.node,
};

PageWrapper.defaultProps = {
  classes: {},
  title: '',
  children: <React.Fragment />,
};

export default withStyles(styles)(PageWrapper);
