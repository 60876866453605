import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
});

const Docs = ({ classes }) => (
  <Paper className={classes.root}>
    <Typography variant="h4">{intl.get('docs')}</Typography>
    <div>{intl.get('docs.boards')}</div>
    <div>{intl.get('docs.cards')}</div>
    <div>{intl.get('docs.steps')}</div>
    <div>{intl.get('docs.attachments')}</div>
    <div>{intl.get('docs.comments')}</div>
    <div>{intl.get('docs.contact_us')}</div>
  </Paper>
);

Docs.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
};

Docs.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Docs);
