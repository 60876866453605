import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import * as boardsActions from '../../thunks/Boards';
import * as milestonesActions from '../../thunks/Milestones';
import Error from '../../common/Error';
import PropTypes from 'prop-types';

import {
  MilestoneCreateContainer as MilestoneCreate,
  MilestoneUpdateContainer as MilestoneUpdate,
} from './';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';
import {
  selectIsLoadingMilestones,
  selectIsRequestedMilestones,
  selectIsLoadingPossibleResponsible,
  selectIsRequestedPossibleResponsible,
  selectPossibleResponsible,
} from '../../ducks/Milestones';
import { hasResponsibleField } from '../../utils/FieldUtil';
import { memoize, isEmpty } from 'lodash';

const mapStateToProps = () =>
  createStructuredSelector({
    board: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingBoard: selectIsLoadingActiveBoard(),
    isLoadingMilestones: selectIsLoadingMilestones(),
    isRequestedMilestones: selectIsRequestedMilestones(),
    possibleResponsible: selectPossibleResponsible(),
    isLoadingPossibleResponsible: selectIsLoadingPossibleResponsible(),
    isRequestedPossibleResponsible: selectIsRequestedPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...milestonesActions,
      ...boardsActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

class MilestoneContainer extends React.Component {
  componentDidMount() {
    const { board, isRequestedMilestones, isLoadingMilestones } = this.props;
    const { boardId } = this.props.match.params;
    // Direct link, assume nothing is loaded.
    if (!board) {
      this.handleFetchActiveBoard({ boardId });
    }

    if (!isRequestedMilestones || !isLoadingMilestones) {
      this.handleFetchMilestones({ boardId });
    }

    if (board) {
      this.handleFetchPossibleResponsible();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { board } = this.props;

    if (!!board && prevProps.board !== board) {
      this.handleFetchPossibleResponsible();
    }
  }

  handleFetchActiveBoard = ({ boardId }) => {
    const { activeCommunity, activeRoom, actions } = this.props;

    actions.fetchActiveBoard({ boardId }).then(action => {
      const workflowConfig = action.payload.activeBoard.workflow_config;

      if (activeCommunity !== workflowConfig.community_id) {
        actions.setActiveCommunity({
          communityId: workflowConfig.community_id,
        });
      }

      if (activeRoom !== workflowConfig.room_id) {
        actions.setActiveRoom({ roomId: workflowConfig.room_id });
      }
    });
  };

  handleFetchMilestones = ({ boardId }) => {
    this.props.actions
      .fetchMilestones({ boardId })
      .then(action => alertAction({ action, error: intl.get('milestone.fetch.error') }));
  };

  handleFetchPossibleResponsible = () => {
    const { board, isLoadingPossibleResponsible, isRequestedPossibleResponsible } = this.props;
    const { milestoneFolderId } = this.props.match.params;
    const milestoneConfig = this.getMilestoneSet(board, milestoneFolderId);

    if (milestoneConfig) {
      const shouldLoadPossibleResponsible =
        hasResponsibleField({ fieldConfig: milestoneConfig }) &&
        isRequestedPossibleResponsible.boardId !== board.id &&
        !isLoadingPossibleResponsible;

      if (shouldLoadPossibleResponsible) {
        this.props.actions
          .fetchPossibleResponsible({ boardId: board.id })
          .then(action => alertAction({ action, error: intl.get('card.responsible.error') }));
      }
    }
  };

  getMilestoneSet = memoize((board, milestoneFolderId) =>
    board.milestones_config.find(config => config.id === milestoneFolderId),
  );

  render() {
    const {
      board,
      isLoadingBoard,
      isLoadingMilestones,
      isRequestedMilestones,
      isLoadingPossibleResponsible,
      isRequestedPossibleResponsible,
    } = this.props;
    const { params } = this.props.match;

    // Still loading active board
    if (isLoadingBoard || isLoadingMilestones || !isRequestedMilestones) {
      return <LinearProgress />;
    }

    // Board fetch failed
    if (!board) {
      return <Error text={intl.get('board.not_found')} />;
    }

    const milestoneConfig = this.getMilestoneSet(board, params.milestoneFolderId);

    // Still loading possible responsible
    if (
      hasResponsibleField({ fieldConfig: milestoneConfig }) &&
      (isLoadingPossibleResponsible || isEmpty(isRequestedPossibleResponsible))
    ) {
      return <LinearProgress />;
    }

    if (params.milestoneId) {
      return <MilestoneUpdate board={board} params={params} />;
    }

    return <MilestoneCreate board={board} params={params} />;
  }
}

MilestoneContainer.propTypes = {
  board: PropTypes.shape({
    id: PropTypes.string,
    delivery_config: PropTypes.shape({}),
  }),
  activeCommunity: PropTypes.string,
  activeRoom: PropTypes.string,
  isLoadingBoard: PropTypes.bool.isRequired,
  isLoadingMilestones: PropTypes.bool.isRequired,
  isRequestedMilestones: PropTypes.bool.isRequired,
  isLoadingPossibleResponsible: PropTypes.bool.isRequired,
  isRequestedPossibleResponsible: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchMilestones: PropTypes.func,
    setActiveCommunity: PropTypes.func,
    setActiveRoom: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      milestoneFolderId: PropTypes.string,
      milestoneId: PropTypes.string,
    }),
  }),
};

MilestoneContainer.defaultProps = {
  board: {},
  activeCommunity: '',
  activeRoom: '',
  match: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneContainer);
