import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Header from '../common/Header';
import { selectUserProfile } from '../ducks/Auth';
import { selectShowUserMenu, selectUserMenuLocation } from '../ducks/App';
import * as authActions from '../thunks/Auth';
import { actions as appActions } from '../ducks/App';

const mapStateToProps = () =>
  createStructuredSelector({
    user: selectUserProfile(),
    showUserMenu: selectShowUserMenu(),
    userMenuLocation: selectUserMenuLocation(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...authActions,
      ...appActions,
    },
    dispatch,
  ),
});

const HeaderContainer = ({
  actions,
  showUserMenu,
  onSidebarClick,
  activeBoard,
  userMenuLocation,
  user,
  sidebarOpen,
  onLogout,
}) => {
  const handleUserMenu = location => {
    const locationId = location.id ? location.id : null;
    actions.showUserMenu({
      show: !showUserMenu,
      location: locationId,
    });
  };
  const handleLogout = () => {
    actions.showUserMenu({ show: false, location: null });
    actions.logout();
    onLogout();
  };
  const handleHideUserMenu = () => {
    actions.showUserMenu({ show: false });
  };

  return (
    <Header
      activeBoard={activeBoard}
      onSidebarClick={onSidebarClick}
      onUserMenuClick={handleUserMenu}
      onHideUserMenu={handleHideUserMenu}
      showUserMenu={showUserMenu}
      userMenuLocation={userMenuLocation}
      user={user}
      sidebarOpen={sidebarOpen}
      onLogout={handleLogout}
    />
  );
};

HeaderContainer.propTypes = {
  onSidebarClick: PropTypes.func,
  onLogout: PropTypes.func,
  showUserMenu: PropTypes.bool,
  userMenuLocation: PropTypes.string,
  sidebarOpen: PropTypes.bool,
  actions: PropTypes.shape({
    showUserMenu: PropTypes.func,
    logout: PropTypes.func,
  }),
  activeBoard: PropTypes.shape({}),
  user: PropTypes.shape({}),
};

HeaderContainer.defaultProps = {
  onSidebarClick: () => {},
  onLogout: () => {},
  showUserMenu: false,
  userMenuLocation: 'menu',
  sidebarOpen: true,
  actions: {},
  activeBoard: {},
  user: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
