import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Value from '../../common/Value';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  lightTooltip: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
  },
  ppcContainer: {
    width: '100%',
    position: 'relative',
    height: 15,
  },
  progressBar: {
    position: 'relative',
    background: '#fd0e0e',
    height: 3,
    borderRadius: 3,
    top: 9,
    width: '75%',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: 3,
    height: 3,
    background: '#309269',
    display: 'inline-block',
  },
  percent: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '25%',
    textAlign: 'center',
  },
  placeHolder: {
    height: 15,
  },
  title: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 8,
  },
  titleHighReadability: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 8,
  },
});

class PPCProgress extends React.Component {
  render() {
    const { viewConfig, ppc, classes, dateFrom, dateTo, title } = this.props;
    if (!ppc.total) return <div className={classes.placeHolder} style={this.props.style} />;
    const progress = ppc.total ? (ppc.green / ppc.total) * 100 : 0;
    return (
      <Tooltip
        title={
          <React.Fragment>
            <Typography
              className={viewConfig.highReadability ? classes.titleHighReadability : classes.title}>
              {title}
            </Typography>
            <Value
              highReadability={viewConfig.highReadability}
              caption={intl.get('board.tooltips.completed')}
              value={`${ppc.green}`}
            />
            <Value
              highReadability={viewConfig.highReadability}
              caption={intl.get('board.tooltips.remaining')}
              value={`${ppc.total - ppc.green}`}
            />
            <Value
              highReadability={viewConfig.highReadability}
              caption={intl.get('board.tooltips.total')}
              value={`${ppc.total}`}
            />
            <Value
              highReadability={viewConfig.highReadability}
              caption={intl.get('board.toolbar.date.from.tooltip')}
              value={`${dateFrom}`}
            />
            <Value
              highReadability={viewConfig.highReadability}
              caption={intl.get('board.toolbar.date.to.tooltip')}
              value={`${dateTo}`}
            />
          </React.Fragment>
        }
        classes={{ tooltip: classes.lightTooltip }}
        style={this.props.style}>
        <div className={classes.ppcContainer}>
          <div className={classes.progressBar}>
            <div
              className={classes.progress}
              // % of progressBar
              style={{
                width: progress + '%',
              }}
            />
          </div>
          <div className={classes.percent}>{progress.toFixed(0)}%</div>
        </div>
      </Tooltip>
    );
  }
}

PPCProgress.propTypes = {
  viewConfig: PropTypes.object.isRequired,
  ppc: PropTypes.shape({
    green: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  classes: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  dateFrom: PropTypes.string.isRequired,
  dateTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(PPCProgress);
