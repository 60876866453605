import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { selectViewConfig } from './Cards';
import { getBoardConfig, verifyName } from '../utils/FieldUtil';

// Types
export const types = {
  //
  // FETCH BOARDS
  //
  FETCH_BOARDS_REQUEST: 'FETCH_BOARDS_REQUEST',
  FETCH_BOARDS_SUCCESS: 'FETCH_BOARDS_SUCCESS',
  FETCH_BOARDS_ERROR: 'FETCH_BOARDS_ERROR',

  //
  // FETCH CURRENT BOARD
  //
  FETCH_ACTIVE_BOARD_REQUEST: 'FETCH_ACTIVE_BOARD_REQUEST',
  FETCH_ACTIVE_BOARD_SUCCESS: 'FETCH_ACTIVE_BOARD_SUCCESS',
  FETCH_ACTIVE_BOARD_ERROR: 'FETCH_ACTIVE_BOARD_ERROR',

  //
  // EXPAND / COLLAPSE WEEK COLUMNS
  //
  EXPAND_COLLAPSE_WEEK_COLUMN: 'EXPAND_COLLAPSE_WEEK_COLUMN',

  //
  // ROOM MEMBERS
  //
  FETCH_ROOM_MEMBERS_REQUEST: 'FETCH_ROOM_MEMBERS_REQUEST',
  FETCH_ROOM_MEMBERS_SUCCESS: 'FETCH_ROOM_MEMBERS_SUCCESS',
  FETCH_ROOM_MEMBERS_ERROR: 'FETCH_ROOM_MEMBERS_ERROR',

  //
  // SCROLL POS
  //
  SET_TILE_SCROLL_POS: 'SET_TILE_SCROLL_POS',
  SET_WEEK_SCROLL_POS: 'SET_WEEK_SCROLL_POS',
};

// If the action's payload holds a field configuration, then for each member field, verify the names of users that can
// be selected. Invited members have not yet set a name; for those users, use the e-mail address instead. For other
// names, just remove leading and trailing whitespace. Perform the same check for the users in the "from" and "to"
// fields.
// todo: move to utils?
const setNameForInvitedUsers = action => {
  const fieldConfig = action.payload.activeBoard.field_config;
  const from = fieldConfig.allowed_from_authorities;
  const to = fieldConfig.allowed_to_authorities;
  const fields = fieldConfig.fields;

  if (from) {
    from.forEach(verifyName);
  }
  if (to) {
    to.forEach(verifyName);
  }
  if (fields) {
    fields.forEach(field => {
      if (field.type === 'member' && field.limited_to) {
        field.limited_to.forEach(verifyName);
      }
    });
  }
};

const initialState = Immutable({
  isLoadingBoards: false,
  isRequested: {},
  isLoadingRoomMembers: false,
  boards: [],
  isLoadingActiveBoard: false,
  activeBoard: null,
  viewConfig: {
    expandedColumns: [],
    tileScrollPos: {
      scrollX: null,
      scrollY: null,
    },
    weekScrollPos: {
      scrollX: null,
      scrollY: null,
    },
  },
  roomMembers: [],
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // FETCH BOARDS
    //
    case types.FETCH_BOARDS_REQUEST:
      return state.merge({
        isLoadingBoards: true,
        isRequested: { ...action.payload.params },
      });
    case types.FETCH_BOARDS_SUCCESS:
      return state.merge({
        isLoadingBoards: false,
        boards: action.payload.boards,
      });
    case types.FETCH_BOARDS_ERROR:
      return state.merge({
        isLoadingBoards: false,
      });

    //
    // FETCH CURRENT BOARD
    //
    case types.FETCH_ACTIVE_BOARD_REQUEST:
      return state.merge({
        isLoadingActiveBoard: true,
      });
    case types.FETCH_ACTIVE_BOARD_SUCCESS:
      setNameForInvitedUsers(action);
      return state.merge({
        isLoadingActiveBoard: false,
        activeBoard: action.payload.activeBoard,
      });
    case types.FETCH_ACTIVE_BOARD_ERROR:
      return state.merge({
        isLoadingActiveBoard: false,
      });

    //
    // EXPAND_COLLAPSE_WEEK_COLUMN
    //
    case types.EXPAND_COLLAPSE_WEEK_COLUMN: {
      const wasExpanded =
        state.viewConfig.expandedColumns.indexOf(action.payload.weekColumnId) > -1;
      const newExpandedColumnsArray = wasExpanded
        ? state.viewConfig.expandedColumns.filter(
            columnNumber => columnNumber !== action.payload.weekColumnId,
          )
        : state.viewConfig.expandedColumns.concat(action.payload.weekColumnId);

      return state.merge({
        viewConfig: {
          expandedColumns: newExpandedColumnsArray,
        },
      });
    }
    //
    // FETCH ROOM MEMBERS
    //
    case types.FETCH_ROOM_MEMBERS_REQUEST:
      return state.merge({ isLoadingRoomMembers: true });
    case types.FETCH_ROOM_MEMBERS_SUCCESS:
      return state.merge({ roomMembers: action.payload.roomMembers });
    case types.FETCH_ROOM_MEMBERS_ERROR:
      return state.merge({ isLoadingRoomMembers: false });
    //
    // SCROLL POS
    //
    case types.SET_TILE_SCROLL_POS:
      return state.merge({
        viewConfig: {
          ...state.viewConfig,
          tileScrollPos: action.payload.scrollPos,
        },
      });
    case types.SET_WEEK_SCROLL_POS:
      return state.merge({
        viewConfig: {
          ...state.viewConfig,
          weekScrollPos: action.payload.scrollPos,
        },
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  //
  // FETCH BOARDS
  //
  fetchBoardsRequest: params => ({
    type: types.FETCH_BOARDS_REQUEST,
    payload: { params },
  }),
  fetchBoardsSuccess: ({ boards }) => ({
    type: types.FETCH_BOARDS_SUCCESS,
    payload: { boards },
  }),
  fetchBoardsError: ({ error }) => ({
    type: types.FETCH_BOARDS_ERROR,
    payload: { error },
  }),
  //
  // FETCH CURRENT BOARD
  //
  fetchActiveBoardRequest: () => ({
    type: types.FETCH_ACTIVE_BOARD_REQUEST,
  }),
  fetchActiveBoardSuccess: ({ activeBoard }) => ({
    type: types.FETCH_ACTIVE_BOARD_SUCCESS,
    payload: { activeBoard },
  }),
  fetchActiveBoardError: ({ error }) => ({
    type: types.FETCH_ACTIVE_BOARD_ERROR,
    payload: { error },
  }),
  //
  // EXPAND / COLLAPSE WEEK VIEW COLUMNS
  //
  expandCollapseWeekViewColumn: ({ weekColumnId }) => ({
    type: types.EXPAND_COLLAPSE_WEEK_COLUMN,
    payload: { weekColumnId },
  }),
  //
  // FETCH ROOM MEMBERS
  //
  fetchRoomMembersRequest: () => ({
    type: types.FETCH_ROOM_MEMBERS_REQUEST,
  }),
  fetchRoomMembersSuccess: ({ roomMembers }) => ({
    type: types.FETCH_ROOM_MEMBERS_SUCCESS,
    payload: { roomMembers },
  }),
  fetchRoomMembersError: ({ error }) => ({
    type: types.FETCH_ROOM_MEMBERS_ERROR,
    payload: { error },
  }),
  //
  // SCROLL POS
  //
  setTileScrollPos: ({ scrollPos }) => ({
    type: types.SET_TILE_SCROLL_POS,
    payload: { scrollPos },
  }),
  setWeekScrollPos: ({ scrollPos }) => ({
    type: types.SET_WEEK_SCROLL_POS,
    payload: { scrollPos },
  }),
};

// Selectors
const boardsSelector = () => state => state.boards;

export const selectIsLoadingBoards = () =>
  createSelector(boardsSelector(), boardsState => boardsState.isLoadingBoards);

export const selectBoards = () =>
  createSelector(boardsSelector(), boardsState => boardsState.boards);

export const selectIsLoadingActiveBoard = () =>
  createSelector(boardsSelector(), boardsState => boardsState.isLoadingActiveBoard);

export const selectActiveBoard = () =>
  createSelector(boardsSelector(), boardsState => boardsState.activeBoard);

export const selectBoardConfig = (type = '') =>
  createSelector(
    selectActiveBoard(),
    selectViewConfig(),
    selectExpandedColumns(),
    (board, viewConfig, expandedColumns) =>
      getBoardConfig(board.field_config, type, viewConfig, expandedColumns),
  );

export const selectExpandedColumns = () =>
  createSelector(boardsSelector(), boardsState => boardsState.viewConfig.expandedColumns);

export const selectRoomMembers = () =>
  createSelector(boardsSelector(), boardsState => boardsState.roomMembers);

export const selectIsRequested = () =>
  createSelector(boardsSelector(), boardsState => boardsState.isRequested);

export const selectTileScrollPos = () =>
  createSelector(boardsSelector(), boardsState => boardsState.viewConfig.tileScrollPos);

export const selectWeekScrollPos = () =>
  createSelector(boardsSelector(), boardsState => boardsState.viewConfig.weekScrollPos);
