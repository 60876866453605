import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Gravatar from 'react-gravatar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';
import Help from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { ENVIRONMENTAL_VARIABLES } from '../utils/Environment';

const style = theme => ({
  flexGrow: {
    flex: '1 1 auto',
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 45,
      width: 'calc(100% - 45px)',
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  iconElementLeft: {
    display: 'flex',
    color: 'black',
    padding: 10,
  },
  sidebarButton: {
    padding: 0,
  },
  helpButton: {
    height: 42,
    width: 42,
    color: 'black',
  },
  menuIcon: {
    color: 'black',
  },
  avatar: {
    flex: 1,
    paddingRight: 10,
  },
  userInitials: {
    flex: 4,
    '& h3': {
      margin: 0,
    },
    '& p': {
      fontStyle: 'italic',
      margin: 0,
    },
    '& span': {
      display: 'inline-block',
      paddingTop: '7px',
    },
    '& a': {
      cursor: 'pointer',
    },
  },
  userMenu: {
    height: 170,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  userInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    flex: 3,
  },
  bottomMenu: {
    borderTop: '1px solid #3eb1c8',
    padding: 10,
    flex: 1,
  },
  appBarShift: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '100%',
      zIndex: 1199,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 250,
      width: 'calc(100% - 250px)',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  titleShift: {
    marginLeft: 16,
  },
});

const handleHelpClick = event => {
  const helpWindow = window.open('', '_blank');

  helpWindow.opener = null;
  helpWindow.location = ENVIRONMENTAL_VARIABLES.HELP_CENTER_URL + intl.get('app_bar.help.language');
};

const Header = ({
  user,
  showUserMenu,
  userMenuLocation,
  onUserMenuClick,
  onSidebarClick,
  onLogout,
  classes,
  sidebarOpen,
  activeBoard,
}) => {
  const handleUserMenu = event => {
    event.preventDefault();
    onUserMenuClick(event.currentTarget);
  };
  return (
    <AppBar className={classNames(classes.appBar, sidebarOpen && classes.appBarShift)}>
      <Toolbar disableGutters>
        {!sidebarOpen && (
          <div className={classes.iconElementLeft}>
            <Tooltip title={intl.get('app_bar.sidebar.open.tooltip')}>
              <IconButton className={classes.sidebarButton} onClick={onSidebarClick}>
                <Menu className={classes.menuIcon} />
              </IconButton>
            </Tooltip>
          </div>
        )}
        <div className={sidebarOpen ? classes.titleShift : null}>
          <Typography variant="h6">{activeBoard && activeBoard.name}</Typography>
          <Typography variant="caption">
            {activeBoard &&
              activeBoard.workflow_config &&
              `${activeBoard.workflow_config.community_id} / ${activeBoard.workflow_config.room_id} / ${activeBoard.workflow_config.workflow_name}`}
          </Typography>
        </div>
        <div className={classes.flexGrow} />
        <Tooltip title={intl.get('app_bar.user_profile.tooltip')}>
          <IconButton id="userMenuButton" onClick={event => handleUserMenu(event, this)}>
            <Avatar>
              <Gravatar email={user.email || ''} default="mm" />
            </Avatar>
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.get('app_bar.help.tooltip')}>
          <IconButton onClick={handleHelpClick}>
            <Help className={classes.helpButton} />
          </IconButton>
        </Tooltip>
      </Toolbar>
      {/* TODO move to @tribiahq/interaxo-react-components as ProfileIcon or ProfileInfo or ProfilePopover */}
      <Popover
        open={showUserMenu}
        anchorEl={document.querySelector(`#${userMenuLocation}`)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        onClose={onUserMenuClick}>
        <div className={classes.userMenu}>
          <div className={classes.userInfo}>
            <div className={classes.avatar}>
              <Gravatar email={user.email || ''} default="mm" />
            </div>
            <div className={classes.userInitials}>
              <h3>{user.name || intl.get('app_bar.unknown')}</h3>
              <p>{user.email || ''}</p>
            </div>
          </div>
          <div className={classes.bottomMenu}>
            <Button variant="contained" color="primary" onClick={onLogout}>
              {intl.get('app_bar.logout').defaultMessage('Logout')}
            </Button>
          </div>
        </div>
      </Popover>
    </AppBar>
  );
};

/**
 *
 * @type {Object}
 */

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    appBar: PropTypes.string,
    appBarShift: PropTypes.string,
    iconElementLeft: PropTypes.string,
    sidebarButton: PropTypes.string,
    menuIcon: PropTypes.string,
    titleShift: PropTypes.string,
    flexGrow: PropTypes.string,
    userInitials: PropTypes.string,
    bottomMenu: PropTypes.string,
    helpButton: PropTypes.string,
    userMenu: PropTypes.string,
    userInfo: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  onSidebarClick: PropTypes.func.isRequired,
  showUserMenu: PropTypes.bool.isRequired,
  userMenuLocation: PropTypes.string,
  onUserMenuClick: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  activeBoard: PropTypes.shape({
    name: PropTypes.string,
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
      room_id: PropTypes.string,
      workflow_name: PropTypes.string,
    }),
  }),
};

Header.defaultProps = {
  userMenuLocation: 'menu',
  activeBoard: {},
};

export default withStyles(style)(Header);
