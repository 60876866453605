import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';

// Types
export const types = {
  //
  // SUBSCRIBE
  //
  SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
  SUBSCRIBE_ERROR: 'SUBSCRIBE_ERROR',

  //
  // FETCH SUBSCRIPTIONS
  //
  FETCH_SUBSCRIPTIONS_REQUEST: 'FETCH_SUBSCRIPTIONS_REQUEST',
  FETCH_SUBSCRIPTIONS_SUCCESS: 'FETCH_SUBSCRIPTIONS_SUCCESS',
  FETCH_SUBSCRIPTIONS_ERROR: 'FETCH_SUBSCRIPTIONS_ERROR',
};

const initialState = Immutable({
  subscribers: [],
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    //
    // FETCH SUBSCRIPTIONS
    //
    case types.FETCH_SUBSCRIPTIONS_REQUEST:
      return state.merge({
        subscribers: Immutable([]),
      });
    case types.FETCH_SUBSCRIPTIONS_SUCCESS:
      return state.merge({
        subscribers: action.payload.subscriptions,
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  //
  // SUBSCRIBING
  //
  subscribeRequest: () => ({
    type: types.SUBSCRIBE_REQUEST,
  }),
  subscribeSuccess: ({ boardId }) => ({
    type: types.SUBSCRIBE_SUCCESS,
    payload: { boardId },
  }),
  subscribeError: ({ error }) => ({
    type: types.SUBSCRIBE_ERROR,
    payload: { error },
  }),
  //
  // FETCH SUBSCRIPTIONS
  //
  fetchSubscriptionsRequest: () => ({
    type: types.FETCH_SUBSCRIPTIONS_REQUEST,
  }),
  fetchSubscriptionsSuccess: ({ subscriptions }) => ({
    type: types.FETCH_SUBSCRIPTIONS_SUCCESS,
    payload: { subscriptions },
  }),
  fetchSubscriptionsError: ({ error }) => ({
    type: types.FETCH_SUBSCRIPTIONS_ERROR,
    payload: { error },
  }),
};

// Selectors
const subscribersSelector = () => state => state.subscribers;

export const selectSubscriptions = () =>
  createSelector(subscribersSelector(), subscribersState => subscribersState.subscribers);
