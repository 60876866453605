import { ENVIRONMENTAL_VARIABLES } from '../utils/Environment';

window.INTERAXO_API_URL = ENVIRONMENTAL_VARIABLES?.INTERAXO_URL;

export const AUTH0_CONFIG = {
  clientId: ENVIRONMENTAL_VARIABLES?.AUTH0_CLIENT_ID,
  domain: ENVIRONMENTAL_VARIABLES?.AUTH0_DOMAIN,
  audience: ENVIRONMENTAL_VARIABLES?.AUTH0_AUDIENCE,
  scope: 'openid profile ix:web-api:all',
};

export const KEYCLOAK_CONFIG = {
  clientId: ENVIRONMENTAL_VARIABLES?.KEYCLOAK_CLIENT_ID,
  url: ENVIRONMENTAL_VARIABLES?.KEYCLOAK_URL,
  realm: ENVIRONMENTAL_VARIABLES?.KEYCLOAK_REALM,
  scope: 'openid profile ix:web-api:all',
};
