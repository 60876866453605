import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ButtonWithProgress({ tooltipText, buttonText, handleClick }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [start, setStart] = React.useState(false);

  React.useEffect(() => {
    if (start) {
      (async () => {
        try {
          setLoading(true);
          await handleClick();
          setLoading(false);
          setStart(false);
        } catch (e) {
          setLoading(false);
          setStart(false);
        }
      })();
    }
  }, [handleClick, start]);

  const handleButtonClick = () => {
    if (!loading) {
      setStart(true);
    }
  };

  return (
    <div className={classes.root}>
      <Tooltip title={tooltipText}>
        <Button variant="contained" color="primary" disabled={loading} onClick={handleButtonClick}>
          {buttonText}
        </Button>
      </Tooltip>
      {loading && <CircularProgress size={24} className={classes.progress} />}
    </div>
  );
}

ButtonWithProgress.propTypes = {
  tooltipText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

ButtonWithProgress.defaultProps = {
  tooltipText: '',
  handleClick: () => {},
};

export default ButtonWithProgress;
