import { types } from '../../ducks/Deliveries';

export default {
  [types.FETCH_DELIVERIES_SUCCESS]: {
    category: 'deliveries',
    action: 'fetch',
  },
  [types.FETCH_DELIVERIES_ERROR]: action => ({
    category: 'deliveries',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.CREATE_DELIVERY_SUCCESS]: {
    category: 'deliveries',
    action: 'create',
  },
  [types.CREATE_DELIVERY_ERROR]: action => ({
    category: 'deliveries',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_DELIVERY_SUCCESS]: {
    category: 'deliveries',
    action: 'update',
  },
  [types.UPDATE_DELIVERY_ERROR]: action => ({
    category: 'deliveries',
    action: 'update.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_DELIVERY_IMMEDIATELY_SUCCESS]: {
    category: 'deliveries',
    action: 'update-immediately',
  },
  [types.UPDATE_DELIVERY_IMMEDIATELY_ERROR]: action => ({
    category: 'deliveries',
    action: 'update-immediately.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.DELETE_DELIVERY_SUCCESS]: {
    category: 'deliveries',
    action: 'delete',
  },
  [types.DELETE_DELIVERY_ERROR]: action => ({
    category: 'deliveries',
    action: 'delete.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
