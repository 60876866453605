import { startOfISOWeek, endOfISOWeek, startOfDay, endOfDay } from 'date-fns';

export const getNoonOfDay = date => {
  let newDate = new Date(date);
  newDate.setHours(12, 0, 0, 0);

  return newDate;
};

export const getBeginAndEndOfWeek = date => {
  const begin = startOfISOWeek(date);
  const end = endOfISOWeek(date);

  return {
    begin,
    end,
  };
};

export const getBeginAndEndOfDay = date => {
  const begin = startOfDay(date);
  const end = endOfDay(date);

  return {
    begin,
    end,
  };
};
