export const MILESTONE = 'MILESTONE';
export const QUESTION = 'QUESTION';
export const DELIVERY = 'DELIVERY';
export const MILESTONE_CELL = 'MILESTONE_CELL'; // could contain only milestones
export const DISCIPLINABLE_CELL = 'DISCIPLINABLE_CELL'; // could contain questions and deliveries
export const ANSWERED_ROW = 'answered.row';

export const HIGH_READABILITY_CELL_WIDTH = 278;
export const DEFAULT_CELL_WIDTH = 185;
export const HIGH_READABILITY_MINIMUM_CELL_WIDTH = 180;
export const MINIMUM_CELL_WIDTH = 117;
