import React from 'react';
import PropTypes from 'prop-types';

import Milestone from '@material-ui/icons/StopOutlined';

const OutlinedMilestoneIcon = ({ style, ...rest }) => (
  <Milestone style={{ transform: 'rotate(45deg)', ...style }} {...rest} />
);

OutlinedMilestoneIcon.propTypes = {
  style: PropTypes.shape({}),
};

OutlinedMilestoneIcon.defaultProps = {
  style: {},
};

export default OutlinedMilestoneIcon;
