import * as api from '../services/API';
import { actions } from '../ducks/Communities';
import onError from './Error';

export const fetchCommunities = () => {
  return async dispatch => {
    dispatch(actions.fetchCommunitiesRequest());
    try {
      const communities = await api.fetchCommunities();
      return dispatch(actions.fetchCommunitiesSuccess({ communities }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchCommunitiesError({ error }),
      });
    }
  };
};
