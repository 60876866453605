import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useAuth0, useKeycloak } from '@tribiahq/interaxo-react-components';

import { client } from './services/API';
import { actions as authActions } from './ducks/Auth';
import createStore from './store/createStore';
import App from './App';
import { ENVIRONMENTAL_VARIABLES } from './utils/Environment';
import { isAuth0, isKeycloak } from './utils/AuthUtil';

const store = createStore();

let sessionExpirationTimer;
let sessionExpirationWarningTimer;

const SESSION_TIMEOUT = parseInt(`${ENVIRONMENTAL_VARIABLES.SESSION_TIMEOUT}`);
const SESSION_EXPIRATION_TIMEOUT = SESSION_TIMEOUT * 60 * 60000;
const SESSION_EXPIRATION_WARNING_TIMEOUT = SESSION_EXPIRATION_TIMEOUT - 15 * 60000;

const delaySessionExpiration = () =>
  setTimeout(() => {
    store.dispatch(authActions.setSessionExpired());
  }, SESSION_EXPIRATION_TIMEOUT);

const delaySessionExpirationWarning = () =>
  setTimeout(() => {
    store.dispatch(authActions.setSessionExpiring(true));
  }, SESSION_EXPIRATION_WARNING_TIMEOUT);

const handleResetSessionTimeoutTrackers = () => {
  clearTimeout(sessionExpirationTimer);
  sessionExpirationTimer = delaySessionExpiration();

  clearTimeout(sessionExpirationWarningTimer);
  sessionExpirationWarningTimer = delaySessionExpirationWarning();
};

const trackSessionExpiration = () => {
  sessionExpirationTimer = delaySessionExpiration();
  sessionExpirationWarningTimer = delaySessionExpirationWarning();

  client.interceptors.response.use(
    response => {
      handleResetSessionTimeoutTrackers();

      return response;
    },
    error => Promise.reject(error),
  );
};

const trackStorageEvents = logout => {
  window.addEventListener('storage', event => {
    if (event.key === 'isAuthenticated' && event.newValue === 'false') {
      localStorage.removeItem('isAuthenticated');
      logout();
    }

    if (event.key === 'refreshedAt' && parseInt(event.newValue) > parseInt(event.oldValue || 0)) {
      clearTimeout(sessionExpirationTimer);
      sessionExpirationTimer = delaySessionExpiration();

      clearTimeout(sessionExpirationWarningTimer);
      sessionExpirationWarningTimer = delaySessionExpirationWarning();
    }
  });
};

const clearTxCookies = auth0Client => {
  if (
    auth0Client &&
    auth0Client.transactionManager &&
    auth0Client.transactionManager.transactions
  ) {
    Object.keys(auth0Client.transactionManager.transactions).forEach(txId =>
      auth0Client.transactionManager.remove(txId),
    );
  }
};

const Authentication = () => {
  const useAuth = isAuth0() ? useAuth0 : useKeycloak;
  const { authClient, isAuthenticated, loginWithRedirect, loading, getTokenSilently, logout } =
    useAuth();

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${window.location.search}`,
          },
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, loading]);

  if (loading || !isAuthenticated) {
    return (
      <div
        style={{
          height: `${window.innerHeight}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CircularProgress />
      </div>
    );
  }

  if (isAuthenticated) {
    localStorage.setItem('isAuthenticated', 'true');
    clearTxCookies(authClient);
    let tokenCache = null;
    client.interceptors.request.use(async config => {
      // Settings ts param with interceptor to have it recalculated
      // for each request. So we avoid caching responses.
      config.params = config.params || {};
      config.params.ts = new Date().getTime();
      try {
        const token = await getTokenSilently();

        if (isAuth0()) {
          if (!tokenCache || (tokenCache && tokenCache.access_token !== token)) {
            tokenCache = authClient.cache.get({
              scope: 'openid profile email ix:web-api:all',
              audience: ENVIRONMENTAL_VARIABLES.AUTH0_AUDIENCE,
            });
            localStorage.setItem('refreshedAt', new Date().getTime().toString());
          }

          if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }

        if (isKeycloak()) {
          const accessToken = await getTokenSilently();
          config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
      } catch (err) {
        await loginWithRedirect({
          appState: {
            targetUrl: `${window.location.pathname}${window.location.search}`,
          },
        });
      }
    });
  }

  trackSessionExpiration();
  trackStorageEvents(logout);

  return (
    <Provider store={store}>
      <Router>
        <App onResetSessionTimeoutTrackers={handleResetSessionTimeoutTrackers} onLogout={logout} />
      </Router>
    </Provider>
  );
};

export default Authentication;
