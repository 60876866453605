import React from 'react';
import classNames from 'classnames';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button as SpinnerButton } from '@tribiahq/interaxo-react-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { canMoveToStep, isInStep } from '../../utils/PermissionUtils';

const styles = theme => ({
  cardStep: {
    marginBottom: 20,
  },
  currentStep: {
    backgroundColor: '#fff9c4',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  lockIcon: {
    fontSize: theme.typography.pxToRem(20),
    paddingRight: theme.spacing(1),
  },
});

class CardStep extends React.Component {
  state = {
    movingToStep: null,
  };

  handleStepChange = step => {
    this.setState({
      movingToStep: step.id,
    });
    this.props
      .onStepChange({ step, card: this.props.card })
      .then(() => {
        this.setState({
          movingToStep: null,
        });
      })
      .catch(() => {
        this.setState({
          movingToStep: null,
        });
      });
  };

  render() {
    const { classes, card, steps } = this.props;
    const { movingToStep } = this.state;

    return (
      <ExpansionPanel disabled={!card.id} defaultExpanded={!!card.id} className={classes.cardStep}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{intl.get('card.form.move_to')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelActions>
          <Grid container>
            {card.step &&
              steps.map(step => (
                <Grid item key={`step-container-${step.id}`}>
                  <Tooltip title={intl.get('card.move.tooltip')}>
                    <SpinnerButton
                      variant="outlined"
                      className={classNames(isInStep({ step, card }) && classes.currentStep)}
                      onClick={() => this.handleStepChange(step)}
                      spinner={movingToStep && movingToStep === step.id}
                      disabled={!canMoveToStep({ step, card }) || movingToStep}>
                      {step.final && <LockIcon className={classes.lockIcon} />}
                      {step.name}
                    </SpinnerButton>
                  </Tooltip>
                </Grid>
              ))}
          </Grid>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

CardStep.propTypes = {
  classes: PropTypes.shape({
    cardStep: PropTypes.string,
    heading: PropTypes.string,
    currentStep: PropTypes.string,
    lockIcon: PropTypes.string,
  }).isRequired,
  card: PropTypes.shape({
    id: PropTypes.string,
    step: PropTypes.shape({}),
  }).isRequired,
  stepConfig: PropTypes.shape({}),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  onStepChange: PropTypes.func,
};

CardStep.defaultProps = {
  stepConfig: {},
  steps: [],
  onStepChange: () => {},
};

export default withStyles(styles)(CardStep);
