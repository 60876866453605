// See: https://github.com/mui-org/material-ui/issues/9427
import React from 'react';
import PropTypes from 'prop-types';

const stopPropagation = event => event.stopPropagation();

const StopClickPropagation = ({ children, ...rest }) => (
  <div {...rest} onClick={stopPropagation}>
    {children}
  </div>
);

StopClickPropagation.propTypes = {
  children: PropTypes.node,
};

StopClickPropagation.defaultProps = {
  children: <React.Fragment />,
};

export default StopClickPropagation;
