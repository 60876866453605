import { client } from './API';

/**
 * @returns list of boards
 */
export const fetchBoards = params => {
  return client.get(`/v1/boards`, { params }).then(response => response.data);
};

/**
 * @returns single board with id and metadata
 */
export const fetchBoard = ({ boardId }) =>
  client.get(`/v1/boards/${boardId}`).then(response => response.data);

export const getRoomMembers = ({ boardId, disciplines }) =>
  client
    .get(`/v1/boards/${boardId}/members`, {
      params: {
        authorityIds: disciplines.toString(),
      },
    })
    .then(response => response.data);

export const getAllRoomMembers = ({ boardId }) =>
  client.get(`/v1/boards/${boardId}/authorities`).then(response => response.data);
