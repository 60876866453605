import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard } from '../../ducks/Boards';
import * as boardsActions from '../../thunks/Boards';
import * as milestoneActions from '../../thunks/Milestones';
import { actions as milestonesActions, selectPossibleResponsible } from '../../ducks/Milestones';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import {
  getNonMandatoryManualSequenceId,
  populateInitialValues,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  UNIQUE_DOC_ID,
} from '../../utils/FieldUtil';
import PageWrapper from '../../common/PageWrapper';
import { withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import MilestoneAttachments from '../common/Attachments';
import MilestoneComments from '../common/Comments';
import memoize from 'lodash/memoize';

const mapStateToProps = () =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...milestoneActions,
      ...milestonesActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

class MilestoneCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    closeOnSuccess: false,
  };

  handleSubmit = ({ values, item }) => {
    const { board, history } = this.props;
    const { params } = this.props.match;

    return this.props.actions
      .createMilestone({
        boardId: board.id,
        containerId: params.milestoneFolderId,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: this.getMilestoneSet(board).fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('milestone.create.error'),
          success: intl.get('milestone.create.success'),
          onSuccess: () =>
            history.push(
              withViewModeQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/milestoneset/${action.payload.milestoneSet}/milestones/${action.payload.milestone.id}`,
              ),
            ),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeQuery(`/${this.props.board.id}`));
  };

  getMilestoneSet = memoize(board =>
    board.milestones_config.find(config => config.id === this.props.match.params.milestoneFolderId),
  );

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const { activeCommunity, activeRoom, board, possibleResponsible } = this.props;

    // Create new dummy card
    const milestone = {
      type: 'entry',
      fields: this.getMilestoneSet(board)
        .fields.filter(f => f.type !== 'auto-number')
        .map(f => {
          let value = null;
          if (f.type === 'list' || f.type === 'member') {
            if (f.multiple) {
              value = [];
            } else {
              value = '';
            }
          } else if (f.type === 'number') {
            value = 0;
          }
          return { id: f.id, name: f.name, type: f.type, value: value };
        }),
      permissions: {},
    };

    return (
      <PageWrapper title={intl.get('app_bar.milestone_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8}>
            <Form
              formRef={this.formRef}
              item={milestone}
              initialValues={populateInitialValues({
                item: milestone,
                fields: this.getMilestoneSet(board).fields,
              })}
              disabledFields={[
                UNIQUE_DOC_ID,
                ...getNonMandatoryManualSequenceId(this.getMilestoneSet(board).fields),
              ]}
              disableDelete={true}
              requiredFields={getRequiredFields({
                item: milestone,
                fields: this.getMilestoneSet(board).fields,
              })}
              type={board.type}
              fieldConfig={this.getMilestoneSet(board)}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              restrictions={{
                'task-done': (fieldConfig, field) => ({}),
                'task-responsible': (fieldConfig, field) => ({}),
                'rich-text': (fieldConfig, field) => ({}),
                'auto-number': (fieldConfig, field) => ({}),
                'unique-document-id': (fieldConfig, field) => ({}),
                'sequence-number': (fieldConfig, field) => ({}),
                member: (fieldConfig, field) => ({}),
                list: (fieldConfig, field) => ({}),
                date: (fieldConfig, field) => {
                  if (fieldConfig.met_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => ({}),
                numeric: (fieldConfig, field) => ({}),
              }}
              possibleResponsible={possibleResponsible}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  handleClick: () => this.handleSaveClick(true),
                },
                {
                  title: intl.get('common.form.save'),
                  default: true,
                  handleClick: () => this.handleSaveClick(false),
                },
              ]}
            />
            <MilestoneAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={milestone}
              attachments={[]}
              isUploading={false}
              isDeleting={false}
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <MilestoneComments board={board} item={milestone} comments={[]} members={[]} />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

MilestoneCreateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  board: PropTypes.object,
  actions: PropTypes.shape({
    createMilestone: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      milestoneFolderId: PropTypes.string,
    }),
  }),
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
};

MilestoneCreateContainer.defaultProps = {
  board: null,
  history: {},
  match: {},
  possibleResponsible: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilestoneCreateContainer));
