// sort users and groups by type (users first) and name (acs)
export const sortAuthorities = authorities => {
  return authorities.sort((a, b) => {
    if (a.type === b.type) {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }
    return a.type === 'group' ? 1 : -1;
  });
};

// sort users and groups by type, name and the following rules:
// 1) Groups before members
// 2) Exact match first
// 3) Match on first letters in name before letters anywhere else in the name
export const authoritySorter = searchTerm => (a, b) => {
  if (a.type === b.type) {
    const term = searchTerm.toLowerCase();
    const firstStartsWithTerm = a.name.toLowerCase().startsWith(term);
    const secondStartsWithTerm = b.name.toLowerCase().startsWith(term);

    if (firstStartsWithTerm !== secondStartsWithTerm) {
      return firstStartsWithTerm ? -1 : 1;
    }

    return a.name.localeCompare(b.name);
  }

  return a.type > b.type ? 1 : -1;
};

export const cardSorter = fieldId => (a, b) => {
  if (fieldId) {
    const aField = a.fields.find(field => field.id === fieldId) || {};
    const bField = b.fields.find(field => field.id === fieldId) || {};

    return alphabeticalSorter(aField.value, bField.value);
  }

  return alphabeticalSorter(a.name, b.name);
};

const alphabeticalSorter = (a, b) => {
  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }

  if (a === b) {
    return 0;
  }

  return a.localeCompare(b);
};
