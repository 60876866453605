import * as api from '../services/API';
import { actions } from '../ducks/Rooms';
import onError from './Error';

export const fetchRooms = ({ communityId }) => {
  return async dispatch => {
    dispatch(actions.fetchRoomsRequest({ communityId }));
    try {
      const rooms = await api.fetchRooms({ communityId });
      return dispatch(actions.fetchRoomsSuccess({ communityId, rooms }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchRoomsError({ error }),
      });
    }
  };
};
