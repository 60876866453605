import { map } from 'lodash';

import * as api from '../services/API';
import { actions } from '../ducks/Milestones';
import onError from './Error';
import Immutable from 'seamless-immutable';
import { sortAuthorities } from '../utils/SortUtil';

export const fetchMilestones = ({ boardId }) => {
  return async dispatch => {
    dispatch(actions.fetchMilestonesRequest());

    try {
      const milestones = await api.fetchMilestones({ boardId });
      return dispatch(actions.fetchMilestonesSuccess({ milestones }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchMilestonesError({ error }),
      });
    }
  };
};

export const createMilestone = ({ boardId, containerId, data }) => {
  return async dispatch => {
    dispatch(actions.createMilestoneRequest());

    try {
      const milestone = await api.createMilestone({
        boardId,
        containerId,
        data,
      });
      return dispatch(
        actions.createMilestoneSuccess({
          milestone,
          milestoneSet: containerId,
        }),
      );
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createMilestoneError({ error }),
      });
    }
  };
};

export const updateMilestone = ({ boardId, milestoneId, milestoneSet, data }) => {
  return async dispatch => {
    dispatch(actions.updateMilestoneRequest());

    try {
      const milestone = await api.updateMilestone({
        boardId,
        milestoneId,
        data,
      });
      return dispatch(
        actions.updateMilestoneSuccess({
          milestone,
          milestoneSet,
        }),
      );
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateMilestoneError({ error }),
      });
    }
  };
};

export const updateMilestoneImmediately = ({ boardId, milestone, milestoneSet, data }) => {
  return async dispatch => {
    const updatedMilestone = milestone.merge({
      fields: milestone.fields.map(field => {
        const fields = data.filter(updatedField => updatedField.id === field.id);
        return fields.length ? Immutable(fields[0]) : field;
      }),
      fakeModel: true,
    });

    dispatch(
      actions.updateMilestoneImmediatelySuccess({
        milestone: updatedMilestone,
        milestoneSet,
      }),
    );

    try {
      const cardFromBackend = await api.updateMilestone({
        boardId,
        milestoneId: milestone.milestoneId,
        data: {
          type: 'entry',
          fields: data,
        },
      });

      return dispatch(
        actions.updateMilestoneSuccess({
          milestone: cardFromBackend,
          milestoneSet,
        }),
      );
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateMilestoneImmediatelyError({
          error,
          milestone,
          milestoneSet,
        }),
      });
    }
  };
};

export const deleteMilestone = ({ boardId, milestone, milestoneSet }) => {
  return async dispatch => {
    dispatch(actions.deleteMilestoneRequest());

    try {
      await api.deleteMilestone({
        boardId,
        milestoneId: milestone.id,
      });
      return dispatch(actions.deleteMilestoneSuccess({ boardId, milestone, milestoneSet }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteMilestoneError({ error }),
      });
    }
  };
};

export const fetchAttachments = ({ boardId, milestoneId }) => {
  return async dispatch => {
    dispatch(actions.fetchAttachmentsRequest());
    try {
      const attachments = await api.fetchMilestoneAttachments({
        boardId,
        milestoneId,
      });
      return dispatch(actions.fetchAttachmentsSuccess({ attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchAttachmentsError({ error }),
      });
    }
  };
};

export const uploadAttachment = ({ boardId, milestoneId, files }) => {
  return async dispatch => {
    dispatch(actions.uploadAttachmentRequest());
    try {
      const uploads = [];
      const attachUpload = file =>
        uploads.push(
          api.uploadMilestoneAttachment({
            boardId,
            milestoneId,
            file,
          }),
        );
      map(files, attachUpload);
      const attachments = await Promise.all(uploads);
      return dispatch(actions.uploadAttachmentSuccess({ milestoneId, attachments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.uploadAttachmentError({ error }),
      });
    }
  };
};

export const addMilestoneLink = ({ boardId, milestoneId, link }) => {
  return async dispatch => {
    dispatch(actions.addLinkRequest());
    try {
      const createdLink = await api.addMilestoneLink({
        boardId,
        milestoneId,
        link,
      });
      return dispatch(actions.addLinkSuccess({ milestoneId, link: createdLink }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.addLinkError({ error }),
      });
    }
  };
};

export const deleteAttachment = ({ boardId, milestoneId, attachmentId }) => {
  return async dispatch => {
    dispatch(actions.deleteAttachmentsRequest());
    try {
      await api.deleteMilestoneAttachment({
        boardId,
        milestoneId,
        attachmentId,
      });
      return dispatch(actions.deleteAttachmentsSuccess({ milestoneId, attachmentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteAttachmentsError({ error }),
      });
    }
  };
};

export const createComment = ({ boardId, milestoneId, content }) => {
  return async dispatch => {
    dispatch(actions.createCommentRequest());
    try {
      const comment = await api.createMilestoneComment({
        boardId,
        milestoneId,
        content,
      });
      comment.cardId = milestoneId;
      return dispatch(actions.createCommentSuccess({ comment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.createCommentError({ error }),
      });
    }
  };
};

export const updateComment = ({ boardId, milestoneId, comment }) => {
  return async dispatch => {
    dispatch(actions.updateCommentRequest());
    try {
      const updatedComment = await api.updateMilestoneComment({
        boardId,
        milestoneId,
        comment,
      });
      updatedComment.cardId = milestoneId;
      return dispatch(actions.updateCommentSuccess({ comment: updatedComment }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.updateCommentError({ error }),
      });
    }
  };
};

export const deleteComment = ({ boardId, milestoneId, commentId }) => {
  return async dispatch => {
    dispatch(actions.deleteCommentRequest());
    try {
      await api.deleteMilestoneComment({ boardId, milestoneId, commentId });
      return dispatch(actions.deleteCommentSuccess({ commentId: commentId }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.deleteCommentError({ error }),
      });
    }
  };
};

export const fetchComments = ({ boardId, milestoneId }) => {
  return async dispatch => {
    dispatch(actions.fetchCommentsRequest());
    try {
      const comments = await api.fetchMilestoneComments({
        boardId,
        milestoneId,
      });
      comments.forEach(function (comment, index) {
        comment.cardId = milestoneId;
      });
      return dispatch(actions.fetchCommentsSuccess({ comments }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchCommentsError({ error }),
      });
    }
  };
};

export const fetchLog = ({ boardId, cardId }) => {
  return async dispatch => {
    dispatch(actions.fetchLogRequest());
    try {
      const log = await api.fetchCardLog({ boardId, cardId });
      return dispatch(actions.fetchLogSuccess({ log }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchLogError({ error }),
      });
    }
  };
};

export const fetchPossibleResponsible = ({ boardId }) => {
  return async dispatch => {
    dispatch(actions.fetchPossibleResponsibleRequest({ boardId }));
    try {
      // todo: implement a new API to get users and groups who have access to the folder,
      //  but decided to use all room members (users and groups) as a quick fix
      const allRoomMembers = await api.getAllRoomMembers({ boardId });
      const possibleResponsible = sortAuthorities(allRoomMembers);

      return dispatch(actions.fetchPossibleResponsibleSuccess({ possibleResponsible }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchPossibleResponsibleError({ error }),
      });
    }
  };
};
