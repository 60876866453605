import { QUESTION, MILESTONE, DELIVERY } from './Constants';

export const filterOnNoValue = '<No value>';

export const getValidFilterFields = ({ board, fields, type }) => {
  return fields.filter(
    field =>
      field.type === 'list' ||
      (type === MILESTONE && field.type === 'member') ||
      (type === DELIVERY && field.type === 'member') ||
      (type === QUESTION &&
        field.type === 'member' &&
        field.id !== board.field_config.from_member_field &&
        field.id !== board.field_config.to_member_field),
  );
};

export const getFieldFilterId = field => `${field.name}:${field.type}`;

// Return the field with the given fieldId from the given card. card may be a question, milestone or delivery.
const getField = (fieldId, card) => card.fields.find(field => getFieldFilterId(field) === fieldId);

// Return true if any of the values in the given array of field values matches the given filter value.
const matchesFilter = (fieldValues, filterValue) => {
  if (filterValue === filterOnNoValue) {
    return fieldValues.length <= 0;
  }
  return fieldValues.indexOf(filterValue) >= 0;
};

// Return true if the card should be filtered out, and not appear on the board. Return false if it should be displayed.
// The card should be displayed if the field being filtered holds at least one value which matches one of the given
// filter values. Note that the field can have multiple values, and so can the filter. If there are no filter values -
// that is, there is no filtering going on - the card should always be displayed.
const filterOnFieldValues = (fieldValues, filterValues) => {
  if (filterValues.length === 0) {
    return false;
  }
  return !filterValues.some(filterValue => matchesFilter(fieldValues, filterValue));
};

// Determine whether to display the given card, based on the given list of filter values. The values of the card's field
// with the given fieldId - which is assumed to be a list field - must match the list of permitted filter values.
// Return true if the card should be filtered out, and not appear on the board. Return false if it should be displayed.
const filterOnListField = ({ fieldId, filterValues, card }) => {
  const field = getField(fieldId, card);
  if (!field) {
    return false;
  }
  return filterOnFieldValues(field.value, filterValues);
};

// Determine whether to display the given card, based on the given list of filter values. The values of the card's field
// with the given fieldId - which is assumed to be a member list field - must match the list of permitted filter values.
// The filter is assumed to hold member IDs, whereas the field values are the members themselves. Return true if the
// card should be filtered out, and not appear on the board. Return false if it should be displayed.
const filterOnMemberField = ({ fieldId, filterValues, card }) => {
  const field = getField(fieldId, card);
  if (!field) {
    return false;
  }
  return filterOnFieldValues(
    field.value.map(member => member.id),
    filterValues,
  );
};

// get last part separated with :
const getFilterFieldType = fieldFilterId => fieldFilterId.split(':').reverse()[0];

export const getFilters = ({ viewConfig, type }) => {
  let filters = [];
  for (const key in viewConfig.field_filters[type]) {
    const fieldType = getFilterFieldType(key);

    if ('list' === fieldType) {
      filters.push({ fieldId: key, filterFn: filterOnListField });
    }
    if ('member' === fieldType || 'task-responsible' === fieldType) {
      filters.push({ fieldId: key, filterFn: filterOnMemberField });
    }
  }
  return filters;
};

export const isAnyFilterAvailable = fieldFilters => {
  return (
    fieldFilters &&
    Object.keys(fieldFilters)
      .map(key => fieldFilters[key])
      .some(value => value.length > 0)
  );
};

export const isShowingOnlyDefaultStep = (board, stepIdsToShowOnBoard) => {
  return (
    stepIdsToShowOnBoard.length === 1 &&
    stepIdsToShowOnBoard[0] === board.step_config.initial_step_id
  );
};
