import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import LinearProgress from '@material-ui/core/LinearProgress';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard, selectIsLoadingActiveBoard } from '../../ducks/Boards';
import {
  selectIsLoadingDeliveries,
  selectIsRequestedDeliveries,
  selectIsLoadingPossibleResponsible,
  selectIsRequestedPossibleResponsible,
  selectPossibleResponsible,
} from '../../ducks/Deliveries';
import * as boardsActions from '../../thunks/Boards';
import * as deliveriesActions from '../../thunks/Deliveries';
import Error from '../../common/Error';
import PropTypes from 'prop-types';

import {
  DeliveryCreateContainer as DeliveryCreate,
  DeliveryUpdateContainer as DeliveryUpdate,
} from './';
import { actions as roomActions, selectActiveRoom } from '../../ducks/Rooms';
import { actions as communityActions, selectActiveCommunity } from '../../ducks/Communities';
import { hasResponsibleField } from '../../utils/FieldUtil';
import { isEmpty } from 'lodash';

const mapStateToProps = () =>
  createStructuredSelector({
    board: selectActiveBoard(),
    activeRoom: selectActiveRoom(),
    activeCommunity: selectActiveCommunity(),
    isLoadingBoard: selectIsLoadingActiveBoard(),
    isLoadingDeliveries: selectIsLoadingDeliveries(),
    isRequestedDeliveries: selectIsRequestedDeliveries(),
    possibleResponsible: selectPossibleResponsible(),
    isLoadingPossibleResponsible: selectIsLoadingPossibleResponsible(),
    isRequestedPossibleResponsible: selectIsRequestedPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...deliveriesActions,
      ...boardsActions,
      setActiveRoom: roomActions.setActiveRoom,
      setActiveCommunity: communityActions.setActiveCommunity,
    },
    dispatch,
  ),
});

class DeliveryContainer extends React.Component {
  componentDidMount() {
    const { board, isLoadingDeliveries, isRequestedDeliveries } = this.props;
    const { boardId } = this.props.match.params;

    if (!board) {
      this.handleFetchActiveBoard();
    }

    if (!isRequestedDeliveries || !isLoadingDeliveries) {
      this.handleFetchDeliveries({ boardId });
    }

    if (board) {
      this.handleFetchPossibleResponsible();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { board } = this.props;

    if (!!board && prevProps.board !== this.props.board) {
      this.handleFetchPossibleResponsible();
    }
  }

  handleFetchPossibleResponsible = () => {
    const { board, isRequestedPossibleResponsible, isLoadingPossibleResponsible } = this.props;

    const shouldLoadPossibleResponsible =
      hasResponsibleField({ fieldConfig: board.delivery_config }) &&
      isRequestedPossibleResponsible.boardId !== board.id &&
      !isLoadingPossibleResponsible;

    if (shouldLoadPossibleResponsible) {
      this.props.actions
        .fetchPossibleResponsible({ boardId: board.id })
        .then(action => alertAction({ action, error: intl.get('card.responsible.error') }));
    }
  };

  handleFetchActiveBoard = () => {
    const { activeCommunity, activeRoom, actions } = this.props;
    const { boardId } = this.props.match.params;

    actions.fetchActiveBoard({ boardId: boardId }).then(action => {
      const workflowConfig = action.payload.activeBoard.workflow_config;

      if (activeCommunity !== workflowConfig.community_id) {
        actions.setActiveCommunity({
          communityId: workflowConfig.community_id,
        });
      }

      if (activeRoom !== workflowConfig.room_id) {
        actions.setActiveRoom({ roomId: workflowConfig.room_id });
      }

      alertAction({ action, error: intl.get('card.fetch_board.error') });
    });
  };

  handleFetchDeliveries = ({ boardId }) => {
    this.props.actions
      .fetchDeliveries({ boardId })
      .then(action => alertAction({ action, error: intl.get('delivery.fetch.error') }));
  };

  render() {
    const {
      board,
      isLoadingBoard,
      isLoadingDeliveries,
      isRequestedDeliveries,
      isLoadingPossibleResponsible,
      isRequestedPossibleResponsible,
    } = this.props;
    const { params } = this.props.match;

    // Still loading active board
    if (isLoadingBoard || isLoadingDeliveries || !isRequestedDeliveries) {
      return <LinearProgress />;
    }

    // Board fetch failed
    if (!board) {
      return <Error text={intl.get('board.not_found')} />;
    }

    // Still loading possible responsible
    if (
      hasResponsibleField({ fieldConfig: board.delivery_config }) &&
      (isLoadingPossibleResponsible || isEmpty(isRequestedPossibleResponsible))
    ) {
      return <LinearProgress />;
    }

    if (params.deliveryId) {
      return <DeliveryUpdate board={board} params={params} />;
    }

    return <DeliveryCreate board={board} params={params} />;
  }
}

DeliveryContainer.propTypes = {
  board: PropTypes.shape({
    id: PropTypes.string,
    delivery_config: PropTypes.shape({}),
  }),
  activeCommunity: PropTypes.string,
  activeRoom: PropTypes.string,
  isLoadingBoard: PropTypes.bool.isRequired,
  isLoadingDeliveries: PropTypes.bool.isRequired,
  isRequestedDeliveries: PropTypes.bool.isRequired,
  isLoadingPossibleResponsible: PropTypes.bool.isRequired,
  isRequestedPossibleResponsible: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchDeliveries: PropTypes.func,
    setActiveCommunity: PropTypes.func,
    setActiveRoom: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      deliveryId: PropTypes.string,
    }),
  }),
};

DeliveryContainer.defaultProps = {
  board: {},
  activeCommunity: {},
  activeRoom: {},
  match: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContainer);
