import React from 'react';
import { Formik } from 'formik';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Gravatar from 'react-gravatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Editor } from '@tribiahq/interaxo-react-components';
import RootRef from '@material-ui/core/RootRef';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { DateTimeFormatter } from '../../common/DateFormatter';
import DeleteDeliveryDialogue from '../../common/DeleteCardItemDialog';
import IconButton from '@material-ui/core/IconButton';
import { Delete, EditOutlined } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import Immutable from 'seamless-immutable';
import { authoritySorter } from '../../utils/SortUtil';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
  badge: {
    top: '-4px',
    right: '-30px',
    transform: 'scale(1)',
  },
  commentLabel: {
    position: 'absolute',
    left: 40,
    top: 145,
    zIndex: 1000,
  },
  paper: {
    boxShadow: 0,
    margin: `15px auto`,
    padding: 10,
  },
  comment: {
    '&:first-child': {
      paddingLeft: 15,
    },
  },
  iconPadding: {
    padding: 8,
  },
});

class Comments extends React.Component {
  editor = null;

  state = {
    editorExpanded: false,
    commentToEdit: null,
    openDeleteDialogue: false,
    commentToDelete: null,
  };

  constructor(props) {
    super(props);
    this.commentCaption = React.createRef();
  }

  handleSubmit = (values, actions) => {
    this.closeEditor();
    actions.setSubmitting(true);

    if (this.state.commentToEdit) {
      return this.props
        .onUpdateComment({
          comment: this.state.commentToEdit.merge({
            content: values['content'],
          }),
        })
        .then(() => {
          this.setState({ commentToEdit: null });
          actions.resetForm({});
          actions.setSubmitting(false);
        });
    }

    this.props.onSubmitComment({ content: values['content'] }).then(() => {
      actions.resetForm({});
      actions.setSubmitting(false);
    });
  };

  handleEditComment = ({ comment }) => {
    this.setState({ commentToEdit: comment });
  };

  handleDeleteComment = ({ comment }) => {
    this.setState({ openDeleteDialogue: true, commentToDelete: comment });
  };

  handleCloseDelete = () => {
    this.setState({ openDeleteDialogue: false, commentToDelete: null });
  };

  handleDeleteSubmit = () => {
    if (this.state.commentToDelete) {
      this.props.onDeleteComment({ comment: this.state.commentToDelete });
    }
    this.handleCloseDelete();
  };

  handleCancelEditComment = () => {
    this.setState({ commentToEdit: null });
  };

  validate = values => {
    let errors = {};

    if (!values.content || values.content === ' ') {
      errors['content'] = intl.get('common.form.validators.required');
    }
    return errors;
  };

  // Increase the size of the editor when it receives focus, and display the ADD and CANCEL buttons. Writing through a
  // keyhole is no fun. Note that this has to be done dynamically, by adjusting the size of its DOM element: we cannot
  // re-render the editor with a greater height, the way we would usually do in React, as it would then promptly lose
  // focus. Note also that we have to use the pointer to the editor provided by the editor itself; if we had a ref to
  // the editor, its setHeight method would (for whatever reason) not be available.
  handleFocus = () => {
    this.setState({ editorExpanded: true });
  };

  // Shrink the editor after editing is complete, and remove the ADD and CANCEL buttons.
  closeEditor = () => {
    this.setState({ editorExpanded: false });
  };

  fetchMentions = (searchTerm, callback) => {
    const { members, board } = this.props;
    const mentions = Immutable.asMutable(members)
      .filter(member => member.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
      .sort(authoritySorter(searchTerm))
      .map(member => ({
        id:
          member.type === 'group'
            ? member.id
            : `${member.id.replace('@', '~')}@${board.workflow_config.community_id}`,
        name: member.name,
        rawname: member.name,
      }));
    callback(mentions, []);
  };

  render() {
    const { classes, item, comments } = this.props;
    const newComments = comments.filter(comment => item.id === comment.cardId);

    // TODO: In the Formik render method, we should be able to use the handleFocus and handleBlur parameters. However, handleFocus is undefined, and handleBlur does not work.
    return (
      <ExpansionPanel
        disabled={!item.id}
        defaultExpanded={!!item.id}
        onChange={(event, expanded) => {
          if (this.commentCaption && this.commentCaption.current) {
            this.commentCaption.current.style.display = expanded ? '' : 'none';
          }
        }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <React.Fragment>
            <Badge
              classes={{ badge: classes.badge }}
              color="primary"
              badgeContent={newComments.length}>
              <Typography className={classes.heading}>{intl.get('common.comments')}</Typography>
            </Badge>
          </React.Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.form}>
          <Formik
            onSubmit={this.handleSubmit}
            validate={this.validate}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            enableReinitialize
            initialValues={{
              content: this.state.commentToEdit ? this.state.commentToEdit.content : ' ',
            }}
            render={({ values, handleSubmit, isSubmitting, isValid, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                {!this.state.editorExpanded && !isSubmitting && item.permissions.ADD_COMMENT && (
                  <RootRef rootRef={this.commentCaption}>
                    <Typography variant="caption" className={classes.commentLabel}>
                      {intl.get('common.comments.label')}
                    </Typography>
                  </RootRef>
                )}
                <Editor
                  id="commentField"
                  value={values.content || ' '}
                  onChange={(event, editor) => setFieldValue('content', editor.getContent())}
                  onFocus={this.handleFocus.bind(this)}
                  enableMentions
                  mentionsFetch={this.fetchMentions}
                  disabled={!item.permissions.ADD_COMMENT}
                  height={this.state.editorExpanded ? 250 : 100}
                  style={{ marginBottom: 20 }}
                />
                {this.state.editorExpanded && (
                  <div>
                    {this.state.commentToEdit ? (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}>
                        {intl.get('common.comments.update')}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || !isValid || !item.permissions.ADD_COMMENT}>
                        {intl.get('common.comments.add')}
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginLeft: 16 }}
                      disabled={isSubmitting || !item.permissions.ADD_COMMENT}
                      onClick={() => {
                        setFieldValue('content', ' ');
                        this.handleCancelEditComment();
                        this.closeEditor();
                      }}>
                      {intl.get('common.dialog.cancel')}
                    </Button>
                    {isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                )}
              </form>
            )}
          />
          <Grid>
            {newComments.map((comment, index) => (
              <Paper key={`comment-${index}`} className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Avatar>
                      <Gravatar email={comment.author || ''} default="mm" />
                    </Avatar>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Tooltip title={intl.get('common.comments.update')}>
                      <IconButton
                        className={classes.iconPadding}
                        disabled={!comment.permissions.UPDATE}
                        onClick={() => {
                          this.handleFocus();
                          this.handleEditComment({ comment });
                        }}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={intl.get('common.comments.delete')}>
                      <IconButton
                        className={classes.iconPadding}
                        disabled={!comment.permissions.DELETE}
                        onClick={() => this.handleDeleteComment({ comment })}>
                        <Delete />
                      </IconButton>
                    </Tooltip>

                    <DeleteDeliveryDialogue
                      open={this.state.openDeleteDialogue}
                      onClose={this.handleCloseDelete}
                      onDelete={this.handleDeleteSubmit}
                      titles={{
                        deleteDialogTitle: intl.get('common.comments.delete.tooltip.title'),
                        deleteDialogMessage: intl.get('common.comments.delete.tooltip.message'),
                      }}
                    />

                    <ListItemText
                      classes={{
                        root: classes.comment,
                      }}
                      primary={<div dangerouslySetInnerHTML={{ __html: comment.content }} />}
                      secondary={intl.get('common.comments.posted_by', {
                        name: comment.created_by.name,
                        date: DateTimeFormatter({ value: comment.modified }),
                      })}
                    />
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

Comments.propTypes = {
  board: PropTypes.shape({
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
    }),
  }),
  classes: PropTypes.shape({
    badge: PropTypes.string,
    buttonProgress: PropTypes.string,
    comment: PropTypes.string,
    commentLabel: PropTypes.string,
    heading: PropTypes.string,
    form: PropTypes.string,
    paper: PropTypes.string,
    wrapper: PropTypes.string,
    iconPadding: PropTypes.string,
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      ADD_COMMENT: PropTypes.bool,
    }),
  }).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmitComment: PropTypes.func,
  onCommentEdit: PropTypes.func,
  onCancelEdit: PropTypes.func,
  onUpdateComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
};

Comments.defaultProps = {
  board: null,
  comments: [],
  members: [],
  onSubmitComment: () => {},
  onUpdateComment: () => {},
  onDeleteComment: () => {},
};

export default withStyles(styles)(Comments);
