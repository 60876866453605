import * as api from '../services/API';
import { actions } from '../ducks/Boards';
import onError from './Error';

export const fetchBoards = ({ communityId, roomId }) => {
  return async dispatch => {
    dispatch(actions.fetchBoardsRequest({ communityId, roomId }));

    try {
      const boards = await api.fetchBoards({ communityId, roomId });

      return dispatch(actions.fetchBoardsSuccess({ boards }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchBoardsError({ error }),
      });
    }
  };
};

export const fetchActiveBoard = ({ boardId }) => {
  return async dispatch => {
    dispatch(actions.fetchActiveBoardRequest());

    try {
      const activeBoard = await api.fetchBoard({ boardId });

      return dispatch(actions.fetchActiveBoardSuccess({ activeBoard }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchActiveBoardError({ error }),
      });
    }
  };
};

export const fetchRoomMembers = ({ boardId }) => {
  return async dispatch => {
    dispatch(actions.fetchRoomMembersRequest());

    try {
      const roomMembers = await api.getAllRoomMembers({ boardId });
      return dispatch(actions.fetchRoomMembersSuccess({ roomMembers }));
    } catch (error) {
      return onError({
        error,
        dispatch,
        action: actions.fetchRoomMembersError({ error }),
      });
    }
  };
};
