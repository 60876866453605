import { types } from '../../ducks/Milestones';

export default {
  [types.FETCH_MILESTONES_SUCCESS]: {
    category: 'milestones',
    action: 'fetch',
  },
  [types.FETCH_MILESTONES_ERROR]: action => ({
    category: 'milestones',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.CREATE_MILESTONE_SUCCESS]: {
    category: 'milestones',
    action: 'create',
  },
  [types.CREATE_MILESTONE_ERROR]: action => ({
    category: 'milestones',
    action: 'create.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_MILESTONE_SUCCESS]: {
    category: 'milestones',
    action: 'update',
  },
  [types.UPDATE_MILESTONE_ERROR]: action => ({
    category: 'milestones',
    action: 'update.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.UPDATE_MILESTONE_IMMEDIATELY_SUCCESS]: {
    category: 'milestones',
    action: 'update-immediately',
  },
  [types.UPDATE_MILESTONE_IMMEDIATELY_ERROR]: action => ({
    category: 'milestones',
    action: 'update-immediately.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [types.DELETE_MILESTONE_SUCCESS]: {
    category: 'milestones',
    action: 'delete',
  },
  [types.DELETE_MILESTONE_ERROR]: action => ({
    category: 'milestones',
    action: 'delete.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
