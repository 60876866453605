import { client } from './API';

/**
 * Subscribe to card or board
 * @param id id of CARD or BOARD to subscribe
 * @return nothing
 */
export const subscribe = ({ boardId, id }) =>
  client.post(`/v1/boards/${boardId}/subscribers/${id}/-me-`, {}).then(response => response.data);

/**
 * Unsubscribe from card or board
 * @param id id of CARD or BOARD to unsubscribe
 * @return nothing
 */
export const unsubscribe = ({ boardId, id }) =>
  client.delete(`/v1/boards/${boardId}/subscribers/${id}/-me-`).then(response => response.data);

/**
 * @returns all subscribers (user objects) of CARD or BOARD
 */
export const fetchSubscribers = ({ boardId, id }) =>
  client.get(`/v1/boards/${boardId}/subscribers/${id}`).then(response => response.data);
