import { types as questionTypes } from '../../ducks/Cards';
import { types as milestoneTypes } from '../../ducks/Milestones';
import { types as deliveryTypes } from '../../ducks/Deliveries';

export default {
  [questionTypes.FETCH_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'fetch',
  },
  [questionTypes.FETCH_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [questionTypes.UPLOAD_ATTACHMENT_SUCCESS]: {
    category: 'attachments',
    action: 'upload',
  },
  [questionTypes.UPLOAD_ATTACHMENT_ERROR]: action => ({
    category: 'attachments',
    action: 'upload.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [questionTypes.DELETE_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'delete',
  },
  [questionTypes.DELETE_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'delete.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),

  [milestoneTypes.FETCH_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'fetch',
  },
  [milestoneTypes.FETCH_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.UPLOAD_ATTACHMENT_SUCCESS]: {
    category: 'attachments',
    action: 'upload',
  },
  [milestoneTypes.UPLOAD_ATTACHMENT_ERROR]: action => ({
    category: 'attachments',
    action: 'upload.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [milestoneTypes.DELETE_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'delete',
  },
  [milestoneTypes.DELETE_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'delete.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),

  [deliveryTypes.FETCH_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'fetch',
  },
  [deliveryTypes.FETCH_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.UPLOAD_ATTACHMENT_SUCCESS]: {
    category: 'attachments',
    action: 'upload',
  },
  [deliveryTypes.UPLOAD_ATTACHMENT_ERROR]: action => ({
    category: 'attachments',
    action: 'upload.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
  [deliveryTypes.DELETE_ATTACHMENTS_SUCCESS]: {
    category: 'attachments',
    action: 'delete',
  },
  [deliveryTypes.DELETE_ATTACHMENTS_ERROR]: action => ({
    category: 'attachments',
    action: 'delete.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
