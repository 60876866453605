import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Table } from '@tribiahq/interaxo-react-components';
import { withStyles } from '@material-ui/core/styles';

import Immutable from 'seamless-immutable';
import DeleteIconButton from '../../common/DeleteIconButton';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

const styles = () => ({
  mobile: {
    flexDirection: 'column',
  },
  noData: {
    width: '100%',
    position: 'absolute',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    transform: 'none',
  },
});

const DeleteButton = ({ onExecute }) => (
  <DeleteIconButton tooltipText={intl.get('common.attachments.delete')} onClick={onExecute} />
);

DeleteButton.propTypes = {
  onExecute: PropTypes.func.isRequired,
};

class AttachmentsMobile extends React.Component {
  // Note that the attachments table must be mutable; otherwise, IE 11 will display an empty table.
  render() {
    const { classes, item, attachments, formatAttachmentLink, commitChanges } = this.props;
    return (
      <ExpansionPanelDetails className={classes.mobile}>
        <Table
          rows={Immutable(attachments || []).asMutable()}
          columns={[
            {
              name: 'name',
              title: intl.get('common.attachments.columns.name'),
            },
          ]}
          editing={{
            stateConfig: {
              onCommitChanges: commitChanges,
            },
            config: {
              width: 120,
              showDeleteCommand: item.permissions.DELETE_ATTACHMENT,
              commandComponent: DeleteButton,
            },
          }}
          providers={[
            <DataTypeProvider
              key={'linkProvider'}
              for={['name']}
              formatterComponent={formatAttachmentLink}
            />,
          ]}
          tableConfig={{
            messages: {
              noData: <div className={classes.noData}>{intl.get('common.content.empty')}</div>,
            },
          }}
        />
      </ExpansionPanelDetails>
    );
  }
}

AttachmentsMobile.propTypes = {
  classes: PropTypes.shape({
    mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    noData: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }).isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  item: PropTypes.shape({
    permissions: PropTypes.shape({
      DELETE_ATTACHMENT: PropTypes.bool,
    }),
  }).isRequired,
  isUploading: PropTypes.bool,
  formatAttachmentLink: PropTypes.func,
  commitChanges: PropTypes.func,
};

AttachmentsMobile.defaultProps = {
  attachments: [],
  isUploading: false,
  formatAttachmentLink: () => {},
  commitChanges: () => {},
};

export default withStyles(styles)(AttachmentsMobile);
