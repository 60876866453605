import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  icon: {
    padding: 8,
  },
};

const DeleteIconButton = ({ tooltipText, onClick, disabled, classes }) =>
  !disabled ? (
    <Tooltip title={tooltipText}>
      <IconButton className={classes.icon} onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  ) : (
    <span />
  );

DeleteIconButton.propTypes = {
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.shape({
    icon: PropTypes.string,
  }).isRequired,
};

DeleteIconButton.defaultProps = {
  tooltipText: '',
  disabled: false,
  onClick: () => {},
};

export default withStyles(styles)(DeleteIconButton);
