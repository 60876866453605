import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import IconInfo from '@material-ui/icons/InfoOutlined';
import IconEdit from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter } from 'react-router-dom';

import * as deliveryActions from '../../thunks/Deliveries';
import { fetchRoomMembers } from '../../thunks/Boards';
import ListBoard from './ListBoard';
import {
  actions as dActions,
  selectDeliveries,
  selectIsLoadingDeliveries,
  selectLastActiveDelivery,
  selectListViewConfig,
} from '../../ducks/Deliveries';
import { selectActiveBoard, selectRoomMembers } from '../../ducks/Boards';
import { actions as boardActions } from '../../ducks/Boards';
import { LIST_DELIVERY_VIEW_MODE } from '../../utils/ViewModeUtil';
import {
  deliveryFieldsToColumns,
  generateNumberOfCardsMessage,
  STATUS_COLUMN,
} from '../../utils/ListViewUtil';
import PageWrapper from '../../common/PageWrapper';
import {
  toPrimitiveFieldValue,
  itemToRow,
  getCellComponentRenderer,
  getCardInfo,
  getEntryTitle,
} from '../../utils/FieldUtil';
import { canEditDelivery } from '../../utils/PermissionUtils';
import { Cell } from '@tribiahq/interaxo-react-components';
import ToggleIconButton from '../../common/ToggleIconButton';
import { alertAction } from '../../thunks/Alerts';
import DeleteDeliveryDialog from '../../common/DeleteCardItemDialog';
import Error from '../../common/Error';
import DeleteIconButton from '../../common/DeleteIconButton';
import PropTypes from 'prop-types';
import SubscriptionContainer from '../../common/SubscriptionContainer';
import { exportContent } from '../../services/Export';
import saveAs from 'file-saver';
import { generateExportRequest } from '../../utils/ExportUtil';

let searchTerm = '';

const mapStateToProps = () =>
  createStructuredSelector({
    activeBoard: selectActiveBoard(),
    isLoadingDeliveries: selectIsLoadingDeliveries(),
    deliveries: selectDeliveries(),
    roomMembers: selectRoomMembers(),
    lastActiveDelivery: selectLastActiveDelivery(),
    listViewConfig: selectListViewConfig(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...deliveryActions,
      ...boardActions,
      ...dActions,
      cleanUp: dActions.cleanUp,
      fetchRoomMembers,
    },
    dispatch,
  ),
});

class ListDeliveryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDeleteDialog: false,
      filters: [],
      viewConfig: props.listViewConfig,
    };
  }

  componentDidMount() {
    const { activeBoard, roomMembers } = this.props;
    const { params } = this.props.match;
    this.props.actions.fetchDeliveries({ boardId: params.boardId });
    if (roomMembers.length < 1 || activeBoard.id.toString() !== params.boardId) {
      this.props.actions.fetchRoomMembers({ boardId: params.boardId });
    }

    searchTerm = localStorage.getItem('searchTerm') || '';
    this.setState({
      filters: JSON.parse(localStorage.getItem('filters')) || [],
    });
  }

  componentWillUnmount() {
    const { viewConfig } = this.state;

    if (viewConfig) {
      this.props.actions.setListViewConfig(viewConfig);
    }
  }

  handleSortChange = sorter => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, sorter } });
  };

  handleColumnOrderChange = columnOrder => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, columnOrder } });
  };

  handleColumnVisibilityChange = visibleColumns => {
    const { viewConfig } = this.state;
    this.setState({ viewConfig: { ...viewConfig, visibleColumns } });
  };

  handleSearch = searchTerm => {
    localStorage.setItem('searchTerm', searchTerm);
  };

  handleFilter = filters => {
    localStorage.setItem('filters', JSON.stringify(filters));
    this.setState({
      filters: filters,
    });
  };

  handleDeliveryEditClick = deliveryId => {
    this.props.actions.setLastActiveDelivery({
      lastActiveDelivery: deliveryId,
    });
    this.props.history.push(
      `/${this.props.match.params.boardId}/deliveries/${deliveryId}?view=${LIST_DELIVERY_VIEW_MODE}`,
    );
  };

  handleDeliveryCreateClick = () =>
    this.props.history.push(
      `/${this.props.match.params.boardId}/create-delivery?view=${LIST_DELIVERY_VIEW_MODE}`,
    );

  handleDelete = () => {
    this.setState({ openDeleteDialog: false });

    const { activeBoard } = this.props;
    this.props.actions
      .deleteDelivery({
        boardId: activeBoard.id,
        delivery: this.state.delivery,
      })
      .then(action =>
        alertAction({
          action,
          success: intl.get('board.tooltips.delivery.delete.success.message'),
          error: intl.get('board.tooltips.delivery.delete.error.message'),
          onSuccess: () => {},
        }),
      );
  };

  calculateStatus = (row, fieldId) =>
    row.fields.find(field => field.id === fieldId && field.value)
      ? intl.get('delivery.status.delivered')
      : intl.get('delivery.status.not_delivered');

  rowToDeliveryInfo = row => {
    const { roomMembers } = this.props;

    const cardInfo = row.fields.reduce((deliveryInfo, field) => {
      deliveryInfo[field.id] = toPrimitiveFieldValue(field);
      return deliveryInfo;
    }, {});
    return cardInfo.merge({
      [STATUS_COLUMN]: row[STATUS_COLUMN],
      ...getCardInfo(row, roomMembers),
    });
  };

  deliveriesToRows = (deliveries, deliveryConfig) =>
    deliveries.asMutable().map(delivery => {
      let row = itemToRow({ item: delivery, fieldConfig: deliveryConfig });
      row[STATUS_COLUMN] = this.calculateStatus(delivery, deliveryConfig.delivery_met_date_field);

      return row;
    });

  handleClose = () => {
    this.setState({ openDeleteDialog: false });
  };

  handleClickOpenDialog = delivery => {
    this.setState({
      delivery: delivery,
      openDeleteDialog: true,
    });
  };

  renderDeliveryLinkButton = ({ row }) => {
    const canEdit = canEditDelivery(row.permissions);

    return (
      <Cell>
        <ToggleIconButton
          tooltipText={intl.get(
            canEdit
              ? 'board.views.list.tooltips.edit_delivery'
              : 'board.views.list.tooltips.view_delivery',
          )}
          generalIcon={canEdit ? <IconEdit /> : <IconInfo />}
          hoverIcon={canEdit ? <IconEdit /> : <IconInfo />}
          disabled={false}
          onClick={() => this.handleDeliveryEditClick(row.id)}
        />
        <DeleteIconButton
          tooltipText={intl.get('board.tooltips.delivery.delete.question.title')}
          onClick={() => this.handleClickOpenDialog(row)}
          disabled={!row.permissions.DELETE}
        />
      </Cell>
    );
  };

  handleExportClick =
    ({ folderName }) =>
    ({ columns, items }) => {
      const exportRequest = generateExportRequest({ columns, items });

      return exportContent({
        boardId: this.props.activeBoard.id,
        exportRequest,
      }).then(response => Promise.resolve(saveAs(response.data, `${folderName}.csv`)));
    };

  render() {
    const { activeBoard, deliveries, lastActiveDelivery, isLoadingDeliveries, roomMembers } =
      this.props;

    const { viewConfig } = this.state;

    // Still loading milestones or board
    if (isLoadingDeliveries) {
      return <LinearProgress />;
    }

    return (
      <PageWrapper title={intl.get('app_bar.delivery_list_view')}>
        {activeBoard.delivery_config ? (
          <React.Fragment>
            <ListBoard
              lastActiveItem={lastActiveDelivery}
              board={activeBoard}
              items={this.deliveriesToRows(deliveries, activeBoard.delivery_config)}
              height={window.innerHeight - 192}
              style={{ marginBottom: 15 }}
              columns={deliveryFieldsToColumns(activeBoard.delivery_config)}
              renderCellComponent={getCellComponentRenderer(
                activeBoard.delivery_config.fields,
                roomMembers,
              )}
              editing={{
                renderLinkButtons: this.renderDeliveryLinkButton,
                width: 110,
              }}
              rowToInfo={this.rowToDeliveryInfo}
              readOnly={!activeBoard.delivery_config.permissions.ADD_DELIVERY}
              onItemCreateClick={this.handleDeliveryCreateClick}
              tooltips={{
                create: intl.get('board.tooltips.add_delivery'),
              }}
              subscription={
                <SubscriptionContainer id={activeBoard.delivery_config.delivery_folder_id} />
              }
              filters={this.state.filters}
              onFiltersChange={this.handleFilter}
              onSearch={this.handleSearch}
              onSortChange={this.handleSortChange}
              onColumnOrderChange={this.handleColumnOrderChange}
              onColumnVisibilityChange={this.handleColumnVisibilityChange}
              searchTerm={searchTerm}
              selection={{
                enableSelection: true,
                helperText: intl.get('board.views.list.tooltips.cards.export.helper_text'),
                numberOfItemsMessage: generateNumberOfCardsMessage,
              }}
              sorter={viewConfig.sorter}
              defaultSorter={[{ columnName: getEntryTitle(activeBoard.delivery_config.fields) }]}
              columnOrder={viewConfig.columnOrder}
              visibleColumns={viewConfig.visibleColumns}
              onExportClick={this.handleExportClick({
                folderName: activeBoard.delivery_config.name,
              })}
            />
            <DeleteDeliveryDialog
              open={this.state.openDeleteDialog}
              onClose={this.handleClose}
              onDelete={this.handleDelete}
              titles={{
                deleteDialogTitle: intl.get('board.tooltips.delivery.delete.question.title'),
                deleteDialogMessage: intl.get('board.tooltips.delivery.delete.question.message'),
              }}
            />
          </React.Fragment>
        ) : (
          <Error text={intl.get('delivery.not_found')} />
        )}
      </PageWrapper>
    );
  }
}

ListDeliveryContainer.propTypes = {
  activeBoard: PropTypes.shape({
    id: PropTypes.string,
    delivery_config: PropTypes.shape({
      name: PropTypes.string,
      delivery_folder_id: PropTypes.string,
      delivery_met_date_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
      permissions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  params: PropTypes.shape({
    boardId: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    deleteDelivery: PropTypes.func.isRequired,
    fetchDeliveries: PropTypes.func.isRequired,
    cleanUp: PropTypes.func.isRequired,
    fetchRoomMembers: PropTypes.func.isRequired,
    setLastActiveDelivery: PropTypes.func.isRequired,
    setListViewConfig: PropTypes.func.isRequired,
  }),
  deliveries: PropTypes.array.isRequired,
  lastActiveDelivery: PropTypes.string,
  isLoadingDeliveries: PropTypes.bool.isRequired,
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  listViewConfig: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

ListDeliveryContainer.defaultProps = {
  roomMembers: [],
  match: {},
  history: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListDeliveryContainer));
