import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import classNames from 'classnames';
import Menu from '@material-ui/core/Menu';
import DisciplineFilters from './DisciplineFilters';
import FieldFilters from './FieldFilters';
import StepsFilter from './StepsFilter';
import IconButton from '@material-ui/core/IconButton';
import IconFilterOn from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { getIdenticalMilestoneFields } from '../../utils/FieldUtil';
import {
  getValidFilterFields,
  isAnyFilterAvailable,
  isShowingOnlyDefaultStep,
} from '../../utils/FilterUtil';
import { WEEK_BOARD_VIEW_MODE } from '../../utils/ViewModeUtil';

import { QUESTION, MILESTONE, DELIVERY } from '../../utils/Constants';

const styles = {
  iconFiltersApplied: {
    color: red[800],
  },
  menu: {
    paddingBottom: 24,
  },
};

class BoardCellFilters extends React.Component {
  state = {
    anchorEl: null,
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { board, classes, viewConfig, possibleResponsible, onChange, viewName } = this.props;

    const fromMemberField = board.field_config.fields.find(
      field => field.id === board.field_config.from_member_field,
    );
    const toMemberField = board.field_config.fields.find(
      field => field.id === board.field_config.to_member_field,
    );

    // Filters questions
    let questionFields = getValidFilterFields({
      board,
      fields: board.field_config.fields,
      type: QUESTION,
    });
    let taskResponsible = board.field_config.fields.filter(
      field => field.type === 'task-responsible',
    );
    if (taskResponsible.length === 1) {
      questionFields = questionFields.concat(
        taskResponsible[0].set('limited_to', possibleResponsible),
      );
    }

    //Filters deliveries
    let deliveryFields = [];
    if (board.delivery_config && viewName === WEEK_BOARD_VIEW_MODE) {
      deliveryFields = getValidFilterFields({
        board,
        fields: board.delivery_config.fields,
        type: DELIVERY,
      });
    }

    //Filters milestones
    let milestoneFields = [];
    if (viewName === WEEK_BOARD_VIEW_MODE) {
      milestoneFields = getValidFilterFields({
        board,
        fields: getIdenticalMilestoneFields({ board }),
        type: MILESTONE,
      });
    }

    // Filters applied
    const hasDisciplineFilters =
      (!!viewConfig.from && viewConfig.from.length !== fromMemberField.limited_to.length) ||
      (!!viewConfig.to && viewConfig.to.length !== toMemberField.limited_to.length);
    const hasFilters =
      hasDisciplineFilters ||
      isAnyFilterAvailable(viewConfig.field_filters[QUESTION]) ||
      !isShowingOnlyDefaultStep(board, viewConfig.stepIdsToShowOnBoard) ||
      (viewName === WEEK_BOARD_VIEW_MODE &&
        (isAnyFilterAvailable(viewConfig.field_filters[MILESTONE]) ||
          isAnyFilterAvailable(viewConfig.field_filters[DELIVERY])));

    return (
      <React.Fragment>
        <Tooltip title={intl.get('board.toolbar.discipline_filters.tooltip')}>
          <IconButton onClick={this.handleClick}>
            <IconFilterOn className={classNames(hasFilters && classes.iconFiltersApplied)} />
          </IconButton>
        </Tooltip>
        <Menu
          classes={{ paper: classes.menu }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}>
          <DisciplineFilters
            viewConfig={viewConfig}
            hasDisciplineFilters={hasDisciplineFilters}
            fromMemberField={fromMemberField}
            toMemberField={toMemberField}
            onChange={onChange}
          />
          {milestoneFields.length > 0 && (
            <FieldFilters
              title={intl.get('board.toolbar.milestones.filters')}
              resetTitle={intl.get('board.toolbar.milestones.filters.reset')}
              viewConfig={viewConfig}
              fields={milestoneFields}
              onChange={onChange}
              type={MILESTONE}
            />
          )}
          {questionFields.length > 0 && (
            <FieldFilters
              title={intl.get('board.toolbar.questions.filters')}
              resetTitle={intl.get('board.toolbar.questions.filters.reset')}
              viewConfig={viewConfig}
              fields={questionFields}
              onChange={onChange}
              type={QUESTION}
            />
          )}
          {deliveryFields.length > 0 && (
            <FieldFilters
              title={intl.get('board.toolbar.deliveries.filters')}
              resetTitle={intl.get('board.toolbar.deliveries.filters.reset')}
              viewConfig={viewConfig}
              fields={deliveryFields}
              onChange={onChange}
              type={DELIVERY}
            />
          )}
          <StepsFilter
            title={intl.get('board.toolbar.step_to_expose')}
            resetTitle={intl.get('board.toolbar.deliveries.filters.reset')}
            viewConfig={viewConfig}
            stepIdsToShowOnBoard={viewConfig.stepIdsToShowOnBoard}
            onChange={onChange}
            board={board}
          />
        </Menu>
      </React.Fragment>
    );
  }
}

BoardCellFilters.propTypes = {
  board: PropTypes.shape({
    field_config: PropTypes.shape({
      from_member_field: PropTypes.string,
      to_member_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    delivery_config: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  classes: PropTypes.shape({
    iconFiltersApplied: PropTypes.string,
    menu: PropTypes.string,
  }).isRequired,
  viewConfig: PropTypes.shape({
    field_filters: PropTypes.shape({}),
    stepIdsToShowOnBoard: PropTypes.arrayOf(PropTypes.string),
    from: PropTypes.arrayOf(PropTypes.shape({})),
    to: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  viewName: PropTypes.string.isRequired,
};

export default withStyles(styles)(BoardCellFilters);
