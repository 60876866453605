import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withStyles } from '@material-ui/core/styles';
import CardItem from './CardItem';
import { STATUS_RED, STATUS_GREEN } from './CardItem';
import { getQuestionFieldValuesToDisplayInTooltip } from '../../utils/FieldUtil';
import { QUESTION } from '../../utils/Constants';
import QuestionIcon from '@material-ui/icons/HelpRounded';
import { isQuestionAnswered } from '../../utils/QuestionUtil';

const styles = () => ({
  typeIcon: {
    height: 32,
    width: 32,
    color: '#3eb1c8',
  },
  typeIconHighReadability: {
    height: 48,
    width: 48,
    color: '#3eb1c8',
  },
});

class QuestionCardItem extends React.Component {
  render() {
    const { classes, card, board, viewConfig, onCardClick, rowIndex, columnIndex, isMarked, type } =
      this.props;
    const isAnswered = isQuestionAnswered(board, card);

    return (
      <CardItem
        key={`card-${card.id}`}
        card={card}
        board={board}
        viewConfig={viewConfig}
        onCardClick={card => {
          onCardClick({ card, type: QUESTION });
        }}
        getFieldMap={getQuestionFieldValuesToDisplayInTooltip}
        typeIcon={
          <QuestionIcon
            className={
              viewConfig.highReadability ? classes.typeIconHighReadability : classes.typeIcon
            }
          />
        }
        typeIconTooltip={intl.get('board.tooltips.question')}
        statusColour={isAnswered ? STATUS_GREEN : STATUS_RED}
        statusTooltip={
          isAnswered
            ? intl.get('board.tooltips.question.answered')
            : intl.get('board.tooltips.question.unanswered')
        }
        titleField="questionTitle"
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        isMarked={isMarked}
        type={type}
      />
    );
  }
}

QuestionCardItem.propTypes = {
  card: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  viewConfig: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  isMarked: PropTypes.bool,
  classes: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // from 'withStyles'
  type: PropTypes.string.isRequired,
};

QuestionCardItem.defaultProps = {
  onCardClick: () => {},
  rowIndex: 0,
  columnIndex: 0,
  isMarked: false,
};

export default withStyles(styles)(QuestionCardItem);
