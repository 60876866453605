import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';

import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard } from '../../ducks/Boards';
import { selectPossibleResponsible } from '../../ducks/Cards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import * as boardsActions from '../../thunks/Boards';
import * as cardsActions from '../../thunks/Cards';
import {
  populateInitialValues,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  hasResponsibleField,
  getDefaultFieldValue,
  getInitialFieldValue,
  getNonMandatoryManualSequenceId,
  UNIQUE_DOC_ID,
} from '../../utils/FieldUtil';
import PageWrapper from '../../common/PageWrapper';
import { withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import CardAttachments from '../common/Attachments';
import CardStep from './CardStep';
import CardComments from '../common/Comments';
import PropTypes from 'prop-types';
import { validateAnsweredDateField } from '../../utils/Validators';
import { TextareaAutosize } from '@material-ui/core';

const mapStateToProps = () =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...cardsActions, ...boardsActions }, dispatch),
});

class CardCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    closeOnSuccess: false,
  };

  handleSubmit = ({ values, item }) => {
    const { board } = this.props;

    return this.props.actions
      .createCard({
        boardId: board.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.field_config.fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.create.error'),
          success: intl.get('card.create.success'),
          onSuccess: () =>
            this.props.history.push(
              withViewModeQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/cards/${action.payload.card.id}`,
              ),
            ),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeQuery(`/${this.props.board.id}`));
  };

  handleFetchPossibleResponsible = ({ disciplines }) => {
    if (hasResponsibleField({ fieldConfig: this.props.board.field_config })) {
      this.props.actions
        .fetchPossibleResponsible({ boardId: this.props.board.id, disciplines })
        .then(action =>
          alertAction({
            action,
            error: intl.get('card.responsible.error'),
          }),
        );
    }
  };

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const {
      activeCommunity,
      activeRoom,
      board,
      possibleResponsible,
      user,
      attachments,
      isUploading,
      isDeleting,
      comments,
      members,
    } = this.props;

    // Create new dummy card
    const card = {
      type: 'entry',
      fields: board.field_config.fields
        .filter(field => field.type !== 'auto-number')
        .map(field => {
          const value = field.initial_value
            ? getInitialFieldValue(field)
            : getDefaultFieldValue(field);

          return {
            id: field.id,
            name: field.name,
            type: field.type,
            value: value,
          };
        }),
      permissions: {},
    };

    return (
      <PageWrapper title={intl.get('app_bar.question_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              formRef={this.formRef}
              item={card}
              possibleResponsible={possibleResponsible}
              initialValues={populateInitialValues({
                item: card,
                fields: board.field_config.fields,
              })}
              disabledFields={[
                board.field_config.answer_field,
                board.field_config.answered_date_field,
                UNIQUE_DOC_ID,
                ...getNonMandatoryManualSequenceId(board.field_config.fields),
              ]}
              disableDelete={true}
              requiredFields={getRequiredFields({
                item: card,
                fields: board.field_config.fields,
              })}
              type={board.type}
              fieldConfig={board.field_config}
              onFetchPossibleResponsible={this.handleFetchPossibleResponsible}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              restrictions={{
                'task-done': (fieldConfig, field) => ({}),
                'task-responsible': (fieldConfig, field) => ({}),
                'rich-text': (fieldConfig, field) => ({}),
                'auto-number': (fieldConfig, field) => ({}),
                'unique-document-id': (fieldConfig, field) => ({}),
                'sequence-number': (fieldConfig, field) => ({}),
                member: (fieldConfig, field) => ({}),
                list: (fieldConfig, field) => ({}),
                date: (fieldConfig, field) => {
                  if (fieldConfig.answered_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => {
                  // question/answer field should be displayed as textarea if it's plain text field
                  return (field.id === fieldConfig.question_field ||
                    field.id === fieldConfig.answer_field) &&
                    field.type === 'string'
                    ? {
                        variant: 'outlined',
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          inputComponent: TextareaAutosize,
                          inputProps: { rowsMin: 5, rowsMax: 10 },
                          style: { minWidth: 512 },
                        },
                        style: { marginTop: 15 },
                      }
                    : {};
                },
                numeric: (fieldConfig, field) => ({}),
              }}
              formValidation={{
                answered_date_field: (fieldConfig, values) =>
                  validateAnsweredDateField({ fieldConfig, values }),
              }}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  handleClick: () => this.handleSaveClick(true),
                },
                {
                  title: intl.get('common.form.save'),
                  default: true,
                  handleClick: () => this.handleSaveClick(false),
                },
              ]}
            />
            <CardAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={card}
              user={user}
              attachments={attachments}
              isUploading={isUploading}
              isDeleting={isDeleting}
            />
          </Grid>
          <Grid item sm={12} md={4} xs={12}>
            <CardStep card={card} steps={board.step_config.steps} />
            <CardComments board={board} item={card} comments={comments} members={members} />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

CardCreateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  board: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    field_config: PropTypes.shape({
      answer_field: PropTypes.string,
      answered_date_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    step_config: PropTypes.shape({
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  isLoadingBoard: PropTypes.bool,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchCards: PropTypes.func,
    createCard: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }).isRequired,
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

CardCreateContainer.defaultProps = {
  possibleResponsible: [],
  user: {},
  attachments: [],
  comments: [],
  isUploading: false,
  isDeleting: false,
  members: [],
  history: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardCreateContainer));
