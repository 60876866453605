import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import saveAs from 'file-saver';

import { alertAction } from '../../thunks/Alerts';
import * as boardsActions from '../../thunks/Boards';
import * as cardActions from '../../thunks/Cards';
import Error from '../../common/Error';
import PageWrapper from '../../common/PageWrapper';
import {
  calculateDisabledFieldsForCard,
  getUpdatedFieldsAndValues,
  getRequiredFields,
  populateInitialValues,
  hasResponsibleField,
  getCardMetaInfo,
} from '../../utils/FieldUtil';
import { selectRoomMembers } from '../../ducks/Boards';
import {
  selectCard,
  selectAttachments,
  selectComments,
  selectLog,
  selectIsUploadingAttachments,
  selectIsDeletingAttachments,
  selectPossibleResponsible,
  actions as cardsActions,
} from '../../ducks/Cards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import { downloadCardAttachment } from '../../services/Cards';
import { withViewModeAndStepQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import CardAttachments from '../common/Attachments';
import Log from '../common/Log';
import CardStep from './CardStep';
import CardComments from '../common/Comments';
import PropTypes from 'prop-types';
import { validateAnsweredDateField, validateAnswerField } from '../../utils/Validators';
import { TextareaAutosize } from '@material-ui/core';

const mapStateToProps = (state, ownProp) =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    card: selectCard({ cardId: ownProp.match.params.cardId }),
    attachments: selectAttachments(),
    comments: selectComments(),
    log: selectLog(),
    isUploading: selectIsUploadingAttachments(),
    isDeleting: selectIsDeletingAttachments(),
    possibleResponsible: selectPossibleResponsible(),
    roomMembers: selectRoomMembers(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...cardActions, ...cardsActions, ...boardsActions }, dispatch),
});

class CardUpdateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    closeOnSuccess: false,
  };

  componentDidMount() {
    const { board, card, roomMembers } = this.props;
    const { params } = this.props.match;
    this.handleFetchComments({
      boardId: params.boardId,
      cardId: params.cardId,
    });
    this.handleFetchLog({
      boardId: params.boardId,
      cardId: params.cardId,
    });
    this.handleFetchAttachments({
      boardId: params.boardId,
      cardId: params.cardId,
    });
    if (roomMembers.length < 1 || board.id.toString() !== params.boardId) {
      this.handleFetchRoomMembers({ boardId: params.boardId });
    }

    // Possible responsible
    const toValue = card.fields
      .find(field => field.id === board.field_config.to_member_field)
      .value.map(discipline => discipline.id);
    this.handleFetchPossibleResponsible({ disciplines: toValue });
  }

  handleSubmit = ({ values, item }) => {
    const { board, history } = this.props;

    return this.props.actions
      .updateCard({
        boardId: board.id,
        cardId: item.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.field_config.fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.update.error'),
          success: intl.get('card.update.success'),
          onSuccess: () =>
            history.push(
              withViewModeAndStepQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/cards/${action.payload.card.id}`,
              ),
            ),
        });
      });
  };

  handleStepChange = async ({ card, step }) => {
    const form = this.formRef.current;

    if (form.dirty) {
      if (!form.isValid) {
        alertAction({
          action: { payload: { error: 'Invalid form' } },
          error: intl.get('card.move.error'),
        });
        return Promise.reject('Form is invalid');
      }

      return this.handleMoveAndUpdate({ card, step });
    }

    return this.handleMove({ card, step });
  };

  handleMoveAndUpdate = ({ card, step }) => {
    const { board, history } = this.props;
    const form = this.formRef.current;

    const updatedValues = getUpdatedFieldsAndValues({
      values: form.values,
      item: card,
      fieldsConfig: board.field_config.fields,
    });

    return this.props.actions
      .updateCardImmediately({
        boardId: board.id,
        card,
        stepId: step.id,
        data: updatedValues || [],
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.move.error'),
          success: intl.get('card.move_and_update.success'),
          onSuccess: () => history.push(withViewModeAndStepQuery(`/${board.id}`)),
        });
      });
  };

  handleMove = ({ card, step }) => {
    const { board, history } = this.props;
    const view = new URLSearchParams(this.props.location.search).get('view');

    return this.props.actions
      .moveCard({
        board,
        cardId: card.id,
        stepId: step.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('card.move.error'),
          success: intl.get('card.move.success'),
          onSuccess: () =>
            view ? history.push(`/${board.id}?view=${view}`) : history.push(`/${board.id}`),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeAndStepQuery(`/${this.props.board.id}`));
  };

  handleUploadAttachment = ({ files }) => {
    const { board, card } = this.props;
    if (files.length > 0) {
      this.props.actions
        .uploadAttachment({
          boardId: board.id,
          cardId: card.id,
          files,
        })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.attachments.upload.error'),
            success: intl.get('common.attachments.upload.success'),
          });
        });
    }
  };

  handleAddLinks = links => {
    const { board, card } = this.props;
    links.forEach(link =>
      this.props.actions
        .addLink({
          boardId: board.id,
          cardId: card.id,
          link,
        })
        .then(action => {
          alertAction({
            action,
            error: intl.get('common.link.add.error'),
            success: intl.get('common.link.add.success'),
          });
        }),
    );
  };

  handleDeleteAttachment = ({ attachmentId }) => {
    const { board, card } = this.props;
    this.props.actions
      .deleteAttachment({
        boardId: board.id,
        cardId: card.id,
        attachmentId,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.attachments.delete.error'),
          success: intl.get('common.attachments.delete.success'),
        });
      });
  };

  handleDownloadAttachment = ({ attachment }) => {
    const { board, card } = this.props;
    const boardId = board.id;
    const cardId = card.id;
    let attachmentId = attachment.id;
    let version;
    if (attachment.type === 'internal-link') {
      attachmentId = attachment.destination;
      version = attachment.destination_version;
    }
    downloadCardAttachment({
      boardId,
      cardId,
      attachmentId,
      version,
    }).then(response => {
      saveAs(response.data, attachment.name);
    });
  };

  handleFetchPossibleResponsible = ({ disciplines }) => {
    if (hasResponsibleField({ fieldConfig: this.props.board.field_config })) {
      this.props.actions
        .fetchPossibleResponsible({ boardId: this.props.board.id, disciplines })
        .then(action =>
          alertAction({
            action,
            error: intl.get('card.responsible.error'),
          }),
        );
    }
  };

  handleFetchRoomMembers = ({ boardId }) => {
    this.props.actions.fetchRoomMembers({ boardId });
  };

  handleFetchComments = ({ boardId, cardId }) => {
    this.props.actions
      .fetchComments({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.comments.fetch.error'),
        }),
      );
  };

  handleFetchLog = ({ boardId, cardId }) => {
    this.props.actions
      .fetchLog({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.log.fetch.error'),
        }),
      );
  };

  handleFetchAttachments = ({ boardId, cardId }) => {
    this.props.actions
      .fetchAttachments({
        boardId,
        cardId,
      })
      .then(action =>
        alertAction({
          action,
          error: intl.get('common.attachments.fetch.error'),
        }),
      );
  };

  handleSubmitComment = ({ content }) => {
    const { board, card } = this.props;
    return this.props.actions
      .createComment({
        boardId: board.id,
        cardId: card.id,
        content,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.add.error'),
          success: intl.get('common.comments.add.success'),
        });
      });
  };

  handleUpdateComment = ({ comment }) => {
    const { board, card } = this.props;

    return this.props.actions
      .updateComment({
        boardId: board.id,
        cardId: card.id,
        commentId: comment.id,
        comment,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.update.error'),
          success: intl.get('common.comments.update.success'),
        });
      });
  };

  handleDeleteComment = ({ comment }) => {
    const { board, card } = this.props;

    return this.props.actions
      .deleteComment({
        boardId: board.id,
        cardId: card.id,
        commentId: comment.id,
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('common.comments.delete.error'),
          success: intl.get('common.comments.delete.success'),
        });
      });
  };

  handleSaveClick = ({ closeForm }) => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const {
      activeCommunity,
      activeRoom,
      attachments,
      board,
      card,
      isUploading,
      isDeleting,
      comments,
      log,
      possibleResponsible,
      roomMembers,
    } = this.props;

    const { params } = this.props.match;

    if (!card) {
      return (
        <Error
          text={intl.get('card.not_found', {
            cardId: params.cardId,
            boardId: params.boardId,
          })}
        />
      );
    }

    return (
      <PageWrapper title={intl.get('app_bar.question_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              formRef={this.formRef}
              item={card}
              possibleResponsible={possibleResponsible}
              initialValues={populateInitialValues({
                item: card,
                fields: board.field_config.fields,
              })}
              disabledFields={calculateDisabledFieldsForCard({
                permissions: card.permissions,
                fieldConfig: board.field_config,
              })}
              disableDelete={true}
              requiredFields={getRequiredFields({
                item: card,
                fields: board.field_config.fields,
              })}
              type={board.type}
              fieldConfig={board.field_config}
              onFetchPossibleResponsible={this.handleFetchPossibleResponsible}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              restrictions={{
                'task-done': (fieldConfig, field) => ({}),
                'task-responsible': (fieldConfig, field) => ({}),
                'rich-text': (fieldConfig, field) => ({}),
                'auto-number': (fieldConfig, field) => ({}),
                'unique-document-id': (fieldConfig, field) => ({}),
                'sequence-number': (fieldConfig, field) => ({}),
                member: (fieldConfig, field) => ({}),
                list: (fieldConfig, field) => ({}),
                date: (fieldConfig, field) => {
                  if (fieldConfig.answered_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => {
                  // question/answer field should be displayed as textarea if it's plain text field
                  return (field.id === fieldConfig.question_field ||
                    field.id === fieldConfig.answer_field) &&
                    field.type === 'string'
                    ? {
                        variant: 'outlined',
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          inputComponent: TextareaAutosize,
                          inputProps: { rowsMin: 5, rowsMax: 10 },
                          style: { minWidth: 512 },
                        },
                        style: { marginTop: 15 },
                      }
                    : {};
                },
                numeric: (fieldConfig, field) => ({}),
              }}
              formValidation={{
                answered_date_field: (fieldConfig, values) =>
                  validateAnsweredDateField({ fieldConfig, values }),
                answer_field: (fieldConfig, values) => validateAnswerField({ fieldConfig, values }),
              }}
              metaInfo={getCardMetaInfo(card, roomMembers)}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  default: true,
                  handleClick: () => this.handleSaveClick({ closeForm: true }),
                },
                {
                  title: intl.get('common.form.save'),
                  handleClick: () => this.handleSaveClick({ closeForm: false }),
                },
              ]}
            />
            <CardAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={card}
              attachments={attachments}
              members={roomMembers}
              isUploading={isUploading}
              isDeleting={isDeleting}
              onAddLinks={this.handleAddLinks}
              onUploadAttachment={this.handleUploadAttachment}
              onDeleteAttachment={this.handleDeleteAttachment}
              onDownloadAttachment={this.handleDownloadAttachment}
            />
            <Log board={board} log={log} members={roomMembers} item={card} />
          </Grid>
          <Grid item sm={12} md={4} xs={12}>
            <CardStep
              card={card}
              steps={board.step_config.steps}
              onStepChange={this.handleStepChange}
            />
            <CardComments
              board={board}
              item={card}
              comments={comments}
              onSubmitComment={this.handleSubmitComment}
              onUpdateComment={this.handleUpdateComment}
              onDeleteComment={this.handleDeleteComment}
              members={roomMembers}
            />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

CardUpdateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  comments: PropTypes.arrayOf(PropTypes.shape({})),
  isUploading: PropTypes.bool,
  board: PropTypes.shape({
    workflow_config: PropTypes.shape({
      community_id: PropTypes.string,
      room_id: PropTypes.string,
    }),
    field_config: PropTypes.shape({
      to_member_field: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    step_config: PropTypes.shape({
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    id: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  card: PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    permissions: PropTypes.shape({}),
  }),
  params: PropTypes.shape({
    boardId: PropTypes.string.isRequired,
    cardId: PropTypes.string.isRequired,
  }).isRequired,
  isDeleting: PropTypes.bool,
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
  roomMembers: PropTypes.arrayOf(PropTypes.shape({})),
  actions: PropTypes.shape({
    fetchActiveBoard: PropTypes.func,
    fetchCards: PropTypes.func,
    updateCard: PropTypes.func,
    moveCard: PropTypes.func,
    updateCardImmediately: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
    fetchRoomMembers: PropTypes.func,
    fetchComments: PropTypes.func,
    createComment: PropTypes.func,
    fetchAttachments: PropTypes.func,
    uploadAttachment: PropTypes.func,
    addLink: PropTypes.func,
    deleteAttachment: PropTypes.func,
    fetchLog: PropTypes.func,
    updateComment: PropTypes.func,
    deleteComment: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      boardId: PropTypes.string,
      cardId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  log: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

CardUpdateContainer.defaultProps = {
  attachments: [],
  comments: [],
  isUploading: false,
  isLoadingBoard: false,
  isDeleting: false,
  possibleResponsible: [],
  roomMembers: [],
  match: {},
  history: {},
  location: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardUpdateContainer));
