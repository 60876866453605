import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import auth from '../ducks/Auth';
import ui from '../ducks/App';
import cards from '../ducks/Cards';
import boards from '../ducks/Boards';
import subscribers from '../ducks/Subscribers';
import communities from '../ducks/Communities';
import rooms from '../ducks/Rooms';
import milestones from '../ducks/Milestones';
import deliveries from '../ducks/Deliveries';
import files from '../ducks/Files';

export default combineReducers({
  auth,
  ui,
  cards,
  boards,
  toastr,
  subscribers,
  communities,
  rooms,
  milestones,
  deliveries,
  files,
});
