/**
 * All catch (error) handlers when performing axios request should go via
 * this handler. This will ensure that general errors are captured, and
 * is logged (TODO). If network errors occure, or a users session has expired,
 * a sessionError action will be dispatched which redirects the user to the login page.
 *
 * Either message OR duck needs to be provided.
 * @param  {[error]} error        Error received in response
 * @param  {[dispatch]} dispatch  redux dispatch
 * @param  {[string]} message     (optional) message key of message to show
 * @param  {[object]} action        (optional) functional specific action to handle state changes
 * ific error handler if server error
 * @return {[Promise]}
 */
export default function onError({ error, dispatch, message, action }) {
  // Error relating to what action was performed, dispatching default duck specified.
  return dispatch(action);
}
