import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { insertUpdatedItem } from '../utils/ReducerUtil';
import { verifyName } from '../utils/FieldUtil';

// Types
export const types = {
  // get
  FETCH_MILESTONES_REQUEST: 'FETCH_MILESTONES_REQUEST',
  FETCH_MILESTONES_SUCCESS: 'FETCH_MILESTONES_SUCCESS',
  FETCH_MILESTONES_ERROR: 'FETCH_MILESTONES_ERROR',
  // clean up
  CLEAN_UP_MILESTONES: 'CLEAN_UP_MILESTONES',
  // create
  CREATE_MILESTONE_REQUEST: 'CREATE_MILESTONE_REQUEST',
  CREATE_MILESTONE_SUCCESS: 'CREATE_MILESTONE_SUCCESS',
  CREATE_MILESTONE_ERROR: 'CREATE_MILESTONE_ERROR',
  // update
  UPDATE_MILESTONE_REQUEST: 'UPDATE_MILESTONE_REQUEST',
  UPDATE_MILESTONE_SUCCESS: 'UPDATE_MILESTONE_SUCCESS',
  UPDATE_MILESTONE_IMMEDIATELY_SUCCESS: 'UPDATE_MILESTONE_IMMEDIATELY_SUCCESS',
  UPDATE_MILESTONE_ERROR: 'UPDATE_MILESTONE_ERROR',
  UPDATE_MILESTONE_IMMEDIATELY_ERROR: 'UPDATE_MILESTONE_IMMEDIATELY_ERROR',
  // delete
  DELETE_MILESTONE_REQUEST: 'DELETE_MILESTONE_REQUEST',
  DELETE_MILESTONE_SUCCESS: 'DELETE_MILESTONE_SUCCESS',
  DELETE_MILESTONE_ERROR: 'DELETE_MILESTONE_ERROR',
  // add attachment
  UPLOAD_ATTACHMENT_REQUEST: 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_ERROR: 'UPLOAD_ATTACHMENT_ERROR',
  // add link
  ADD_LINK_REQUEST: 'ADD_LINK_REQUEST',
  ADD_LINK_SUCCESS: 'ADD_LINK_SUCCESS',
  ADD_LINK_ERROR: 'ADD_LINK_ERROR',
  // get attachments
  FETCH_ATTACHMENTS_REQUEST: 'FETCH_ATTACHMENTS_REQUEST',
  FETCH_ATTACHMENTS_SUCCESS: 'FETCH_ATTACHMENTS_SUCCESS',
  FETCH_ATTACHMENTS_ERROR: 'FETCH_ATTACHMENTS_ERROR',
  // fetch log
  FETCH_LOG_REQUEST: 'FETCH_LOG_REQUEST',
  FETCH_LOG_SUCCESS: 'FETCH_LOG_SUCCESS',
  FETCH_LOG_ERROR: 'FETCH_LOG_ERROR',
  // delete Attachments
  DELETE_ATTACHMENTS_REQUEST: 'DELETE_ATTACHMENTS_REQUEST',
  DELETE_ATTACHMENTS_SUCCESS: 'DELETE_ATTACHMENTS_SUCCESS',
  DELETE_ATTACHMENTS_ERROR: 'DELETE_ATTACHMENTS_ERROR',
  // get comments
  FETCH_COMMENTS_REQUEST: 'FETCH_COMMENTS_REQUEST',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_ERROR: 'FETCH_COMMENTS_ERROR',
  // add comment
  CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_ERROR: 'CREATE_COMMENT_ERROR',

  // Update comment
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_REQUEST_SUCCESS: 'UPDATE_COMMENT_REQUEST_SUCCESS',
  UPDATE_COMMENT_REQUEST_ERROR: 'UPDATE_COMMENT_REQUEST_ERROR',

  // Update comment
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_REQUEST_SUCCESS: 'DELETE_COMMENT_REQUEST_SUCCESS',
  DELETE_COMMENT_REQUEST_ERROR: 'DELETE_COMMENT_REQUEST_ERROR',

  //
  //  FETCH POSSIBLE RESPONSIBLE PERSONS
  //
  FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_REQUEST: 'FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_REQUEST',
  FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_SUCCESS: 'FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_SUCCESS',
  FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_ERROR: 'FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_ERROR',
  //
  // LAST ACTIVE MILESTONE
  //
  SET_LAST_ACTIVE_MILESTONE: 'SET_LAST_ACTIVE_MILESTONE',

  // View config
  SET_MILESTONES_LIST_VIEW_CONFIG: 'SET_MILESTONES_LIST_VIEW_CONFIG',
};

const initialState = Immutable({
  isLoadingMilestones: false,
  isRequestedMilestones: false,
  isSavingMilestone: false,
  milestones: [],
  isFetchingAttachments: false,
  isUploadingAttachments: false,
  isDeletingAttachments: false,
  isCreatingAttachment: false,
  attachments: [],
  comments: [],
  log: [],
  possibleResponsible: [],
  isLoadingPossibleResponsible: false,
  isRequestedPossibleResponsible: {},
  lastActiveMilestone: null,
  listViewConfig: {},
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_MILESTONES_REQUEST:
      return state.merge({
        isLoadingMilestones: true,
        isRequestedMilestones: true,
      });
    case types.FETCH_MILESTONES_SUCCESS:
      return state.merge({
        isLoadingMilestones: false,
        milestones: Immutable(action.payload.milestones),
      });
    case types.FETCH_MILESTONES_ERROR:
      return state.merge({
        isLoadingMilestones: false,
      });
    case types.CLEAN_UP_MILESTONES:
      return state.merge({
        milestones: [],
        isRequestedMilestones: false,
      });
    case types.UPDATE_MILESTONE_REQUEST:
    case types.CREATE_MILESTONE_REQUEST:
    case types.DELETE_MILESTONE_REQUEST:
      return state.merge({
        isSavingMilestone: true,
      });
    case types.CREATE_MILESTONE_SUCCESS:
      return state.merge({
        isSavingMilestone: false,
        milestones: state.milestones.map(set =>
          set.id === action.payload.milestoneSet
            ? set.set('milestones', set.milestones.concat(Immutable(action.payload.milestone)))
            : set,
        ),
      });
    case types.CREATE_MILESTONE_ERROR:
    case types.DELETE_MILESTONE_ERROR:
      return state.merge({
        isSavingMilestone: false,
      });
    case types.DELETE_MILESTONE_SUCCESS:
      return state.merge({
        isSavingMilestone: false,
        milestones: state.milestones.map(set =>
          set.id === action.payload.milestoneSet
            ? set.set(
                'milestones',
                set.milestones.filter(milestone => milestone.id !== action.payload.milestone.id),
              )
            : set,
        ),
      });
    case types.UPDATE_MILESTONE_SUCCESS:
      return state.merge({
        isSavingMilestone: false,
        milestones: state.milestones.map(set =>
          set.id === action.payload.milestoneSet
            ? set.set('milestones', insertUpdatedItem(set.milestones, action.payload.milestone))
            : set,
        ),
      });
    case types.UPDATE_MILESTONE_IMMEDIATELY_SUCCESS:
      return state.merge({
        isSavingMilestone: true,
        milestones: state.milestones.map(set =>
          set.id === action.payload.milestoneSet
            ? set.set('milestones', insertUpdatedItem(set.milestones, action.payload.milestone))
            : set,
        ),
      });
    case types.UPDATE_MILESTONE_IMMEDIATELY_ERROR:
      return state.merge({
        isSavingMilestone: false,
        milestones: state.milestones.map(set =>
          set.id === action.payload.milestoneSet
            ? set.set('milestones', insertUpdatedItem(set.milestones, action.payload.milestone))
            : set,
        ),
      });
    case types.FETCH_ATTACHMENTS_REQUEST:
      return state.merge({
        isFetchingAttachments: true,
      });
    case types.FETCH_ATTACHMENTS_SUCCESS:
      return state.merge({
        isFetchingAttachments: false,
        attachments: Immutable(action.payload.attachments),
      });
    case types.FETCH_ATTACHMENTS_ERROR:
      return state.merge({
        isFetchingAttachments: false,
      });
    case types.FETCH_LOG_REQUEST:
      return state.merge({
        isFetchingLog: true,
        log: [],
      });
    case types.FETCH_LOG_SUCCESS:
      return state.merge({
        log: action.payload.log,
        isFetchingLog: false,
      });
    case types.FETCH_LOG_ERROR:
      return state.merge({
        isFetchingLog: false,
      });
    case types.UPLOAD_ATTACHMENT_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.UPLOAD_ATTACHMENT_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        milestones: state.milestones.map(milestone =>
          milestone.id === action.payload.milestoneId
            ? milestone.set('child_count', milestone.child_count + 1)
            : milestone,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.attachments)),
      });
    case types.UPLOAD_ATTACHMENT_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.ADD_LINK_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.ADD_LINK_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        milestones: state.milestones.map(milestone =>
          milestone.id === action.payload.milestoneId
            ? milestone.set('child_count', milestone.child_count + 1)
            : milestone,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.link)),
      });
    case types.ADD_LINK_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.DELETE_ATTACHMENTS_REQUEST:
      return state.merge({
        isDeletingAttachments: true,
      });
    case types.DELETE_ATTACHMENTS_SUCCESS:
      return state.merge({
        isDeletingAttachments: false,
        milestones: state.milestones.map(milestone =>
          milestone.id === action.payload.milestoneId
            ? milestone.set('child_count', milestone.child_count - 1)
            : milestone,
        ),
        attachments: state.attachments.filter(a => a.id !== action.payload.attachmentId),
      });
    case types.DELETE_ATTACHMENTS_ERROR:
      return state.merge({
        isDeletingAttachments: false,
      });
    case types.FETCH_COMMENTS_REQUEST:
      return state.merge({
        isFetchingComments: true,
      });
    case types.FETCH_COMMENTS_SUCCESS: {
      const commentIds = new Set(action.payload.comments.map(comment => comment.id));
      const newComments = state.comments
        .filter(comment => !commentIds.has(comment.id))
        .concat(action.payload.comments);
      return state.merge({
        isFetchingComments: false,
        comments: Immutable(newComments),
      });
    }
    case types.FETCH_COMMENTS_ERROR:
      return state.merge({
        isFetchingComments: false,
      });
    case types.CREATE_COMMENT_REQUEST:
      return state.merge({
        isCreatingComment: true,
      });
    case types.CREATE_COMMENT_SUCCESS:
      return state.merge({
        isCreatingComment: false,
        comments: Immutable([action.payload.comment]).concat(state.comments),
      });
    case types.CREATE_COMMENT_ERROR:
      return state.merge({
        isCreatingComment: false,
      });
    //
    // FETCH POSSIBLE RESPONSIBLE
    //
    case types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_REQUEST:
      return state.merge({
        possibleResponsible: Immutable([]),
        isLoadingPossibleResponsible: true,
        isRequestedPossibleResponsible: { boardId: action.payload.boardId },
      });
    case types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_SUCCESS:
      action.payload.possibleResponsible.forEach(verifyName);
      return state.merge({
        possibleResponsible: action.payload.possibleResponsible,
        isLoadingPossibleResponsible: false,
      });
    case types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_ERROR:
      return state.merge({
        isLoadingPossibleResponsible: false,
      });
    //
    // LAST ACTIVE MILESTONE
    //
    case types.SET_LAST_ACTIVE_MILESTONE:
      return state.merge({
        lastActiveMilestone: action.payload.lastActiveMilestone,
      });
    case types.UPDATE_COMMENT_REQUEST:
      return state.merge({
        isUpdatingComment: true,
      });
    case types.UPDATE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isUpdatingComment: false,
        comments: state.comments.map(comment => {
          if (comment.id === action.payload.comment.id) {
            return action.payload.comment;
          }
          return comment;
        }),
      });
    case types.UPDATE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isUpdatingComment: false,
      });
    case types.DELETE_COMMENT_REQUEST:
      return state.merge({
        isDeletingComment: true,
      });
    case types.DELETE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isDeletingComment: false,
        comments: state.comments.filter(comment => comment.id !== action.payload.commentId),
      });
    case types.DELETE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isDeletingComment: false,
      });
    case types.SET_MILESTONES_LIST_VIEW_CONFIG:
      return state.merge({
        listViewConfig: action.payload.config,
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  setListViewConfig: config => ({
    type: types.SET_MILESTONES_LIST_VIEW_CONFIG,
    payload: {
      config,
    },
  }),
  cleanUp: () => ({
    type: types.CLEAN_UP_MILESTONES,
  }),
  fetchMilestonesRequest: () => ({
    type: types.FETCH_MILESTONES_REQUEST,
  }),
  fetchMilestonesSuccess: ({ milestones }) => ({
    type: types.FETCH_MILESTONES_SUCCESS,
    payload: {
      milestones,
    },
  }),
  fetchMilestonesError: ({ error }) => ({
    type: types.FETCH_MILESTONES_ERROR,
    payload: { error },
  }),
  createMilestoneRequest: () => ({
    type: types.CREATE_MILESTONE_REQUEST,
  }),
  createMilestoneSuccess: ({ milestone, milestoneSet }) => ({
    type: types.CREATE_MILESTONE_SUCCESS,
    payload: {
      milestone,
      milestoneSet,
    },
  }),
  createMilestoneError: ({ error }) => ({
    type: types.CREATE_MILESTONE_ERROR,
    payload: { error },
  }),
  updateMilestoneRequest: () => ({
    type: types.UPDATE_MILESTONE_REQUEST,
  }),
  updateMilestoneSuccess: ({ milestone, milestoneSet }) => ({
    type: types.UPDATE_MILESTONE_SUCCESS,
    payload: {
      milestone,
      milestoneSet,
    },
  }),
  updateMilestoneImmediatelySuccess: ({ milestone, milestoneSet }) => ({
    type: types.UPDATE_MILESTONE_IMMEDIATELY_SUCCESS,
    payload: {
      milestone,
      milestoneSet,
    },
  }),
  updateMilestoneError: ({ error }) => ({
    type: types.UPDATE_MILESTONE_ERROR,
    payload: { error },
  }),
  updateMilestoneImmediatelyError: ({ error, milestone, milestoneSet }) => ({
    type: types.UPDATE_MILESTONE_IMMEDIATELY_ERROR,
    payload: { error, milestone, milestoneSet },
  }),
  deleteMilestoneRequest: () => ({
    type: types.DELETE_MILESTONE_REQUEST,
  }),
  deleteMilestoneSuccess: ({ boardId, milestone, milestoneSet }) => ({
    type: types.DELETE_MILESTONE_SUCCESS,
    payload: { boardId, milestone, milestoneSet },
  }),
  deleteMilestoneError: ({ error }) => ({
    type: types.DELETE_MILESTONE_ERROR,
    payload: { error },
  }),
  fetchAttachmentsRequest: () => ({
    type: types.FETCH_ATTACHMENTS_REQUEST,
  }),
  fetchAttachmentsSuccess: ({ attachments }) => ({
    type: types.FETCH_ATTACHMENTS_SUCCESS,
    payload: {
      attachments,
    },
  }),
  fetchAttachmentsError: ({ error }) => ({
    type: types.FETCH_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  uploadAttachmentRequest: () => ({
    type: types.UPLOAD_ATTACHMENT_REQUEST,
  }),
  uploadAttachmentSuccess: ({ milestoneId, attachments }) => ({
    type: types.UPLOAD_ATTACHMENT_SUCCESS,
    payload: {
      attachments,
      milestoneId,
    },
  }),
  uploadAttachmentError: ({ error }) => ({
    type: types.UPLOAD_ATTACHMENT_ERROR,
    payload: { error },
  }),
  addLinkRequest: () => ({
    type: types.ADD_LINK_REQUEST,
  }),
  addLinkSuccess: ({ milestoneId, link }) => ({
    type: types.ADD_LINK_SUCCESS,
    payload: {
      link,
      milestoneId,
    },
  }),
  addLinkError: ({ error }) => ({
    type: types.ADD_LINK_ERROR,
    payload: { error },
  }),
  deleteAttachmentsRequest: () => ({
    type: types.DELETE_ATTACHMENTS_REQUEST,
  }),
  deleteAttachmentsSuccess: ({ milestoneId, attachmentId }) => ({
    type: types.DELETE_ATTACHMENTS_SUCCESS,
    payload: {
      attachmentId,
      milestoneId,
    },
  }),
  deleteAttachmentsError: ({ error }) => ({
    type: types.DELETE_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  fetchCommentsRequest: () => ({
    type: types.FETCH_COMMENTS_REQUEST,
  }),
  fetchCommentsSuccess: ({ comments }) => ({
    type: types.FETCH_COMMENTS_SUCCESS,
    payload: {
      comments,
    },
  }),
  fetchCommentsError: ({ error }) => ({
    type: types.FETCH_COMMENTS_ERROR,
    payload: { error },
  }),
  fetchLogRequest: () => ({
    type: types.FETCH_LOG_REQUEST,
  }),
  fetchLogSuccess: ({ log }) => ({
    type: types.FETCH_LOG_SUCCESS,
    payload: {
      log,
    },
  }),
  fetchLogError: ({ error }) => ({
    type: types.FETCH_LOG_ERROR,
    payload: { error },
  }),
  createCommentRequest: () => ({
    type: types.CREATE_COMMENT_REQUEST,
  }),
  createCommentSuccess: ({ comment }) => ({
    type: types.CREATE_COMMENT_SUCCESS,
    payload: {
      comment,
    },
  }),
  createCommentError: ({ error }) => ({
    type: types.CREATE_COMMENT_ERROR,
    payload: { error },
  }),
  //
  // FETCH POSSIBLE RESPONSIBLE
  //
  fetchPossibleResponsibleRequest: ({ boardId }) => ({
    type: types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_REQUEST,
    payload: { boardId },
  }),
  fetchPossibleResponsibleSuccess: ({ possibleResponsible }) => ({
    type: types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_SUCCESS,
    payload: { possibleResponsible },
  }),
  fetchPossibleResponsibleError: ({ error }) => ({
    type: types.FETCH_MILESTONE_POSSIBLE_RESPONSIBLE_ERROR,
    payload: { error },
  }),
  //
  // LAST ACTIVE MILESTONE
  //
  setLastActiveMilestone: ({ lastActiveMilestone }) => ({
    type: types.SET_LAST_ACTIVE_MILESTONE,
    payload: { lastActiveMilestone },
  }),

  // Update comment
  updateCommentRequest: () => ({
    type: types.UPDATE_COMMENT_REQUEST,
  }),
  updateCommentSuccess: ({ comment }) => ({
    type: types.UPDATE_COMMENT_REQUEST_SUCCESS,
    payload: {
      comment,
    },
  }),
  updateCommentError: ({ error }) => ({
    type: types.UPDATE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),
  // Delete comment
  deleteCommentRequest: () => ({
    type: types.DELETE_COMMENT_REQUEST,
  }),
  deleteCommentSuccess: ({ commentId }) => ({
    type: types.DELETE_COMMENT_REQUEST_SUCCESS,
    payload: {
      commentId,
    },
  }),
  deleteCommentError: ({ error }) => ({
    type: types.DELETE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),
};

// Selectors
const milestonesSelector = () => state => state.milestones;

export const selectListViewConfig = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.listViewConfig);
export const selectMilestones = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.milestones);
export const selectIsLoadingMilestones = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.isLoadingMilestones);
export const selectIsRequestedMilestones = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.isRequestedMilestones);
export const selectIsSavingMilestone = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.isSavingMilestone);
export const selectMilestone = ({ milestoneId }) =>
  createSelector(milestonesSelector(), milestonesState => {
    let result = null;

    milestonesState.milestones.forEach(milestoneSet => {
      const milestone = milestoneSet.milestones.find(m => m.id === milestoneId);

      if (milestone) {
        result = milestone;
      }
    });

    return result;
  });
export const selectAttachments = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.attachments);
export const selectIsUploadingAttachments = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.isUploadingAttachments);
export const selectIsDeletingAttachments = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.isDeletingAttachments);
export const selectComments = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.comments);
export const selectLog = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.log);

export const selectPossibleResponsible = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.possibleResponsible);

export const selectIsLoadingPossibleResponsible = () =>
  createSelector(
    milestonesSelector(),
    milestonesState => milestonesState.isLoadingPossibleResponsible,
  );

export const selectIsRequestedPossibleResponsible = () =>
  createSelector(
    milestonesSelector(),
    milestonesState => milestonesState.isRequestedPossibleResponsible,
  );

export const selectLastActiveMilestone = () =>
  createSelector(milestonesSelector(), milestonesState => milestonesState.lastActiveMilestone);
