import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Step from './Step';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = theme => ({
  root: {
    width: 300,
    height: 'calc(100% - 90px)', // header + pixels to first step box
    overflow: 'auto',
    padding: 20,
    paddingTop: 70,
    backgroundColor: '#fafafa',
  },
  steps: {
    padding: 20,
  },
  drawer: {
    width: 340,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: 340,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 50,
  },
  toolbar: {
    marginTop: 75,
  },
});

class Steps extends React.Component {
  handleExpandStep = key => {
    const { viewConfig, onChange } = this.props;

    onChange(viewConfig.set('expandedSteps', viewConfig.expandedSteps.concat(key)));
  };

  handleCollapseStep = key => {
    const { viewConfig, onChange } = this.props;

    onChange(
      viewConfig.set(
        'expandedSteps',
        viewConfig.expandedSteps.filter(item => {
          return item !== key;
        }),
      ),
    );
  };

  /**
   * Renders a step with content.
   * If step is a final step, only render the count.
   * @param  {object} board
   * @param  {object} step  The step to render
   * @param  {array} cards  The cards which belongs to step
   * @param  {object} viewConfig
   * @return {node}         The nodes to render
   */
  stepRenderer = ({ board, step, cards, viewConfig }) => (
    <Step
      key={`step-${step.id}`}
      cards={cards}
      step={step}
      board={board}
      viewConfig={viewConfig}
      expanded={viewConfig.expandedSteps.indexOf(step.id) >= 0}
      onCardClick={this.props.onCardClick}
      onMoveCardToStep={this.props.onMoveCardToStep}
      onExpand={this.handleExpandStep}
      onCollapse={this.handleCollapseStep}
      history={this.props.history}
    />
  );

  stepsRenderer = ({ classes, steps, board, cards, viewConfig }) => (
    <div className={classes.steps}>
      {steps.map(step =>
        this.stepRenderer({
          board,
          step,
          cards: cards.filter(card => card.step.id === step.id),
          viewConfig,
        }),
      )}
    </div>
  );

  render() {
    const { classes, board, cards, steps, viewConfig, open, onSidebarClick } = this.props;

    return (
      <React.Fragment>
        {/* Desktop steps as a right side bar */}
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            anchor="right"
            open={true}
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}>
            <div className={classes.toolbar}>
              <IconButton onClick={onSidebarClick}>
                {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            {open &&
              this.stepsRenderer({
                classes,
                board,
                steps,
                cards,
                viewConfig,
              })}
          </Drawer>
        </Hidden>
        {/* Mobile steps as a simple card */}
        <Hidden mdUp implementation="css">
          {this.stepsRenderer({
            classes,
            board,
            steps,
            cards,
            viewConfig,
          })}
        </Hidden>
      </React.Fragment>
    );
  }
}

Steps.propTypes = {
  classes: PropTypes.object.isRequired,
  board: PropTypes.object.isRequired,
  cards: PropTypes.array.isRequired,
  steps: PropTypes.array.isRequired,
  viewConfig: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSidebarClick: PropTypes.func.isRequired,
  onCardClick: PropTypes.func.isRequired,
  onMoveCardToStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Steps);
