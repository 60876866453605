import Public from '@material-ui/icons/Public';
import Link from '@material-ui/icons/Link';
import ifc_24px from './../assets/img/filetypes/ifc_24px.png';
import pdf_24px from './../assets/img/filetypes/pdf_24px.png';
import word_24px from './../assets/img/filetypes/word_24px.png';
import excel_24px from './../assets/img/filetypes/excel_24px.png';
import powerpoint_24px from './../assets/img/filetypes/powerpoint_24px.png';
import outlook_24px from './../assets/img/filetypes/outlook_24px.png';
import visio_24px from './../assets/img/filetypes/visio_24px.png';
import text_24px from './../assets/img/filetypes/text_24px.png';
import html_24px from './../assets/img/filetypes/html_24px.png';
import autocad_24px from './../assets/img/filetypes/autocad_24px.png';
import image_24px from './../assets/img/filetypes/image_24px.png';
import archive_24px from './../assets/img/filetypes/archive_24px.png';
import video_24px from './../assets/img/filetypes/video_24px.png';
import music_24px from './../assets/img/filetypes/music_24px.png';
import ini_24px from './../assets/img/filetypes/ini_24px.png';
import folder_24px from './../assets/img/filetypes/folder_24px.png';
import folder_adv_24px from './../assets/img/filetypes/folder_adv_24px.png';
import folder_bim_24px from './../assets/img/filetypes/folder_bim_24px.png';
import entry_24px from './../assets/img/filetypes/entry_24px.png';
import WF_folder_adv_24px from './../assets/img/filetypes/WF_folder_adv_24px.png';
import React from 'react';
const fileIconMap = {
  ifc: ifc_24px,
  pdf: pdf_24px,
  pdfPreview: pdf_24px,
  doc: word_24px,
  docx: word_24px,
  xls: excel_24px,
  xlsx: excel_24px,
  xlsm: excel_24px,
  xltm: excel_24px,
  ppt: powerpoint_24px,
  pptx: powerpoint_24px,
  msg: outlook_24px,
  vdx: visio_24px,
  vsx: visio_24px,
  vtx: visio_24px,
  txt: text_24px,
  html: html_24px,
  dwg: autocad_24px,
  bmp: image_24px,
  gif: image_24px,
  jpg: image_24px,
  png: image_24px,
  tif: image_24px,
  zip: archive_24px,
  avi: video_24px,
  mpg: video_24px,
  mpeg: video_24px,
  wmv: video_24px,
  mp3: music_24px,
  wav: music_24px,
  unknown: ini_24px,
  site: folder_24px,
};

const folderIconMap = {
  'simple-folder': folder_24px,
  'active-folder': folder_adv_24px,
  'bim-folder': folder_bim_24px,
  entry: entry_24px,
  'workflow-folder': WF_folder_adv_24px,
  step: WF_folder_adv_24px,
};

const getFileIconFromMapping = key => fileIconMap[key] || fileIconMap['unknown'];
const getFileIcon = fileName => {
  const extension = fileName.substr(fileName.lastIndexOf('.') + 1);
  if (extension === fileName) {
    return getFileIconFromMapping('unknown');
  }

  return getFileIconFromMapping(extension.toLowerCase());
};
export const getFileIconHTML = file => <img src={getFileIcon(file.name)} alt={file.name} />;
const getFolderIconFromMapping = key => folderIconMap[key] || fileIconMap['unknown'];
const getFolderIcon = folder =>
  getFolderIconFromMapping(folder.node_type || folder.destination_type);
export const getFolderIconHTML = folder => <img src={getFolderIcon(folder)} alt={folder.name} />;
export const resolveIconHTML = link => {
  if (link.destination_type === 'file') {
    return getFileIconHTML(link);
  } else {
    return getFolderIconHTML(link);
  }
};
export const getLinkIconHTML = link => {
  return (
    <div style={{ position: 'relative' }}>
      <Link
        style={{
          position: 'absolute',
          top: 10,
          left: -5,
          transform: 'rotate(-45deg)',
        }}
      />
      {resolveIconHTML(link)}
    </div>
  );
};

export const getIcon = record => {
  if (record.type === 'internal-link') {
    return getLinkIconHTML(record);
  }

  if (record.type === 'external-link') {
    return (
      <div>
        <Public />
      </div>
    );
  }

  if (record.type === 'file') {
    return getFileIconHTML(record);
  }

  return getFolderIconHTML(record);
};
