import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ViewColumn from '@material-ui/icons/ViewColumn';

export const ColumnChooserButton = ({ onToggle, getMessage, buttonRef, active, ...restProps }) => (
  <Tooltip title={getMessage('showColumnChooser')} placement="bottom" enterDelay={300}>
    <IconButton onClick={onToggle} buttonRef={buttonRef} {...restProps}>
      <ViewColumn />
    </IconButton>
  </Tooltip>
);

ColumnChooserButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  getMessage: PropTypes.func.isRequired,
  buttonRef: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

ColumnChooserButton.defaultProps = {
  active: false,
};
