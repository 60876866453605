export const isOverdueColumn = columnIndex => columnIndex === 1;
export const isNoDueDateColumn = columnIndex => columnIndex === 0;
export const isMilestoneRow = rowIndex => rowIndex === 0;
export const getValuesForDroppedQuestionCard = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}) => {
  const result = {};

  if (!isOverdueColumn(columnIndex)) {
    result.due = config.yColumns[columnIndex].date;
  }
  if (config.xColumns[rowIndex].id) {
    result.to = config.xColumns[rowIndex].id;
  }

  return result;
};

export const getValuesForDroppedMilestoneCard = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}) => ({
  due: config.yColumns[columnIndex].date,
});

export const getValuesForDroppedDeliveryCard = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}) => ({
  due: config.yColumns[columnIndex].date,
});

const sameCell = ({ columnIndex, rowIndex, sourceColumnIndex, sourceRowIndex }) =>
  columnIndex === sourceColumnIndex && rowIndex === sourceRowIndex;

const moveOnlyVertically = ({ columnIndex, rowIndex, sourceColumnIndex, sourceRowIndex }) =>
  rowIndex !== sourceRowIndex && columnIndex === sourceColumnIndex;

const dateFieldWillBeChanged = ({ columnIndex, sourceColumnIndex, card }) =>
  columnIndex !== sourceColumnIndex || (card.date && card.date.getDay() !== 5);

export const canDropQuestionOnCell = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}) => {
  const isInitialStep = board.step_config.initial_step_id === card.step.id;
  const toWillBeChanged = rowIndex !== sourceRowIndex;
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  const dateWillBeChanged = dateFieldWillBeChanged({
    columnIndex,
    sourceColumnIndex,
    card,
  });

  const moveVerticallyInOverdue =
    isOverdueColumn(columnIndex) &&
    moveOnlyVertically({
      columnIndex,
      rowIndex,
      sourceColumnIndex,
      sourceRowIndex,
    });
  const toMemberFieldConfig = board.field_config.fields.find(
    field => field.id === board.field_config.to_member_field,
  );
  const toMemberField = card.fields.find(field => field.id === board.field_config.to_member_field);
  if (
    toMemberFieldConfig.multiple &&
    !toMemberField.value.find(item => item.id === config.xColumns[rowIndex].id)
  ) {
    return false;
  }

  const canDropOnCellFromDifferentStep =
    !isInitialStep &&
    card.permissions.MOVE[board.step_config.initial_step_id] &&
    card.permissions.ASK;

  return (
    canDropOnCellFromDifferentStep || // if d&ding from different step we should have permission to move to initial step
    (!intoSameCell && // no sense to move to the same cell
      isInitialStep &&
      !isMilestoneRow(rowIndex) && // can't move question to milestone row
      (!isOverdueColumn(columnIndex) || moveVerticallyInOverdue) && // in Overdue column we can move only vertically
      (!dateWillBeChanged || card.permissions.ASK) && // in case date will be changed we should have ASK permissions
      (!toWillBeChanged || card.permissions.ASK)) // in case to will be changed we should have ASK permissions
  );
};

export const canDropDeliveryOnCell = ({
  card,
  board,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
  config,
}) => {
  const fromWillBeChanged = rowIndex !== sourceRowIndex;
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  const moveVerticallyInOverdue =
    isOverdueColumn(columnIndex) &&
    moveOnlyVertically({
      columnIndex,
      rowIndex,
      sourceColumnIndex,
      sourceRowIndex,
    });
  const dateWillBeChanged = dateFieldWillBeChanged({
    columnIndex,
    sourceColumnIndex,
    card,
  });
  const isMemberOfNewFromGroup =
    board.delivery_config.allowed_from_authorities.filter(
      group => group.id === config.xColumns[rowIndex].id,
    ).length > 0;

  return (
    !intoSameCell && // if drop to the same cell then no sense to update
    !isMilestoneRow(rowIndex) && // can't move delivery to milestone row
    (!isOverdueColumn(columnIndex) || moveVerticallyInOverdue) && // in Overdue column we can move only vertically
    (!dateWillBeChanged || card.permissions.UPDATE) && // in case date will be changed we should have UPDATE permissions
    (!fromWillBeChanged || (isMemberOfNewFromGroup && card.permissions.UPDATE)) // in case from will be changed we should be a member of new group
  );
};

export const canDropMilestoneOnCell = ({
  card,
  columnIndex,
  rowIndex,
  sourceColumnIndex,
  sourceRowIndex,
}) => {
  const intoSameCell = sameCell({
    columnIndex,
    rowIndex,
    sourceColumnIndex,
    sourceRowIndex,
  });
  return (
    !intoSameCell && // if drop to the same cell then no sense to update
    isMilestoneRow(rowIndex) && // can move milestone card only to milestone row
    !isOverdueColumn(columnIndex) && // we can't drop milestone to Overdue column
    card.permissions.UPDATE // to perform d&d we should have UPDATE permissions;
  );
};
