import Immutable from 'seamless-immutable';
import { createSelector } from 'reselect';
import { insertUpdatedItem } from '../utils/ReducerUtil';
import { verifyName } from '../utils/FieldUtil';
import ListViewConfig from '../models/ListViewConfig';

// Types
export const types = {
  // get
  FETCH_DELIVERIES_REQUEST: 'FETCH_DELIVERIES_REQUEST',
  FETCH_DELIVERIES_SUCCESS: 'FETCH_DELIVERIES_SUCCESS',
  FETCH_DELIVERIES_ERROR: 'FETCH_DELIVERIES_ERROR',
  // clean up
  CLEAN_UP_DELIVERIES: 'CLEAN_UP_DELIVERIES',
  // create
  CREATE_DELIVERY_REQUEST: 'CREATE_DELIVERY_REQUEST',
  CREATE_DELIVERY_SUCCESS: 'CREATE_DELIVERY_SUCCESS',
  CREATE_DELIVERY_ERROR: 'CREATE_DELIVERY_ERROR',
  // update
  UPDATE_DELIVERY_REQUEST: 'UPDATE_DELIVERY_REQUEST',
  UPDATE_DELIVERY_SUCCESS: 'UPDATE_DELIVERY_SUCCESS',
  UPDATE_DELIVERY_IMMEDIATELY_SUCCESS: 'UPDATE_DELIVERY_IMMEDIATELY_SUCCESS',
  UPDATE_DELIVERY_ERROR: 'UPDATE_DELIVERY_ERROR',
  UPDATE_DELIVERY_IMMEDIATELY_ERROR: 'UPDATE_DELIVERY_IMMEDIATELY_ERROR',
  // delete
  DELETE_DELIVERY_REQUEST: 'DELETE_DELIVERY_REQUEST',
  DELETE_DELIVERY_SUCCESS: 'DELETE_DELIVERY_SUCCESS',
  DELETE_DELIVERY_ERROR: 'DELETE_DELIVERY_ERROR',
  // add attachment
  UPLOAD_ATTACHMENT_REQUEST: 'UPLOAD_ATTACHMENT_REQUEST',
  UPLOAD_ATTACHMENT_SUCCESS: 'UPLOAD_ATTACHMENT_SUCCESS',
  UPLOAD_ATTACHMENT_ERROR: 'UPLOAD_ATTACHMENT_ERROR',
  // add link
  ADD_LINK_REQUEST: 'ADD_LINK_REQUEST',
  ADD_LINK_SUCCESS: 'ADD_LINK_SUCCESS',
  ADD_LINK_ERROR: 'ADD_LINK_ERROR',
  // get attachments
  FETCH_ATTACHMENTS_REQUEST: 'FETCH_ATTACHMENTS_REQUEST',
  FETCH_ATTACHMENTS_SUCCESS: 'FETCH_ATTACHMENTS_SUCCESS',
  FETCH_ATTACHMENTS_ERROR: 'FETCH_ATTACHMENTS_ERROR',
  // delete Attachments
  DELETE_ATTACHMENTS_REQUEST: 'DELETE_ATTACHMENTS_REQUEST',
  DELETE_ATTACHMENTS_SUCCESS: 'DELETE_ATTACHMENTS_SUCCESS',
  DELETE_ATTACHMENTS_ERROR: 'DELETE_ATTACHMENTS_ERROR',
  // get comments
  FETCH_COMMENTS_REQUEST: 'FETCH_COMMENTS_REQUEST',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_ERROR: 'FETCH_COMMENTS_ERROR',
  // add comment
  CREATE_COMMENT_REQUEST: 'CREATE_COMMENT_REQUEST',
  CREATE_COMMENT_SUCCESS: 'CREATE_COMMENT_SUCCESS',
  CREATE_COMMENT_ERROR: 'CREATE_COMMENT_ERROR',
  // Update comment
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_REQUEST_SUCCESS: 'UPDATE_COMMENT_REQUEST_SUCCESS',
  UPDATE_COMMENT_REQUEST_ERROR: 'UPDATE_COMMENT_REQUEST_ERROR',
  // Update comment
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_REQUEST_SUCCESS: 'DELETE_COMMENT_REQUEST_SUCCESS',
  DELETE_COMMENT_REQUEST_ERROR: 'DELETE_COMMENT_REQUEST_ERROR',
  // fetch log
  FETCH_LOG_REQUEST: 'FETCH_LOG_REQUEST',
  FETCH_LOG_SUCCESS: 'FETCH_LOG_SUCCESS',
  FETCH_LOG_ERROR: 'FETCH_LOG_ERROR',
  //
  //  FETCH POSSIBLE RESPONSIBLE PERSONS
  //
  FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_REQUEST: 'FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_REQUEST',
  FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_SUCCESS: 'FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_SUCCESS',
  FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_ERROR: 'FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_ERROR',
  //
  // LAST ACTIVE DELIVERY
  //
  SET_LAST_ACTIVE_DELIVERY: 'SET_LAST_ACTIVE_DELIVERY',

  // List view config
  SET_DELIVERIES_LIST_VIEW_CONFIG: 'SET_DELIVERIES_LIST_VIEW_CONFIG',
};

const initialState = Immutable({
  isLoadingDeliveries: false,
  isRequestedDeliveries: false,
  isSavingDelivery: false,
  deliveries: [],
  isFetchingAttachments: false,
  isUploadingAttachments: false,
  isDeletingAttachments: false,
  isCreatingAttachment: false,
  attachments: [],
  comments: [],
  log: [],
  possibleResponsible: [],
  isLoadingPossibleResponsible: false,
  isRequestedPossibleResponsible: {},
  lastActiveDelivery: null,
  listViewConfig: ListViewConfig.defaultValue,
});

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DELIVERIES_REQUEST:
      return state.merge({
        isLoadingDeliveries: true,
        isRequestedDeliveries: true,
      });
    case types.FETCH_DELIVERIES_SUCCESS:
      return state.merge({
        isLoadingDeliveries: false,
        deliveries: Immutable(action.payload.deliveries),
      });
    case types.FETCH_DELIVERIES_ERROR:
      return state.merge({
        isLoadingDeliveries: false,
      });
    case types.CLEAN_UP_DELIVERIES:
      return state.merge({
        deliveries: [],
        isRequestedDeliveries: false,
      });
    case types.UPDATE_DELIVERY_REQUEST:
    case types.CREATE_DELIVERY_REQUEST:
    case types.DELETE_DELIVERY_REQUEST:
      return state.merge({
        isSavingDelivery: true,
      });
    case types.CREATE_DELIVERY_SUCCESS:
      return state.merge({
        isSavingDelivery: false,
        deliveries: state.deliveries.concat(Immutable(action.payload.delivery)),
      });
    case types.CREATE_DELIVERY_ERROR:
    case types.DELETE_DELIVERY_ERROR:
    case types.UPDATE_DELIVERY_ERROR:
      return state.merge({
        isSavingDelivery: false,
      });
    case types.UPDATE_DELIVERY_SUCCESS:
      return state.merge({
        isSavingDelivery: false,
        deliveries: insertUpdatedItem(state.deliveries, action.payload.delivery),
      });
    case types.UPDATE_DELIVERY_IMMEDIATELY_ERROR:
      return state.merge({
        isSavingDelivery: false,
        deliveries: insertUpdatedItem(state.deliveries, action.payload.delivery),
      });
    case types.UPDATE_DELIVERY_IMMEDIATELY_SUCCESS:
      return state.merge({
        isSavingDelivery: true,
        deliveries: insertUpdatedItem(state.deliveries, action.payload.delivery),
      });
    case types.DELETE_DELIVERY_SUCCESS:
      return state.merge({
        isSavingDelivery: false,
        deliveries: state.deliveries.filter(delivery => delivery.id !== action.payload.delivery.id),
      });
    case types.FETCH_ATTACHMENTS_REQUEST:
      return state.merge({
        isFetchingAttachments: true,
      });
    case types.FETCH_ATTACHMENTS_SUCCESS:
      return state.merge({
        isFetchingAttachments: false,
        attachments: Immutable(action.payload.attachments),
      });
    case types.FETCH_ATTACHMENTS_ERROR:
      return state.merge({
        isFetchingAttachments: false,
      });
    case types.UPLOAD_ATTACHMENT_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.UPLOAD_ATTACHMENT_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        deliveries: state.deliveries.map(delivery =>
          delivery.id === action.payload.deliveryId
            ? delivery.set('child_count', delivery.child_count + 1)
            : delivery,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.attachments)),
      });
    case types.UPLOAD_ATTACHMENT_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.ADD_LINK_REQUEST:
      return state.merge({
        isUploadingAttachments: true,
      });
    case types.ADD_LINK_SUCCESS:
      return state.merge({
        isUploadingAttachments: false,
        deliveries: state.deliveries.map(delivery =>
          delivery.id === action.payload.deliveryId
            ? delivery.set('child_count', delivery.child_count + 1)
            : delivery,
        ),
        attachments: state.attachments.concat(Immutable(action.payload.link)),
      });
    case types.ADD_LINK_ERROR:
      return state.merge({
        isUploadingAttachments: false,
      });
    case types.DELETE_ATTACHMENTS_REQUEST:
      return state.merge({
        isDeletingAttachments: true,
      });
    case types.DELETE_ATTACHMENTS_SUCCESS:
      return state.merge({
        isDeletingAttachments: false,
        deliveries: state.deliveries.map(delivery =>
          delivery.id === action.payload.deliveryId
            ? delivery.set('child_count', delivery.child_count - 1)
            : delivery,
        ),
        attachments: state.attachments.filter(a => a.id !== action.payload.attachmentId),
      });
    case types.DELETE_ATTACHMENTS_ERROR:
      return state.merge({
        isDeletingAttachments: false,
      });
    case types.FETCH_COMMENTS_REQUEST:
      return state.merge({
        isFetchingComments: true,
      });
    case types.FETCH_COMMENTS_SUCCESS: {
      const commentIds = new Set(action.payload.comments.map(comment => comment.id));
      const newComments = state.comments
        .filter(comment => !commentIds.has(comment.id))
        .concat(action.payload.comments);
      return state.merge({
        isFetchingComments: false,
        comments: Immutable(newComments),
      });
    }
    case types.FETCH_COMMENTS_ERROR:
      return state.merge({
        isFetchingComments: false,
      });
    case types.FETCH_LOG_REQUEST:
      return state.merge({
        isFetchingLog: true,
        log: [],
      });
    case types.FETCH_LOG_SUCCESS:
      return state.merge({
        log: action.payload.log,
        isFetchingLog: false,
      });
    case types.FETCH_LOG_ERROR:
      return state.merge({
        isFetchingLog: false,
      });
    case types.CREATE_COMMENT_REQUEST:
      return state.merge({
        isCreatingComment: true,
      });
    case types.CREATE_COMMENT_SUCCESS:
      return state.merge({
        isCreatingComment: false,
        comments: Immutable([action.payload.comment]).concat(state.comments),
      });
    case types.CREATE_COMMENT_ERROR:
      return state.merge({
        isCreatingComment: false,
      });
    //
    // FETCH POSSIBLE RESPONSIBLE
    //
    case types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_REQUEST:
      return state.merge({
        possibleResponsible: Immutable([]),
        isLoadingPossibleResponsible: true,
        isRequestedPossibleResponsible: { boardId: action.payload.boardId },
      });
    case types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_SUCCESS:
      action.payload.possibleResponsible.forEach(verifyName);
      return state.merge({
        possibleResponsible: action.payload.possibleResponsible,
        isLoadingPossibleResponsible: false,
      });
    case types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_ERROR:
      return state.merge({
        isLoadingPossibleResponsible: false,
      });
    //
    // LAST ACTIVE DELIVERY
    //
    case types.SET_LAST_ACTIVE_DELIVERY:
      return state.merge({
        lastActiveDelivery: action.payload.lastActiveDelivery,
      });
    case types.UPDATE_COMMENT_REQUEST:
      return state.merge({
        isUpdatingComment: true,
      });
    case types.UPDATE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isUpdatingComment: false,
        comments: state.comments.map(comment => {
          if (comment.id === action.payload.comment.id) {
            return action.payload.comment;
          }
          return comment;
        }),
      });
    case types.UPDATE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isUpdatingComment: false,
      });
    case types.DELETE_COMMENT_REQUEST:
      return state.merge({
        isDeletingComment: true,
      });
    case types.DELETE_COMMENT_REQUEST_SUCCESS:
      return state.merge({
        isDeletingComment: false,
        comments: state.comments.filter(comment => comment.id !== action.payload.commentId),
      });
    case types.DELETE_COMMENT_REQUEST_ERROR:
      return state.merge({
        isDeletingComment: false,
      });
    case types.SET_DELIVERIES_LIST_VIEW_CONFIG:
      return state.merge({
        listViewConfig: action.payload.config,
      });
    default:
      return state;
  }
};

// Actions
export const actions = {
  setListViewConfig: config => ({
    type: types.SET_DELIVERIES_LIST_VIEW_CONFIG,
    payload: {
      config,
    },
  }),
  cleanUp: () => ({
    type: types.CLEAN_UP_DELIVERIES,
  }),
  fetchDeliveriesRequest: () => ({
    type: types.FETCH_DELIVERIES_REQUEST,
  }),
  fetchDeliveriesSuccess: ({ deliveries }) => ({
    type: types.FETCH_DELIVERIES_SUCCESS,
    payload: {
      deliveries,
    },
  }),
  fetchDeliveriesError: ({ error }) => ({
    type: types.FETCH_DELIVERIES_ERROR,
    payload: { error },
  }),
  createDeliveryRequest: () => ({
    type: types.CREATE_DELIVERY_REQUEST,
  }),
  createDeliverySuccess: ({ delivery }) => ({
    type: types.CREATE_DELIVERY_SUCCESS,
    payload: { delivery },
  }),
  createDeliveryError: ({ error }) => ({
    type: types.CREATE_DELIVERY_ERROR,
    payload: { error },
  }),
  updateDeliveryRequest: () => ({
    type: types.UPDATE_DELIVERY_REQUEST,
  }),
  updateDeliveryImmediatelySuccess: ({ delivery }) => ({
    type: types.UPDATE_DELIVERY_IMMEDIATELY_SUCCESS,
    payload: { delivery },
  }),
  updateDeliveryImmediatelyError: ({ error, delivery }) => ({
    type: types.UPDATE_DELIVERY_IMMEDIATELY_ERROR,
    payload: { error, delivery },
  }),
  updateDeliverySuccess: ({ delivery }) => ({
    type: types.UPDATE_DELIVERY_SUCCESS,
    payload: { delivery },
  }),
  updateDeliveryError: ({ error }) => ({
    type: types.UPDATE_DELIVERY_ERROR,
    payload: { error },
  }),
  deleteDeliveryRequest: () => ({
    type: types.DELETE_DELIVERY_REQUEST,
  }),
  deleteDeliverySuccess: ({ boardId, delivery }) => ({
    type: types.DELETE_DELIVERY_SUCCESS,
    payload: { boardId, delivery },
  }),
  deleteDeliveryError: ({ error }) => ({
    type: types.DELETE_DELIVERY_ERROR,
    payload: { error },
  }),
  fetchAttachmentsRequest: () => ({
    type: types.FETCH_ATTACHMENTS_REQUEST,
  }),
  fetchAttachmentsSuccess: ({ attachments }) => ({
    type: types.FETCH_ATTACHMENTS_SUCCESS,
    payload: {
      attachments,
    },
  }),
  fetchAttachmentsError: ({ error }) => ({
    type: types.FETCH_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  uploadAttachmentRequest: () => ({
    type: types.UPLOAD_ATTACHMENT_REQUEST,
  }),
  uploadAttachmentSuccess: ({ deliveryId, attachments }) => ({
    type: types.UPLOAD_ATTACHMENT_SUCCESS,
    payload: {
      attachments,
      deliveryId,
    },
  }),
  uploadAttachmentError: ({ error }) => ({
    type: types.UPLOAD_ATTACHMENT_ERROR,
    payload: { error },
  }),
  addLinkRequest: () => ({
    type: types.ADD_LINK_REQUEST,
  }),
  addLinkSuccess: ({ deliveryId, link }) => ({
    type: types.ADD_LINK_SUCCESS,
    payload: {
      link,
      deliveryId,
    },
  }),
  addLinkError: ({ error }) => ({
    type: types.ADD_LINK_ERROR,
    payload: { error },
  }),
  deleteAttachmentsRequest: () => ({
    type: types.DELETE_ATTACHMENTS_REQUEST,
  }),
  deleteAttachmentsSuccess: ({ deliveryId, attachmentId }) => ({
    type: types.DELETE_ATTACHMENTS_SUCCESS,
    payload: {
      attachmentId,
      deliveryId,
    },
  }),
  deleteAttachmentsError: ({ error }) => ({
    type: types.DELETE_ATTACHMENTS_ERROR,
    payload: { error },
  }),
  fetchCommentsRequest: () => ({
    type: types.FETCH_COMMENTS_REQUEST,
  }),
  fetchCommentsSuccess: ({ comments }) => ({
    type: types.FETCH_COMMENTS_SUCCESS,
    payload: {
      comments,
    },
  }),
  fetchCommentsError: ({ error }) => ({
    type: types.FETCH_COMMENTS_ERROR,
    payload: { error },
  }),
  createCommentRequest: () => ({
    type: types.CREATE_COMMENT_REQUEST,
  }),
  createCommentSuccess: ({ comment }) => ({
    type: types.CREATE_COMMENT_SUCCESS,
    payload: {
      comment,
    },
  }),
  createCommentError: ({ error }) => ({
    type: types.CREATE_COMMENT_ERROR,
    payload: { error },
  }),
  fetchLogRequest: () => ({
    type: types.FETCH_LOG_REQUEST,
  }),
  fetchLogSuccess: ({ log }) => ({
    type: types.FETCH_LOG_SUCCESS,
    payload: {
      log,
    },
  }),
  fetchLogError: ({ error }) => ({
    type: types.FETCH_LOG_ERROR,
    payload: { error },
  }),
  //
  // FETCH POSSIBLE RESPONSIBLE
  //
  fetchPossibleResponsibleRequest: ({ boardId }) => ({
    type: types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_REQUEST,
    payload: { boardId },
  }),
  fetchPossibleResponsibleSuccess: ({ possibleResponsible }) => ({
    type: types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_SUCCESS,
    payload: { possibleResponsible },
  }),
  fetchPossibleResponsibleError: ({ error }) => ({
    type: types.FETCH_DELIVERY_POSSIBLE_RESPONSIBLE_ERROR,
    payload: { error },
  }),
  //
  // LAST ACTIVE DELIVERY
  //
  setLastActiveDelivery: ({ lastActiveDelivery }) => ({
    type: types.SET_LAST_ACTIVE_DELIVERY,
    payload: { lastActiveDelivery },
  }),

  // Update comment
  updateCommentRequest: () => ({
    type: types.UPDATE_COMMENT_REQUEST,
  }),
  updateCommentSuccess: ({ comment }) => ({
    type: types.UPDATE_COMMENT_REQUEST_SUCCESS,
    payload: {
      comment,
    },
  }),
  updateCommentError: ({ error }) => ({
    type: types.UPDATE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),
  // Delete comment
  deleteCommentRequest: () => ({
    type: types.DELETE_COMMENT_REQUEST,
  }),
  deleteCommentSuccess: ({ commentId }) => ({
    type: types.DELETE_COMMENT_REQUEST_SUCCESS,
    payload: {
      commentId,
    },
  }),
  deleteCommentError: ({ error }) => ({
    type: types.DELETE_COMMENT_REQUEST_ERROR,
    payload: { error },
  }),
};

// Selectors
const deliveriesSelector = () => state => state.deliveries;

export const selectListViewConfig = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.listViewConfig);
export const selectDeliveries = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.deliveries);
export const selectIsLoadingDeliveries = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.isLoadingDeliveries);
export const selectIsRequestedDeliveries = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.isRequestedDeliveries);
export const selectIsSavingDelivery = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.isSavingDelivery);
export const selectDelivery = ({ deliveryId }) =>
  createSelector(deliveriesSelector(), deliveriesState =>
    deliveriesState.deliveries.find(delivery => delivery.id === deliveryId),
  );
export const selectAttachments = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.attachments);
export const selectIsUploadingAttachments = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.isUploadingAttachments);
export const selectIsDeletingAttachments = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.isDeletingAttachments);
export const selectComments = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.comments);
export const selectLog = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.log);

export const selectPossibleResponsible = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.possibleResponsible);

export const selectIsLoadingPossibleResponsible = () =>
  createSelector(
    deliveriesSelector(),
    deliveriesState => deliveriesState.isLoadingPossibleResponsible,
  );

export const selectIsRequestedPossibleResponsible = () =>
  createSelector(
    deliveriesSelector(),
    deliveriesState => deliveriesState.isRequestedPossibleResponsible,
  );

export const selectLastActiveDelivery = () =>
  createSelector(deliveriesSelector(), deliveriesState => deliveriesState.lastActiveDelivery);
