import { types } from '../../ducks/Files';

export default {
  [types.FETCH_FILES_SUCCESS]: {
    category: 'files',
    action: 'fetch',
  },
  [types.FETCH_FILES_ERROR]: action => ({
    category: 'files',
    action: 'fetch.error',
    label: `trace_id: ${
      action.payload.error &&
      action.payload.error.response &&
      action.payload.error.response.data.trace_id
    }`,
  }),
};
