import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import intl from 'react-intl-universal';
import { alertAction } from '../../thunks/Alerts';
import { selectActiveBoard } from '../../ducks/Boards';
import { selectActiveCommunity } from '../../ducks/Communities';
import { selectActiveRoom } from '../../ducks/Rooms';
import * as boardsActions from '../../thunks/Boards';
import {
  getNonMandatoryManualSequenceId,
  getRequiredFields,
  getUpdatedFieldsAndValues,
  populateInitialValues,
  UNIQUE_DOC_ID,
} from '../../utils/FieldUtil';
import * as deliveryActions from '../../thunks/Deliveries';
import PageWrapper from '../../common/PageWrapper';
import { withViewModeQuery } from '../../utils/ViewModeUtil';
import Grid from '@material-ui/core/Grid/Grid';
import Form from '../common/Form';
import DeliveryAttachments from '../common/Attachments';
import DeliveryComments from '../common/Comments';
import { selectPossibleResponsible } from '../../ducks/Deliveries';

const mapStateToProps = () =>
  createStructuredSelector({
    activeCommunity: selectActiveCommunity(),
    activeRoom: selectActiveRoom(),
    board: selectActiveBoard(),
    possibleResponsible: selectPossibleResponsible(),
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...deliveryActions,
      ...boardsActions,
    },
    dispatch,
  ),
});

class DeliveryCreateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    closeOnSuccess: false,
  };

  handleSubmit = ({ values, item }) => {
    const { history, board } = this.props;

    return this.props.actions
      .createDelivery({
        boardId: board.id,
        data: {
          type: 'entry',
          fields: getUpdatedFieldsAndValues({
            values,
            item,
            fieldsConfig: board.delivery_config.fields,
          }),
        },
      })
      .then(action => {
        alertAction({
          action,
          error: intl.get('delivery.create.error'),
          success: intl.get('delivery.create.success'),
          onSuccess: () =>
            history.push(
              withViewModeQuery(
                this.state.closeOnSuccess
                  ? `/${board.id}`
                  : `/${board.id}/deliveries/${action.payload.delivery.id}`,
              ),
            ),
        });
      });
  };

  handleCancel = () => {
    this.props.history.push(withViewModeQuery(`/${this.props.board.id}`));
  };

  handleSaveClick = closeForm => {
    const form = this.formRef.current;

    this.setState({ closeOnSuccess: !!closeForm });

    if (form) {
      form.handleSubmit();
    }
  };

  render() {
    const { activeCommunity, activeRoom, board, possibleResponsible } = this.props;

    // Create new delivery.
    // TODO: If this is the same as for milestones, re-use the code.
    const delivery = {
      type: 'entry',
      fields: board.delivery_config.fields
        .filter(field => field.type !== 'auto-number')
        .map(field => {
          let value = null;
          if (field.type === 'list' || field.type === 'member') {
            if (field.multiple) {
              value = [];
            } else {
              value = '';
            }
          } else if (field.type === 'number') {
            value = 0;
          }
          return {
            id: field.id,
            name: field.name,
            type: field.type,
            value: value,
          };
        }),
      permissions: {},
    };

    return (
      <PageWrapper title={intl.get('app_bar.delivery_card')}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={8} xs={12}>
            <Form
              formRef={this.formRef}
              item={delivery}
              initialValues={populateInitialValues({
                item: delivery,
                fields: board.delivery_config.fields,
              })}
              disabledFields={[
                UNIQUE_DOC_ID,
                ...getNonMandatoryManualSequenceId(board.delivery_config.fields),
              ]}
              disableDelete={true}
              requiredFields={getRequiredFields({
                item: delivery,
                fields: board.delivery_config.fields,
              })}
              type={board.type}
              fieldConfig={board.delivery_config}
              onCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              restrictions={{
                'task-done': (fieldConfig, field) => ({}),
                'task-responsible': (fieldConfig, field) => ({}),
                'rich-text': (fieldConfig, field) => ({}),
                'auto-number': (fieldConfig, field) => ({}),
                'unique-document-id': (fieldConfig, field) => ({}),
                'sequence-number': (fieldConfig, field) => ({}),
                member: (fieldConfig, field) => ({}),
                list: (fieldConfig, field) => ({}),
                date: (fieldConfig, field) => {
                  if (fieldConfig.delivery_met_date_field === field.id) {
                    return { disableFuture: true };
                  }
                },
                string: (fieldConfig, field) => ({}),
                numeric: (fieldConfig, field) => ({}),
              }}
              possibleResponsible={possibleResponsible}
              submitOptions={[
                {
                  title: intl.get('common.form.save_and_close'),
                  handleClick: () => this.handleSaveClick({ closeForm: true }),
                },
                {
                  title: intl.get('common.form.save'),
                  default: true,
                  handleClick: this.handleSaveClick,
                },
              ]}
            />
            <DeliveryAttachments
              activeCommunity={activeCommunity}
              activeRoom={activeRoom}
              item={delivery}
              attachments={[]}
              isUploading={false}
              isDeleting={false}
            />
          </Grid>
          <Grid item sm={12} md={4} xs={12}>
            <DeliveryComments board={board} item={delivery} comments={[]} members={[]} />
          </Grid>
        </Grid>
      </PageWrapper>
    );
  }
}

DeliveryCreateContainer.propTypes = {
  activeCommunity: PropTypes.string.isRequired,
  activeRoom: PropTypes.string.isRequired,
  board: PropTypes.object,
  actions: PropTypes.shape({
    createDelivery: PropTypes.func,
    fetchActiveBoard: PropTypes.func,
    fetchPossibleResponsible: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  possibleResponsible: PropTypes.arrayOf(PropTypes.shape({})),
};

DeliveryCreateContainer.defaultProps = {
  board: null,
  history: {},
  possibleResponsible: [],
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeliveryCreateContainer));
