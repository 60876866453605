import React from 'react';
import PropTypes from 'prop-types';
import { isCardInPPCPeriod } from '../utils/FieldUtil';

class CardList extends React.Component {
  isMarked = item => {
    const { board, viewConfig } = this.props;

    return viewConfig.showPPC && isCardInPPCPeriod(item, board, viewConfig);
  };

  render() {
    const { board, viewConfig, rowIndex, columnIndex, items, CardComponent, onCardClick, type } =
      this.props;

    return (
      <React.Fragment>
        {items.map(item => (
          <CardComponent
            key={item.id}
            card={item}
            board={board}
            viewConfig={viewConfig}
            onCardClick={onCardClick}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            isMarked={this.isMarked(item)}
            type={type}
          />
        ))}
      </React.Fragment>
    );
  }
}

CardList.propTypes = {
  classes: PropTypes.shape({}),
  board: PropTypes.shape({}).isRequired,
  viewConfig: PropTypes.shape({
    showPPC: PropTypes.bool,
  }).isRequired,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // The component that displays the items in this list. You may use QuestionCardItem, DeliveryCardItem or
  // MilestoneCardItem.
  CardComponent: PropTypes.shape({}).isRequired,
  onCardClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

CardList.defaultProps = {
  onCardClick: () => {},
  rowIndex: 0,
  columnIndex: 0,
};

export default CardList;
