import React from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Minimize from '@material-ui/icons/Remove';

import CardList from './CardList';
import QuestionCardItem from '../routes/board/QuestionCardItem';
import DeliveryCardItem from '../routes/board/DeliveryCardItem';

const styles = {
  minimize: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginBottom: -8,
  },
  minimizeIcon: {
    width: 24,
    height: 24,
    padding: 0,
  },
};

const hasItems = list => list && list.length > 0;

const CardFolderOpen = ({
  classes,
  board,
  viewConfig,
  rowIndex,
  columnIndex,
  questions,
  deliveries,
  onCollapse,
  onCardClick,
  type,
}) => (
  <React.Fragment>
    {(hasItems(questions) || hasItems(deliveries)) && (
      <React.Fragment>
        <div className={classes.minimize}>
          <Tooltip title={intl.get('boards.tooltips.close')}>
            <IconButton className={classes.minimizeIcon} onClick={onCollapse}>
              <Minimize />
            </IconButton>
          </Tooltip>
        </div>
        <CardList
          board={board}
          viewConfig={viewConfig}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          items={questions}
          CardComponent={QuestionCardItem}
          onCardClick={onCardClick}
          type={type}
        />
        <CardList
          board={board}
          viewConfig={viewConfig}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          items={deliveries}
          CardComponent={DeliveryCardItem}
          onCardClick={onCardClick}
          type={type}
        />
      </React.Fragment>
    )}
  </React.Fragment>
);

CardFolderOpen.propTypes = {
  classes: PropTypes.shape({
    minimize: PropTypes.string,
    minimizeIcon: PropTypes.string,
  }),
  board: PropTypes.shape({}).isRequired,
  viewConfig: PropTypes.shape({}).isRequired,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
  questions: PropTypes.arrayOf(PropTypes.shape({})),
  deliveries: PropTypes.arrayOf(PropTypes.shape({})),
  onCollapse: PropTypes.func,
  onCardClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

CardFolderOpen.defaultProps = {
  rowIndex: 0,
  columnIndex: 0,
  questions: [],
  deliveries: [],
  onCollapse: () => {},
  onCardClick: () => {},
};

export default withStyles(styles)(CardFolderOpen);
